<div class="schedule-info d-flex flex-column align-items-center p-2">
    <div class="location-name p-1"> Location Name</div>
    <div class="toggle-action p-1">
        JUNUARY 1 - JANUARY 7
    </div>

    <div class="location-info p-3 d-flex justify-content-center gap-5 w-100">
        <ng-container *ngFor="let info of locationInfo">
            <div class="d-flex gap-2">
                <span class="circle"></span>
                <svg-icon [name]="info.icon" svgClass="icon"></svg-icon>
                <span class="d-flex flex-column">
                    <span class="fw-bold"> {{info.value}}</span>
                    <span> {{info.heading}}</span>
                </span>
            </div>
        </ng-container>
    </div>


    <div class="dashboard-title w-100 mt-5">
        <div class="d-flex justify-content-between">

            <div class="view-mode">
                <kendo-buttongroup selection="single">

                    <button kendoButton rounded="full" [selected]="true" (click)="onClickTilesView('tiles')"
                        [toggleable]="true" [svgIcon]="bordersIcon" title=""></button>
                    <button kendoButton rounded="full" [toggleable]="true" (click)="onClickTableView('table')"
                        [svgIcon]="sideIcon" title=""></button>

                </kendo-buttongroup>
            </div>

            <div class="toggle-primary">
                <kendo-buttongroup selection="single">

                    <button kendoButton rounded="medium" [selected]="true" [toggleable]="true" title="Week"
                        class="fw-bold">Week </button>
                    <button kendoButton rounded="medium" [toggleable]="true" title="Month" class="fw-bold">
                        Month</button>

                </kendo-buttongroup>
            </div>


        </div>
    </div>


</div>