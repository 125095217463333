import { Component } from '@angular/core';

@Component({
  selector: 'app-forecast-reports',
  templateUrl: './forecast-reports.component.html',
  styleUrl: './forecast-reports.component.scss'
})
export class ForecastReportsComponent {

}
