import { Injectable } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { OperatorFunction } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { DataSource } from "../../../../shared-rail-performance/models/charts/fusion-charts/data-source";
import { PagedResultWithPointers } from "../../../../shared-rail-performance/models/paging/paged-result";
import { NetworkDwellFilters } from "../../../models/filters/network-dwell-filters";
import { NetworkDwellAverageByStateProvince } from "../../../models/network-dwell/network-dwell-average-by-state-province";
import { NetworkDwellAverageByStation } from "../../../models/network-dwell/network-dwell-average-by-station";
import { NetworkDwellCommodity } from "../../../models/network-dwell/network-dwell-commodity";
import { NetworkDwellCount } from "../../../models/network-dwell/network-dwell-count";
import { NetworkDwellLocation } from "../../../models/network-dwell/network-dwell-location";
import { NetworkDwellPerformance } from "../../../models/network-dwell/network-dwell-performance";
import { NetworkDwellStation } from "../../../models/network-dwell/network-dwell-station";
import { NetworkDwellStatusRecord } from "../../../models/network-dwell/status/network-dwell-status-record";
import { NetworkDwellAverageByMonthStateProvince } from "../../../models/network-dwell/network-dwell-average-by-month-state-province";
import { DataQueryServiceConfiguration } from "../../../../shared-rail-performance/services/data-query/data-query-service-configuration";
import { DataQueryService } from "../../../../shared-rail-performance/services/data-query/data-query.service";

@Injectable({
  providedIn: 'root'
})
export class NetworkDwellDataService extends DataQueryService {
  getNetworkDwellAveragesByStateProvince(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStateProvince, filters);
  }

  getNetworkDwellAveragesByStateProvinceLastMonth() {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStateProvinceLastMonth);
  }

  getNetworkDwellAveragesByMonthLocationChart(filters: NetworkDwellFilters, transform: OperatorFunction<NetworkDwellAverageByMonthStateProvince[], DataSource>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByMonthLocationChart, transform, filters);
  }

  getNetworkDwellAveragesByStation(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStation, filters);
  }

  getNetworkDwellSummary(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellSummary, filters);
  }

  getNetworkDwellByCountry(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByCountry, filters);
  }

  getNetworkDwellCommodity(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellCommodity, filters);
  }

  getNetworkDwellStation(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellStation, filters);
  }

  getNetworkDwellByStatus(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellStatus, filters);
  }

  getNetworkDwellByStatusTableData(filters: NetworkDwellFilters, transform: OperatorFunction<PagedResultWithPointers<NetworkDwellStatusRecord, NetworkDwellFilters>, GridDataResult>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellStatus, transform, filters);
  }

  getNetworkDwellCount(filters: NetworkDwellFilters, transform: OperatorFunction<NetworkDwellCount[], DataSource>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellCount, transform, filters);
  }
}

abstract class NetworkDwellDataServiceConfiguration {
  static readonly NetworkDwellAverageByStateProvince = new DataQueryServiceConfiguration<NetworkDwellAverageByStateProvince[]>(
    'network-dwell-average-by-state-province',
    environment.apiUrl + 'NetworkDwell/Average/StateProvince'
  );

  static readonly NetworkDwellAverageByStateProvinceLastMonth = new DataQueryServiceConfiguration<NetworkDwellAverageByStateProvince[]>(
    'network-dwell-average-by-state-province-last-month',
    environment.apiUrl + 'NetworkDwell/Average/StateProvince/LastMonth'
  );

  static readonly NetworkDwellAverageByMonthLocationChart = new DataQueryServiceConfiguration<DataSource>(
    'network-dwell-average-by-month-location-chart',
     environment.apiUrl + 'NetworkDwell/Average/Month/Location'
  );

  static readonly NetworkDwellAverageByStation = new DataQueryServiceConfiguration<NetworkDwellAverageByStation[]>(
    'network-dwell-average-by-station',
     environment.apiUrl + 'NetworkDwell/Average/Station'
  );

  static readonly NetworkDwellSummary = new DataQueryServiceConfiguration<NetworkDwellPerformance>(
    'network-dwell-summary',
    environment.apiUrl + 'NetworkDwell/Summary',
  );

  static readonly NetworkDwellAverageByCountry = new DataQueryServiceConfiguration<NetworkDwellLocation>( 
    'network-dwell-average-by-country',
    environment.apiUrl + 'NetworkDwell/Average/Country'
  );

  static readonly NetworkDwellCommodity = new DataQueryServiceConfiguration<NetworkDwellCommodity>( 
    'network-dwell-commodity',
    environment.apiUrl + 'NetworkDwell/Commodity'
  );

  static readonly NetworkDwellStation = new DataQueryServiceConfiguration<NetworkDwellStation>(
    'network-dwell-station',
    environment.apiUrl + 'NetworkDwell/Station'
  );

  static readonly NetworkDwellStatus = new DataQueryServiceConfiguration<PagedResultWithPointers<NetworkDwellStatusRecord, NetworkDwellFilters>>(
    'network-dwell-status',
    environment.apiUrl + 'NetworkDwell/Status'
  );

  static readonly NetworkDwellCount = new DataQueryServiceConfiguration<NetworkDwellCount>(
    'network-dwell-count',
    environment.apiUrl + 'NetworkDwell/Count'
  );
}