/*!
 * All material copyright ESRI, All Rights Reserved, unless otherwise specified.
 * See https://github.com/Esri/calcite-design-system/blob/dev/LICENSE.md for details.
 * v2.13.2
 */
import { Build } from '@stencil/core/internal/client';

/**
 * This module allows custom configuration for components.
 */
const existingConfig = globalThis["calciteConfig"];
const focusTrapStack = existingConfig?.focusTrapStack || [];
const runningInE2ETest = Build.isTesting && Build.isBrowser;
const logLevel = existingConfig?.logLevel || (runningInE2ETest ? "error" : "info");
// the following placeholders are replaced by the build
const version = "2.13.2";
const buildDate = "2024-10-24";
const revision = "e38e2977d";
/**
 * Stamp the version onto the global config.
 */
function stampVersion() {
    if (existingConfig && existingConfig.version) {
        return;
    }
    console.info(`Using Calcite Components ${version} [Date: ${buildDate}, Revision: ${revision}]`);
    const target = existingConfig || globalThis["calciteConfig"] || {};
    Object.defineProperty(target, "version", {
        value: version,
        writable: false,
    });
    globalThis["calciteConfig"] = target;
}

export { focusTrapStack as f, logLevel as l, stampVersion as s };

