import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";  
import { FusionChartsModule } from "angular-fusioncharts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SharedRailPerformanceModule } from "../../../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../../../common/common-imports";
import { ReportEditNotificationComponent } from "./components/report-edit-notification/report-edit-notification.component";
import { DashboardReportLayoutComponent } from "./components/dashboard-report-layout/dashboard-report-layout.component";
import { DashboardFiltersModule } from "../filters/dashboard-filters.module";

@NgModule({
    imports: [
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        DashboardFiltersModule
    ],
    exports: [
    ],
    declarations: [
        ReportEditNotificationComponent,
        DashboardReportLayoutComponent
    ],
    schemas: [],
})
export class LayoutModule { 

}
