<div class="d-flex flex-column h-100 position-relative">
    @if (tripTimeFormService.tripHistoryQueryResult$ | async; as result) {
        <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
        <kendo-grid [data]="result.data!"
        [pageSize]="tripTimeFormService.tripHistoryPaging.take"
        [skip]="tripTimeFormService.tripHistoryPaging.skip"
        [pageable]="true"
        (pageChange)="pageChange($event)"
        [scrollable]="'scrollable'">
            <kendo-grid-column title="Origin" [width]="175">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                    {{formattingService.formatStation(dataItem!.rawData![0].originCity, dataItem!.rawData![0].originStateProvince)}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Destination" [width]="175">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                    {{formattingService.formatStation(dataItem!.rawData![0].destinationCity, dataItem!.rawData![0].destinationStateProvince)}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Trip Time (days)" headerClass="trip-history" [width]="getWidthOfChartColumn(result)">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <app-average-historical-trip-time-chart [dataSource]="dataItem"></app-average-historical-trip-time-chart>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-dataItem>
                    <div class="d-flex justify-content-around">
                        <span class="muted historical-chart" *ngFor="let column of result.data?.data![0].columns">
                            {{column}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages noRecords="  "></kendo-grid-messages>
        </kendo-grid>
    }
</div>