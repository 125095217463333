import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { DashboardNetworkDwellFormService } from '../../services/dashboard-network-dwell-form.service';
import { DashboardReportFormService } from '../../../layout/services/dashboard-report-form.service';

@Component({
  selector: 'app-dashboard-dwell-total-chart',
  templateUrl: './dashboard-dwell-total-chart.component.html',
  styleUrl: './dashboard-dwell-total-chart.component.css'
})
export class DashboardDwellTotalChartComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(public networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService){}

  ngOnInit(): void {
    this.networkDwellFormService.queryTotalChartData();

    this.networkDwellFormService.periodControl.valueChanges.subscribe(() => {
      this.networkDwellFormService.queryTotalChartData();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get dwellTotalChartResult$() {
    return this.networkDwellFormService.dwellTotalChartResult$;
  }
}
