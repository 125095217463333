import { Component, OnInit, OnDestroy } from "@angular/core";
import { combineLatest, startWith } from "rxjs";
import { SubSink } from "subsink";
import { LocationType } from "../../../../constants/location-type";
import { DashboardReportFormService } from "../../../layout/services/dashboard-report-form.service";
import { DashboardNetworkDwellFormService } from "../../services/dashboard-network-dwell-form.service";


@Component({
  selector: 'app-dashboard-dwell-by-location-chart',
  templateUrl: './dashboard-dwell-by-location-chart.component.html',
  styleUrl: './dashboard-dwell-by-location-chart.component.css',
})
export class DashboardDwellByLocationChartComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(private networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService
  ) {}

  ngOnInit(): void {
    this.networkDwellFormService.queryDwellByLocationTypeData();

    this.sub.sink = combineLatest([
      this.networkDwellFormService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.networkDwellFormService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.networkDwellFormService.graphLocationTypeControl.valueChanges.pipe(startWith(LocationType.StateProvince)),
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))]).subscribe(() => {
        this.networkDwellFormService.queryDwellByLocationTypeData();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get dwellByLocationTypeQueryResult$() {
    return this.networkDwellFormService.dwellByLocationQueryResult$;
  }
}
