import { HttpClientModule } from "@angular/common/http";
import { SharedRailPerformanceModule } from "../../../shared-rail-performance/shared-rail-performance.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FusionChartsModule } from "angular-fusioncharts";
import { AngularSvgIconModule } from "angular-svg-icon";
import { COMMON_PRIME_NG_IMPORTS, COMMON_KENDO_IMPORTS } from "../../../../common/common-imports";
import { ForecastDashboardLandingComponent } from "./components/forecast-dashboard-landing.component";
import { ForecastVolumeDemandChartComponent } from "./components/charts/forecast-volume-demand-chart/forecast-volume-demand-chart.component";
import { ForecastSwitchingPerformanceComponent } from "./components/charts/forecast-switching-performance/forecast-switching-performance.component";
import { ForecastFirstLastMileComponent } from "./components/charts/forecast-first-last-mile/forecast-first-last-mile.component";
import { CalendarModule } from 'primeng/calendar';

@NgModule({
    imports: [
        HttpClientModule,
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        CalendarModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule
    ],
    exports: [
        ForecastDashboardLandingComponent
    ],
  declarations: [
        ForecastVolumeDemandChartComponent,
        ForecastSwitchingPerformanceComponent,
        ForecastFirstLastMileComponent,
        // CycleTimeAverageByLocationGraphComponent,
        // KpiMetricsComponent,
        // NetworkDwellAverageByLocationMapComponent,
        // RailcarVolumeHistoryGraphComponent,
        ForecastDashboardLandingComponent
    ],
    schemas: [],
})
export class LandingForecastDashboardModule { 

}
