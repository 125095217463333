import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { DashboardCycleTimeFormService } from '../services/dashboard-cycle-time-form.service';

@Component({
  selector: 'app-average-cycle-time-table',
  templateUrl: './average-cycle-time-table.component.html',
  styleUrl: './average-cycle-time-table.component.css',
})
export class AverageCycleTimeTableComponent {
  constructor(public formService: DashboardCycleTimeFormService,
              public formattingService: FormattingService
  ) {
    
  }
}
