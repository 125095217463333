import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DataQueryService } from "../../shared-rail-performance/services/data-query/data-query.service";
import { DataQueryServiceConfiguration } from "../../shared-rail-performance/services/data-query/data-query-service-configuration";

@Injectable({
  providedIn: 'root'
})
export class ForecastReportService extends DataQueryService {
  getAllCarriers() {
    return this.get(ForecastServiceConfiguration.Carriers);
  }  
}

class ForecastServiceConfiguration {
  static readonly Carriers = new DataQueryServiceConfiguration<string[]>(
    'Carriers',
    environment.apiUrl + 'Carriers'
  );
}
