import { Component, OnDestroy, OnInit } from "@angular/core";
import { QueryObserverResult } from "@tanstack/query-core";
import { Observable } from "rxjs";
import { NetworkDwellCommodity } from "../../../models/network-dwell/network-dwell-commodity";
import { NetworkDwellLocation } from "../../../models/network-dwell/network-dwell-location";
import { NetworkDwellPerformance } from "../../../models/network-dwell/network-dwell-performance";
import { NetworkDwellStation } from "../../../models/network-dwell/network-dwell-station";
import { FormGroup } from "@angular/forms";
import { Periods } from "../../../constants/period";
import { LocationType } from "../../../constants/location-type";
import { SubSink } from "subsink";
import { DashboardReportFormService } from "../../layout/services/dashboard-report-form.service";
import { DashboardNetworkDwellFormService } from "../services/dashboard-network-dwell-form.service";
import { CustomerFilterType } from "../../../../shared-rail-performance/models/constants/customer-filter-type";
import { UserService } from "../../../../admin/services/user.service";

@Component({
  selector: 'app-dashboard-network-dwell',
  templateUrl: './dashboard-network-dwell.component.html',
  styleUrl: './dashboard-network-dwell.component.scss',
})
export class DashboardNetworkDwellComponent implements OnInit, OnDestroy {
  public filterForm: FormGroup = this.networkDwellFormService.filterForm;

  private sub = new SubSink();

  public locations = [LocationType.StateProvince, LocationType.Region, LocationType.Country];
  public periods = Periods;

  public showCommodity: boolean = false;

  constructor(private networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService,
              private userService: UserService) {   
  }

  ngOnInit(): void {
    this.networkDwellFormService.querySummaryData();

    this.sub.sink = this.dashboardFormService.filtersApplied$.subscribe(() => {
      this.networkDwellFormService.querySummaryData();
    });

    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.networkDwellFormService.exportData();
    });

    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.showCommodity = (user?.customerGroupUserRoles?.length ?? 0) === 0;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  locationChange(event: any) {
    let locationIndex = event[0];
    let location = this.locations[locationIndex];

    this.networkDwellFormService.graphLocationTypeControl.setValue(location);
  }

  periodFilterChange(event: any) {
    let periodIndex = event[0];
    let period = Periods[periodIndex];

    this.networkDwellFormService.periodControl.setValue(period);
  }

  get showFilterLimitsToolTip(): boolean {
    return (this.dashboardFormService.commonFilters.commodities?.length ?? 0) > 0
           || (this.dashboardFormService.commonFilters.loaded?.length ?? 0) > 0;
  }

  get dwellSummaryQueryResult$(): Observable<QueryObserverResult<NetworkDwellPerformance, Error>> | undefined {
    return this.networkDwellFormService.dwellSummaryQueryResult$;
  }

  get dwellLocationQueryResult$(): Observable<QueryObserverResult<NetworkDwellLocation, Error>> | undefined {
    return this.networkDwellFormService.dwellLocationQueryResult$;
  }

  get dwellCommodityQueryResult$(): Observable<QueryObserverResult<NetworkDwellCommodity, Error>> | undefined {
    return this.networkDwellFormService.dwellCommodityQueryResult$;
  }

  get dwellStationQueryResult$(): Observable<QueryObserverResult<NetworkDwellStation, Error>> | undefined {
    return this.networkDwellFormService.dwellStationQueryResult$;
  }
}