import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryObserverResult } from '@tanstack/query-core';
import { Observable } from 'rxjs';
import { RailcarPerformance } from '../../../models/railcar/railcar-performance';
import { RailcarPerformanceCommodity } from '../../../models/railcar/railcar-performance-commodity';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../layout/services/dashboard-report-form.service';
import { DashboardRailcarFormService } from '../services/dashboard-railcar-form.service';
import { CustomerFilterType } from '../../../../shared-rail-performance/models/constants/customer-filter-type';
import { UserService } from '../../../../admin/services/user.service';

@Component({
  selector: 'app-dashboard-railcar',
  templateUrl: './dashboard-railcar.component.html',
  styleUrl: './dashboard-railcar.component.scss',
})
export class DashboardRailcarComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  public showCommodity: boolean = false;

  constructor(public dashboardRailcarFormService: DashboardRailcarFormService,
              private dashboardFormService: DashboardReportFormService,
              private dashboardReportFormService: DashboardReportFormService,
              private userService: UserService) {
    
  }

  get showHistoricalPerformanceToolTip() {
    return (!!this.dashboardFormService.railcarFilters.loaded
            && this.dashboardFormService.railcarFilters.loaded.length > 0)
        || (!!this.dashboardFormService.railcarFilters.motion
            && this.dashboardFormService.railcarFilters.motion.length > 0);
  }

  get performanceQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceQueryResult$;
  }

  get performanceCommodityQueryResult$(): Observable<QueryObserverResult<RailcarPerformanceCommodity, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceCommodityQueryResult$;
  }

  get performanceEmptyQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceEmptyQueryResult$;
  }

  get performanceLoadedQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceLoadedQueryResult$;
  }

  ngOnInit(): void {
    this.sub.sink = this.dashboardFormService.filtersApplied$.subscribe(() => {
      this.dashboardRailcarFormService.querySummaryData();
    });

    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.dashboardRailcarFormService.exportData();
    });

    this.dashboardRailcarFormService.querySummaryData();

    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.showCommodity = (user?.customerGroupUserRoles?.length ?? 0) === 0;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}