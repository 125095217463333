import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-forecast-demand-report',
  templateUrl: './forecast-demand-report.component.html',
  styleUrl: './forecast-demand-report.component.scss'
})
export class ForecastDemandReportComponent {

  reportTitle = "Forecast Demand";
  width = "80%";
  height = "280";
  type = "msline";
  dataFormat = "json";
  dataSource = data;


  @ViewChild('forcastSummaryReport') childelement: ElementRef | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.chartWidth();
  }

  ngAfterViewInit() {
    this.chartWidth();
  }

  private chartWidth() {
    const child = this.childelement?.nativeElement;
    this.width = (Number(child.offsetWidth) - 100).toString();
  }
  ngOnInit(): void {

  }

  fulfillmentColumns = { lastMonth1: 'January', lastMonth2: 'February', lastMonth3: 'March', lastMonth4: 'April', lastMonth5: 'May', lastMonth6: 'June', lastMonth7: 'July', lastMonth8: 'August', lastMonth9: 'September', lastMonth10: 'October', lastMonth11: 'November', lastMonth12: 'December' };
  fulfillmentData = [
    { isDataSet: true, label: 'Forecasted', lastMonth1: '00.0%', lastMonth2: '00.0%%', lastMonth3: '00.0%', lastMonth4: '00.0%', lastMonth5: '00.0%', lastMonth6: '00.0%', lastMonth7: '00.0%', lastMonth8: '00.0%', lastMonth9: '00.0%', lastMonth10: '00.0%', lastMonth11: '00.0%', lastMonth12: '00.0%' }
    , { isDataSet: true, label: 'Released', lastMonth1: '00.0%', lastMonth2: '00.0%', lastMonth3: '00.0%', lastMonth4: '00.0%', lastMonth5: '00.0%', lastMonth6: '00.0%', lastMonth7: '00.0%', lastMonth8: '00.0%', lastMonth9: '00.0%', lastMonth10: '00.0%', lastMonth11: '00.0%', lastMonth12: '00.0%' }
    , { isDataSet: true, label: 'Orders Missed', lastMonth1: '00.0%', lastMonth2: '00.0%', lastMonth3: '00.0%', lastMonth4: '00.0%', lastMonth5: '00.0%', lastMonth6: '00.0%', lastMonth7: '00.0%', lastMonth8: '00.0%', lastMonth9: '00.0%', lastMonth10: '00.0%', lastMonth11: '00.0%', lastMonth12: '00.0%' }
    , { isDataSet: true, label: 'Accuracy', lastMonth1: '00.0%', lastMonth2: '00.0%%', lastMonth3: '00.0%', lastMonth4: '00.0%', lastMonth5: '00.0%', lastMonth6: '00.0%', lastMonth7: '00.0%', lastMonth8: '00.0%', lastMonth9: '00.0%', lastMonth10: '00.0%', lastMonth11: '00.0%', lastMonth12: '00.0%' }
  ];
}


const data = {
  chart: {
    caption: "",
    yaxisname: "",
    subcaption: "",
    showhovereffect: "1",
    showlegend: "0",
    numbersuffix: "%",
    drawcrossline: "1",
    drawAnchors: "0",
    plottooltext: "<b>$dataValue</b> of $seriesName",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        {
          label: "Jan"
        },
        {
          label: "Feb"
        },
        {
          label: "Mar"
        },
        {
          label: "Apr"
        },
        {
          label: "May"
        },
        {
          label: "Jun"
        },
        {
          label: "Jul"
        },
        {
          label: "Aug"
        },
        {
          label: "Sep"
        },
        {
          label: "Oct"
        },
        {
          label: "Nov"
        },
        {
          label: "Dec"
        }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Fulfillment",
      color: "#1B365D",
      data: [
        {
          value: "78"
        },
        {
          value: "90"
        },
        {
          value: "84"
        },
        {
          value: "100"
        },
        {
          value: "97"
        },
        {
          value: "88"
        },
        {
          value: "86"
        },
        {
          value: "84"
        },
        {
          value: "90"
        },
        {
          value: "82"
        },
        {
          value: "69"
        },
        {
          value: "68"
        }
      ]
    },
    {
      seriesname: "Min Fulfillment",
      color: "#1B365D",
      dashed: "1",

      data: [
        {
          value: "50"
        },
        {
          value: "60"
        },
        {
          value: "28"
        },
        {
          value: "30"
        },
        {
          value: "35"
        },
        {
          value: "37"
        },
        {
          value: "40"
        },
        {
          value: "47"
        },
        {
          value: "35"
        },
        {
          value: "37"
        },
        {
          value: "40"
        },
        {
          value: "47"
        }
      ]
    },
    {
      seriesname: "Accuracy",
      color: "#F24444",
      data: [
        {
          value: "62"
        },
        {
          value: "70"
        },
        {
          value: "76"
        },
        {
          value: "79"
        },
        {
          value: "80"
        },
        {
          value: "78"
        },
        {
          value: "72"
        },
        {
          value: "78"
        },
        {
          value: "70"
        },
        {
          value: "68"
        },
        {
          value: "64"
        },
        {
          value: "60"
        }
      ]
    },

  ]
}
