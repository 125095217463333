import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import Polyline from "@arcgis/core/geometry/Polyline";
import Color from "@arcgis/core/Color";
import Graphic from "@arcgis/core/Graphic";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import _ from 'lodash';
import { geodesicDensify } from '@arcgis/core/geometry/geometryEngine';
import SizeVariable from '@arcgis/core/renderers/visualVariables/SizeVariable';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import FeatureLayer  from "@arcgis/core/layers/FeatureLayer";
import { MapService } from '../../../../../../shared-rail-performance/services/map-service';
import { Trip } from '../../../../../models/trip/trip';

@Injectable({
  providedIn: 'root'
})
export class AverageTripTimeMapService extends MapService implements OnDestroy {
  colorVariableStops$ = new BehaviorSubject<any[]>([]);

  private sub = new SubSink();

  constructor(ngZone: NgZone) {
    super(ngZone);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadColorVariableStops(max: number, mean?: number) {
    let adjustedMax = Math.max(Math.ceil(max), 1);
    let middle = mean ?? adjustedMax / 2;

    this.colorVariableStops$.next([
      {
        value: 0,
        color: new Color([140, 192, 89]),
        size: 1
      },
      {
        value: middle + ((adjustedMax - middle) / 2),
        color: new Color([243, 207, 96]),
        size: 1.5
      },
      {
        value: adjustedMax,
        color: new Color([212, 104, 93]),
        size: 2
      }
    ]);
  }

  loadTrips(mapViewElement: ElementRef, trips: Trip[], expandButtonClick?: () => void) {
    return this.ngZone.runOutsideAngular(async () => {
      if (trips) {
        let max = Math.max(...trips.map(x => x.tripTimeAverage ?? 0));
        let mean = _.mean(trips.map(x => x.tripTimeAverage ?? 0));

        this.loadColorVariableStops(max, mean);
      }

      const simpleLineSymbol = new SimpleLineSymbol({
        width: 1,
        cap: "round",
        join: "round",
        style: "solid"
      });

      const tripRenderer = new SimpleRenderer({
        symbol: simpleLineSymbol,
        label: "Trip",
        visualVariables: [
          new SizeVariable({
            field: 'average-cyle-time',
            stops: this.colorVariableStops$.value
          }),
          new ColorVariable({
            field: 'average-cyle-time',
            stops: this.colorVariableStops$.value
          })
        ]
      });

      let tripLineGraphics = this.getTripLineGraphics(trips);

      let tripLayer = new FeatureLayer({
        renderer: tripRenderer,
        id: 'trips-layer',
        objectIdField: "OBJECTID",
        outFields: ['*'],
        fields: [
          {
            name: 'average-cyle-time',
            type: 'string'
          },
          {
            name: "OBJECTID",
            type: "oid"
          }
        ],
        source: tripLineGraphics
      });

      return this.loadMap(mapViewElement, {
        layers: [tripLayer],
        zoomLevel: 2,
        expandButtonClick,
      }).then((view: MapView) => {
        //this.disableZooming(view);
        return view;
      }).then((view: MapView) => {
        return view;
      });
    });
  }

  expandButtonClick() {

  }

  getTripLineGraphics(trips: Trip[]) {
    let graphics: Graphic[] = [];

    for (let trip of trips) {
      let polyline = new Polyline({
        paths: [
          [
            [trip.originLongitude, trip.originLatitude],
            [trip.destinationLongitude, trip.destinationLatitude],
          ]
        ],
      });

      let curvedPolyline = geodesicDensify(polyline, 1000);

      let graphic = new Graphic({
        geometry: curvedPolyline,
        attributes: {
          'average-cyle-time': trip.tripTimeAverage,
          'trip-count': trip.tripCount,
        },
      });

      graphics.push(graphic);
    }

    return graphics;
  }

  private clickedStationEvent(response: any, mapView: MapView) {
    if (response.results.length) {
      const graphic = response.results[0].graphic as Graphic;
      const attributes = graphic.attributes;
      const name = attributes['state-province-code'];
    }
  }
}
