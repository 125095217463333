<div class="forecast-demand-report">
  <div class="d-flex justify-content-between forecast-demand-border">
    <app-forecast-demand-report-filters [reportTitle]="reportTitle"></app-forecast-demand-report-filters>
  </div>
  <div class="row d-flex justify-content-between forecast-demand-border">
    <div class="col-4">
      <div class="visual_title ps-1">Forecast Demand</div>
      <div class="visual_subtitle ps-1">Description</div>
    </div>
    <div>
      <div class="legend">
        <span class="dot-planned"></span>
        <span>Released</span>
        &nbsp;
        &nbsp;
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span>Forecasted</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed"></span>
        <span>Orders Missed</span>

      </div>
    </div>
    <div class="d-flex justify-content-around" id="forcastSummaryReport" #forcastSummaryReport>
      @if (dataSource) {
      <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
                    [dataSource]="dataSource" [renderAt]="'forcastSummaryReport'">
      </fusioncharts>
      }
    </div>
    <div class="h-100 mb-2">
      <div class="col-12 card-bg">
        <table class="borderenabled w-100">
          <tr>
            <th>
              <label class=""></label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth1}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth2}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth3}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth4}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth5}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth6}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth7}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth8}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth9}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth10}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth11}}</label>
            </th>
            <th>
              <label class="">{{fulfillmentColumns.lastMonth12}}</label>
            </th>
          </tr>

          <tr *ngFor="let dt  of fulfillmentData">
            <td>
              <label [ngClass]="{'table-first-cell-left-padding':!dt.isDataSet}">{{dt.label}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth1}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth2}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth3}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth4}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth5}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth6}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth7}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth8}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth9}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth10}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth11}}</label>
            </td>
            <td>
              <label class="">{{dt.lastMonth12}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
