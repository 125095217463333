import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { ExpansionPanelComponent } from "@progress/kendo-angular-layout";
import { QueryObserverResult } from "@tanstack/query-core";
import { BehaviorSubject, Observable } from "rxjs";
import { Country } from "../../../../shared-rail-performance/models/location/country";
import { StateProvince } from "../../../../shared-rail-performance/models/location/state-province";
import { CommodityDataService } from "../../../../shared-rail-performance/services/commodity-data.service";
import { CustomerListDataService } from "../../../../shared-rail-performance/services/customer-list-data.service";
import { LocationDataService } from "../../../../shared-rail-performance/services/location-data.service";
import { MotionStatuses } from "../../../constants/motion-status";
import { LoadedStatuses } from "../../../constants/loaded-status";
import { Region } from "../../../../shared-rail-performance/models/location/region";
import { Station } from "../../../../shared-rail-performance/models/location/station";
import { SubSink } from "subsink";
import { DashboardType } from "../../../constants/dashboard-type";
import { TravelStatuses } from "../../../constants/travel-status";
import { DashboardReportFormService } from "../../layout/services/dashboard-report-form.service";
import { FormattingService } from "../../../../shared-rail-performance/services/formatting.service";
import { PagedResult } from "../../../../shared-rail-performance/models/paging/paged-result";
import { Customer } from "../../../../admin/models/customer";
import { CustomerFilter } from "../../../../admin/models/customer-filter";
import { ComboBoxComponent } from "@progress/kendo-angular-dropdowns";
import { CustomerFilterType } from "../../../../shared-rail-performance/models/constants/customer-filter-type";
import { UserService } from "../../../../admin/services/user.service";

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrl: './dashboard-filters.component.scss',
})
export class DashboardFiltersComponent implements OnInit, OnDestroy {
  @Input() showFilters$ = new BehaviorSubject<boolean>(false);
  @ViewChild('filterExpansionPanel') filterExpansionPanel?: ExpansionPanelComponent;

  public customersQueryResults$?: Observable<QueryObserverResult<PagedResult<CustomerFilter>, Error>>;
  public countriesQueryResults$?: Observable<QueryObserverResult<Country[], Error>>;
  public statesProvincesQueryResults$?: Observable<QueryObserverResult<StateProvince[], Error>>;
  public stationsWithSplcQueryResults$?: Observable<QueryObserverResult<Station[], Error>>;
  public stationsWithoutSplcQueryResults$?: Observable<QueryObserverResult<Station[], Error>>;
  public regionsQueryResults$?: Observable<QueryObserverResult<Region[], Error>>;
  public commoditiesQueryResults$?: Observable<QueryObserverResult<string[], Error>>;

  public inMotionValues = MotionStatuses;
  public travelStatusValues = TravelStatuses;
  public loadedValues = LoadedStatuses;
  public regionValues = [];

  public DashboardType = DashboardType;

  public showCommodity: boolean = false;

  public virtual: any = {
    itemHeight: 28,
  };

  private sub = new SubSink();

  constructor(private CustomerListDataService: CustomerListDataService, 
              private locationDataService: LocationDataService, 
              private commodityDataService: CommodityDataService,
              public dashboardFormService: DashboardReportFormService,
              public formattingService: FormattingService,
              private userService: UserService) {
              }

  ngOnInit(): void {
    this.customersQueryResults$ = this.CustomerListDataService.getCustomers().result$;
    this.countriesQueryResults$ = this.locationDataService.getCountries().result$;
    this.statesProvincesQueryResults$ = this.locationDataService.getStatesProvinces().result$;
    this.stationsWithSplcQueryResults$ = this.locationDataService.getStations(true).result$;
    this.stationsWithoutSplcQueryResults$ = this.locationDataService.getStations(false).result$;
    this.regionsQueryResults$ = this.locationDataService.getRegions().result$;
    this.commoditiesQueryResults$ = this.commodityDataService.getCommodities().result$;

    this.sub.sink = this.customersQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('customers')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('customers')?.enable();
      }
    });

    this.sub.sink = this.countriesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('countries')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('countries')?.enable();
      }
    });

    this.sub.sink = this.statesProvincesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('statesProvinces')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('statesProvinces')?.enable();
      }
    });

    this.sub.sink = this.commoditiesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('commodities')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('commodities')?.enable();
      }
    });

    this.sub.sink = this.showFilters$.subscribe((showFilters) => {
      if (this.filterExpansionPanel?.expanded != showFilters) {
        this.filterExpansionPanel?.toggle();
      }
    });

    this.sub.sink = this.dashboardFormService.commonFiltersFormGroup.get('customers')?.valueChanges.subscribe(() => {
      if ((this.dashboardFormService.commonFiltersFormGroup.get('customers')?.value?.length ?? 0) > 0
          && (this.dashboardFormService.commonFilters?.customers?.filter(x => x.customerFilterType == CustomerFilterType.Group)?.length ?? 0) > 0) {
        this.dashboardFormService.commonFiltersFormGroup.get('commodities')?.setValue([]);
      }
    });

    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.showCommodity = (user?.customerGroupUserRoles?.length ?? 0) === 0;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  applyFilters() {
    this.dashboardFormService.applyFilters();
  }

  clearFilters() {
    this.dashboardFormService.filtersFormGroup.reset();
    this.dashboardFormService.filtersFormGroup.markAsPristine();
    this.applyFilters();
  }

  public onFocusCombo(comboBox: ComboBoxComponent) {
    if (!comboBox.isOpen) {
      comboBox.toggle(true);
    }
  }
}
