import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomerManagementComponent } from "./components/customer/customer-management/customer-management.component";
import { UserManagementComponent } from "./components/user/user-management/user-management.component";

const routes: Routes = [
  {
    path: 'customer',
    children: [
      {
        path: 'management',
        component: CustomerManagementComponent,
      }
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: 'management',
        component: UserManagementComponent,
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }