[
    {
        "name": "Canada",
        "code": "CA",
        "divisions": [
            {
                "code": "AB",
                "name": "Alberta"
            },
            {
                "code": "BC",
                "name": "British Columbia"
            },
            {
                "code": "MB",
                "name": "Manitoba"
            },
            {
                "code": "NB",
                "name": "New Brunswick"
            },
            {
                "code": "NL",
                "name": "Newfoundland and Labrador"
            },
            {
                "code": "NS",
                "name": "Nova Scotia"
            },
            {
                "code": "ON",
                "name": "Ontario"
            },
            {
                "code": "PE",
                "name": "Prince Edward Island"
            },
            {
                "code": "QC",
                "name": "Quebec"
            },
            {
                "code": "SK",
                "name": "Saskatchewan"
            },
            {
                "code": "NT",
                "name": "Northwest Territories"
            },
            {
                "code": "NU",
                "name": "Nunavut"
            },
            {
                "code": "YT",
                "name": "Yukon Territory"
            }
        ]
    },
    {
        "name": "Mexico",
        "code": "MX",
        "divisions": [
            {
                "code": "DIF",
                "name": "Distrito Federal"
            },
            {
                "code": "AG",
                "name": "Aguascalientes"
            },
            {
                "code": "BN",
                "name": "Baja California"
            },
            {
                "code": "BS",
                "name": "Baja California Sur"
            },
            {
                "code": "CP",
                "name": "Campeche"
            },
            {
                "code": "CH",
                "name": "Coahuila"
            },
            {
                "code": "CL",
                "name": "Colima"
            },
            {
                "code": "CS",
                "name": "Chiapas"
            },
            {
                "code": "CI",
                "name": "Chihuahua"
            },
            {
                "code": "DG",
                "name": "Durango"
            },
            {
                "code": "GE",
                "name": "Guerrero"
            },
            {
                "code": "GJ",
                "name": "Guanajuato"
            },
            {
                "code": "HD",
                "name": "Hidalgo"
            },
            {
                "code": "JA",
                "name": "Jalisco"
            },
            {
                "code": "MX",
                "name": "México"
            },
            {
                "code": "MC",
                "name": "Michoacan"
            },
            {
                "code": "MR",
                "name": "Morelos"
            },
            {
                "code": "NA",
                "name": "Nayarit"
            },
            {
                "code": "NL",
                "name": "Nuevo Leon"
            },
            {
                "code": "OA",
                "name": "Oaxaca"
            },
            {
                "code": "PU",
                "name": "Puebla"
            },
            {
                "code": "QE",
                "name": "Queretaro"
            },
            {
                "code": "QI",
                "name": "Quintana Roo"
            },
            {
                "code": "SL",
                "name": "San Luis Potosi"
            },
            {
                "code": "SI",
                "name": "Sinaloa"
            },
            {
                "code": "SO",
                "name": "Sonora"
            },
            {
                "code": "TB",
                "name": "Tabasco"
            },
            {
                "code": "TA",
                "name": "Tamaulipas"
            },
            {
                "code": "TL",
                "name": "Tlaxcala"
            },
            {
                "code": "VC",
                "name": "Veracruz"
            },
            {
                "code": "YU",
                "name": "Yucatan"
            },
            {
                "code": "ZA",
                "name": "Zacatecas"
            }
        ]
    },
    {
        "name": "United States",
        "code": "US",
        "divisions": [
            {
                "code": "AL",
                "name": "Alabama"
            },
            {
                "code": "AK",
                "name": "Alaska"
            },
            {
                "code": "AZ",
                "name": "Arizona"
            },
            {
                "code": "AR",
                "name": "Arkansas"
            },
            {
                "code": "CA",
                "name": "California"
            },
            {
                "code": "CO",
                "name": "Colorado"
            },
            {
                "code": "CT",
                "name": "Connecticut"
            },
            {
                "code": "DE",
                "name": "Delaware"
            },
            {
                "code": "FL",
                "name": "Florida"
            },
            {
                "code": "GA",
                "name": "Georgia"
            },
            {
                "code": "HI",
                "name": "Hawaii"
            },
            {
                "code": "ID",
                "name": "Idaho"
            },
            {
                "code": "IL",
                "name": "Illinois"
            },
            {
                "code": "IN",
                "name": "Indiana"
            },
            {
                "code": "IA",
                "name": "Iowa"
            },
            {
                "code": "KS",
                "name": "Kansas"
            },
            {
                "code": "KY",
                "name": "Kentucky"
            },
            {
                "code": "LA",
                "name": "Louisiana"
            },
            {
                "code": "ME",
                "name": "Maine"
            },
            {
                "code": "MD",
                "name": "Maryland"
            },
            {
                "code": "MA",
                "name": "Massachusetts"
            },
            {
                "code": "MI",
                "name": "Michigan"
            },
            {
                "code": "MN",
                "name": "Minnesota"
            },
            {
                "code": "MS",
                "name": "Mississippi"
            },
            {
                "code": "MO",
                "name": "Missouri"
            },
            {
                "code": "MT",
                "name": "Montana"
            },
            {
                "code": "NE",
                "name": "Nebraska"
            },
            {
                "code": "NV",
                "name": "Nevada"
            },
            {
                "code": "NH",
                "name": "New Hampshire"
            },
            {
                "code": "NJ",
                "name": "New Jersey"
            },
            {
                "code": "NM",
                "name": "New Mexico"
            },
            {
                "code": "NY",
                "name": "New York"
            },
            {
                "code": "NC",
                "name": "North Carolina"
            },
            {
                "code": "ND",
                "name": "North Dakota"
            },
            {
                "code": "OH",
                "name": "Ohio"
            },
            {
                "code": "OK",
                "name": "Oklahoma"
            },
            {
                "code": "OR",
                "name": "Oregon"
            },
            {
                "code": "PA",
                "name": "Pennsylvania"
            },
            {
                "code": "RI",
                "name": "Rhode Island"
            },
            {
                "code": "SC",
                "name": "South Carolina"
            },
            {
                "code": "SD",
                "name": "South Dakota"
            },
            {
                "code": "TN",
                "name": "Tennessee"
            },
            {
                "code": "TX",
                "name": "Texas"
            },
            {
                "code": "UT",
                "name": "Utah"
            },
            {
                "code": "VT",
                "name": "Vermont"
            },
            {
                "code": "VA",
                "name": "Virginia"
            },
            {
                "code": "WA",
                "name": "Washington"
            },
            {
                "code": "WV",
                "name": "West Virginia"
            },
            {
                "code": "WI",
                "name": "Wisconsin"
            },
            {
                "code": "WY",
                "name": "Wyoming"
            },
            {
                "code": "DC",
                "name": "District of Columbia"
            },
            {
                "code": "AS",
                "name": "American Samoa"
            },
            {
                "code": "GU",
                "name": "Guam"
            },
            {
                "code": "MP",
                "name": "Northern Mariana Islands"
            },
            {
                "code": "PR",
                "name": "Puerto Rico"
            },
            {
                "code": "UM",
                "name": "United States Minor Outlying Islands"
            },
            {
                "code": "VI",
                "name": "Virgin Islands, U.S."
            }
        ]
    }
]