import { Injectable } from '@angular/core';
import { CommodityDataService } from './commodity-data.service';
import { CustomerListDataService } from './customer-list-data.service';
import { LocationDataService } from './location-data.service';
import { combineLatest } from 'rxjs';
import { EnvironmentInformationDataService } from './environment-information-data.service';

@Injectable({
  providedIn: 'root'
})
export class PrefetchService {
  constructor(private commodityDataService: CommodityDataService,
              private CustomerListDataService: CustomerListDataService,
              private locationDataService: LocationDataService,
              private environmentInformationDataService: EnvironmentInformationDataService
  ) { }

  prefetch() {
    return combineLatest([
      this.commodityDataService.prefetch(),
      this.CustomerListDataService.prefetch(),
      this.locationDataService.prefetch(),
      this.environmentInformationDataService.prefetch()
    ]);
  }
}
