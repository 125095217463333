<div class="col p-0 ps-3 pe-3">
    <!-- @if (queryResult$ | async; as result) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    <fusioncharts type="mscombi2d" [dataSource]="result.data ?? {}"></fusioncharts>
    } -->
    <div class="row">
        <div class="legend">
            <span class="fulfillment"></span>
            <span>Fulfillment</span>
            &nbsp;
            &nbsp;
            <span class="min-fulfillment"></span>
            <span class="min-fulfillment"></span>
            <span class="min-fulfillment"></span>
            <span class="min-fulfillment"></span>
            <span>Min Fulfillment</span>
            &nbsp;
            &nbsp;
            <span class="accuracy"></span>
            <span>Accuracy</span>
            &nbsp;
            &nbsp;
            <span class="min-accuracy"></span>
            <span class="min-accuracy"></span>
            <span class="min-accuracy"></span>
            <span class="min-accuracy"></span>
            <span>Min Accuracy</span>
        </div>
        <div class="col-6">
            <div class="row h-100">
                <div class="col-12 card-bg">
                    <div class="row m-2">
                        <label class="col text-center fw-bolder">Fulfillment</label>
                    </div>
                    <table class="firstmilelastmile-table">
                        <tr>
                            <td class="padding-3px"> <label></label></td>
                            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
                                <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Fulfillment</label></td>
                            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
                              <label class="fulfilled-color fw-bolder">{{dt.fulfillment | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Maximum</label></td>
                            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
                              <label class="fulfilled-color fw-bolder">{{dt.maxFulfillment | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Minimum</label></td>
                            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
                              <label class="fulfilled-color fw-bolder">{{dt.minFulfillment | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                    </table>                  
                </div>

                <div class="col-12 card-bg">
                    <div class="row m-2">
                        <label class="col text-center fw-bolder">Accuracy</label>
                    </div>
                    <table class="firstmilelastmile-table">
                        <tr>
                            <td class="padding-3px"> <label></label></td>
                            <td class="padding-3px" *ngFor="let dt  of accuracyData">
                                <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Accuracy</label></td>
                            <td class="padding-3px" *ngFor="let dt of accuracyData">
                              <label class="fulfilled-color fw-bolder">{{dt.accuracy | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Maximum</label></td>
                            <td class="padding-3px" *ngFor="let dt  of accuracyData">
                              <label class="fulfilled-color fw-bolder">{{dt.maxAccuracy | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-3px"> <label class="fw-light-lbl">Minimum</label></td>
                            <td class="padding-3px" *ngFor="let dt  of accuracyData">
                              <label class="fulfilled-color fw-bolder">{{dt.minAccuracy | percent: '1.2-2'}}</label>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-6">
            @if (dataSource) {
            <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
                [dataSource]="dataSource">
            </fusioncharts>
            <!-- <fusioncharts type="doughnut2d"   [dataSource]="dataSource2" chartLeftMargin='25'
            chartRightMargin='0'
            width="300"
            chartTopMargin='10'
            chartBottomMargin='0'  
            enableSmartLabels='1'
            manageLabelOverflow='1'
            useEllipsesWhenOverflow='0'
            showBorder='0'
            showLegend="0"></fusioncharts> -->
            }
        </div>
    </div>
</div>
