import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CustomerManagementComponent } from "./components/customer/customer-management/customer-management.component";
import { CustomerGridComponent } from "./components/customer/customer-grid/customer-grid.component";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../common/common-imports";
import { SharedRailPerformanceModule } from "../shared-rail-performance/shared-rail-performance.module";
import { AdminRoutingModule } from "./admin-routing.module";
import { CustomerEditComponent } from "./components/customer/customer-edit/customer-edit.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserManagementComponent } from "./components/user/user-management/user-management.component";
import { UserGridComponent } from "./components/user/user-grid/user-grid.component";
import { UserEditComponent } from "./components/user/user-edit/user-edit.component";

@NgModule({
    imports: [
      SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
      CommonModule,
      AngularSvgIconModule,
      AdminRoutingModule,
      ReactiveFormsModule,
      FormsModule
    ],
    exports: [
      
    ],
    declarations: [
      CustomerManagementComponent,
      CustomerGridComponent,
      CustomerEditComponent,
      UserManagementComponent,
      UserGridComponent,
      UserEditComponent
    ],
    schemas: [],
})
export class AdminModule { 
  constructor() {

  }
}
