import { Injectable } from "@angular/core";
import { DataService } from "../../shared-rail-performance/services/data/data.service";
import { HttpClient } from "@angular/common/http";
import { User } from "../models/user";
import { UserDto } from "../dto/user-dto";
import { NotificationService } from "@progress/kendo-angular-notification";

@Injectable({
    providedIn: 'root'
})
export class UserDataService extends DataService<User, UserDto> {
    constructor(httpClient: HttpClient, notificationService: NotificationService) {
        super(httpClient, notificationService, {
            resourceEndpoint: 'Users'
        });
    }
}