import { AfterViewInit, Component, NO_ERRORS_SCHEMA, OnInit, ViewEncapsulation, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { ChartSeries } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { ChartSeriesData } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { SubSink } from 'subsink';
import { cloneDeep } from 'lodash';
import { SwitchingPerformanceDto, SwitchingPerformanceYearReport } from '../../../../../models/switching-performance-report';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-forecast-switching-performance-report',
  templateUrl: './forecast-switching-performance-report.component.html',
  styleUrl: './forecast-switching-performance-report.component.scss',
  providers: [PercentPipe]
})
export class ForecastSwitchingPerformanceReportComponent implements OnInit {

  private sub = new SubSink();
  isSPReportByLoading$ = new BehaviorSubject<boolean>(true);

  private sub2 = new SubSink();
  isSPReportByDonutsLoading$ = new BehaviorSubject<boolean>(true);

  fulfillmentData: SwitchingPerformanceYearReport[] = [];
  reportTitle = "Switching Performance";
 
  width = "1500";
  height = "280";
  type = "msline";
  dataFormat = "json";

  dataSource: any;
  dataSource2: any;
  dataSource3: any;
  dataSource4: any;
  noData: boolean = true;


  @ViewChild('switchingPerformanceYearLineReport') childelement: ElementRef | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.chartWidth();
  }

  ngAfterViewInit() {
    this.chartWidth();
  }

  private chartWidth() {
    const child = this.childelement?.nativeElement;
    this.width = (Number(child.offsetWidth) - 100).toString();
  }

  ngOnInit(): void {
    this.sub.sink = this.kpiDataService.getSwitchingPerformanceYearReport().subscribe((dwellKpi: SwitchingPerformanceYearReport[]) => {
      this.loadSPYearReport(dwellKpi);
      this.isSPReportByLoading$.next(false);
    });

    this.sub2.sink = this.kpiDataService.getSwitchingPerformanceReport().subscribe((dwellKpi: SwitchingPerformanceDto) => {
      this.loadSPReport(dwellKpi);
      this.isSPReportByDonutsLoading$.next(false);
    });
  }


  loadSPReport(dataset: SwitchingPerformanceDto | null) {
    if (dataset == null) return;
    this.dataSource2 = this.setMonthlySPReport(dataset);
    this.dataSource3 = this.setcurrentYearReport(dataset);
    this.dataSource4 = this.setpreviousYearReport(dataset);
  }

  setMonthlySPReport(dataset: SwitchingPerformanceDto) {
    var monthlyData = cloneDeep(this.donutDataSource);
    monthlyData.data[0].value = dataset.monthReport.performedAsPlanned * 100;
    monthlyData.data[1].value = dataset.monthReport.inaccurate * 100;
    monthlyData.data[2].value = dataset.monthReport.missed * 100;
    monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText;
    monthlyData.chart.centerLabel = dataset.monthReport.labelText;
    if (dataset.monthReport.performedAsPlanned == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.inaccurate == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.missed == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.performedAsPlanned == 0 &&
      dataset.monthReport.inaccurate == 0 &&
      dataset.monthReport.missed == 0) {
      monthlyData.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      monthlyData.chart.showToolTip = "0";
      monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText + " No Data";
      monthlyData.chart.centerLabel = dataset.monthReport.labelText + " No Data";
    }

    if (monthlyData.data.length <= 1) {
      monthlyData.chart.showpercentvalues = "0";
      monthlyData.chart.valuePosition = "none";
    }
    return monthlyData;
  }
   
  setcurrentYearReport(dataset: SwitchingPerformanceDto) {
    var currentYearReport = cloneDeep(this.donutDataSource);
    currentYearReport.data[0].value = dataset.currentYearReport.performedAsPlanned * 100;
    currentYearReport.data[1].value = dataset.currentYearReport.inaccurate * 100;
    currentYearReport.data[2].value = dataset.currentYearReport.missed * 100;
    currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText;
    currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText;
    if (dataset.currentYearReport.performedAsPlanned == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.inaccurate == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.missed == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.performedAsPlanned == 0 &&
      dataset.currentYearReport.inaccurate == 0 &&
      dataset.currentYearReport.missed == 0) {
      currentYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      currentYearReport.chart.showToolTip = "0";
      currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText + " No Data";
      currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText + " No Data";
    }
    if (currentYearReport.data.length <= 1) {
      currentYearReport.chart.showpercentvalues = "0";
      currentYearReport.chart.valuePosition = "none";     
    }
    return currentYearReport;
  }
  setpreviousYearReport(dataset: SwitchingPerformanceDto) {
    var previousYearReport = cloneDeep(this.donutDataSource);
    previousYearReport.data[0].value = dataset.previousYearReport.performedAsPlanned * 100;   
    previousYearReport.data[1].value = dataset.previousYearReport.inaccurate * 100;  
    previousYearReport.data[2].value = dataset.previousYearReport.missed * 100;
    if (dataset.previousYearReport.performedAsPlanned == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    if (dataset.previousYearReport.inaccurate == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    if (dataset.previousYearReport.missed == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText;
    previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText;
    if (dataset.previousYearReport.performedAsPlanned == 0 &&
      dataset.previousYearReport.inaccurate == 0 &&
      dataset.previousYearReport.missed == 0) {
      previousYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      previousYearReport.chart.showToolTip = "0";
      previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText + " No Data";
      previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText + " No Data";
    }
    if (previousYearReport.data.length <= 1) {
      previousYearReport.chart.showpercentvalues = "0";
      previousYearReport.chart.valuePosition = "none";
    }
    return previousYearReport;
  }

  loadSPYearReport(dataset: SwitchingPerformanceYearReport[]) {
    if (dataset == null) return;
    this.fulfillmentData = dataset;
    this.dataSource2 = this.setSPTableReport(dataset);
  }

  setSPTableReport(dataset: SwitchingPerformanceYearReport[]) {
    var monthlyData = cloneDeep(this.dataSource);
    this.mapReportData(dataset);
  }


  mapReportData(dataset: SwitchingPerformanceYearReport[]) {
    var reportDataSource = cloneDeep(this.getSPReportLineChart());
    let categories = [{ category: dataset.map(item => ({ label: item.monthName })) }];

    let performedAsPlanned = dataset.map(item => ({ value: this.percentPipe.transform(item.performedAsPlanned , '1.2-2') }));
    let inaccurate = dataset.map(item => ({ value: this.percentPipe.transform(item.inaccurate, '1.2-2') }));
    let missed = dataset.map(item => ({ value: this.percentPipe.transform(item.missed, '1.2-2') }));

    (reportDataSource.categories as { category: { label: string }[] }[]) = categories;

    reportDataSource.dataset.forEach((line: any) => {
      if (line.seriesname == "Performed as Planned") {
        line.data = performedAsPlanned;
      }
      if (line.seriesname == "Inaccurate") {
        line.data = inaccurate;
      }
      if (line.seriesname == "Missed") {
        line.data = missed;
      }
    });
    dataset.forEach((line: any) => {
      if (line.performedAsPlanned > 0 || line.inaccurate > 0 || line.missed > 0) {
        this.noData = false;
      }
    });
    this.dataSource = reportDataSource;
  }

  constructor(private kpiDataService: KpiDataService,
    private percentPipe: PercentPipe
  ) {

  }

  donutDataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      numberSuffix: "%",
      showlegend: "0",
      showLabels: "0",
      defaultcenterlabel: "May 2024",
      useEllipsesWhenOverflow: true,
      labelDistance: "50",
      centerLabel: "May 2024",
      centerLabelFontSize: "14",
      centerLabelFontColor: "black",
      centerLabelBold: "1",
      showpercentvalues: "1",
      aligncaptionwithcanvas: "0",
      pieRadius: "100",
      doughnutRadius: "50",
      captionpadding: "0",
      decimals: "1",
      // label value styles
      valueFontColor: "#474747", // Change value font color
      patternBgColor: "#F44444",
      labelBorderRadius: 14,
      labelBgColor: "#FAFAFA", //#FDFDFD,
      labelFontSize: 14,
      valuePosition: "inside",
      valueBgColor: "#000000", // Background color for values (effectively works in tooltip)
      valueBgAlpha: "60", // Opacity for the background color
    },
    data: [
      { label: "Performed as Planned", value: "0", color: "#1B365D"},
      { label: "Inaccurate", value: "0", color: "#F44444" },
      { label: "Missed", value: "0", color: "#F2D675" }
    ],

  };
  getSPReportLineChart() {
    let lineChartData = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        showlegend: "0",
        numbersuffix: "%",
        drawcrossline: "1",
        drawAnchors: "0",
        plottooltext: "<b>$dataValue</b> of $seriesName",
        theme: "fusion"
      },
      categories: [],
      dataset: [
        {
          seriesname: "Performed as Planned",
          color: "#1B365D",
          data: []
        },
        {
          seriesname: "Inaccurate",
          color: "#F24444",
          dashed: "1",
          data: []
        },
        {
          seriesname: "Missed",
          color: "#F2D675",
          dashed: "1",
          data: []
        }
      ]
    }
    return lineChartData;
  }
};
