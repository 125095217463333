import * as i0 from '@angular/core';
import { InjectionToken, makeEnvironmentProviders, runInInjectionContext, inject, Injectable, PLATFORM_ID, Injector, assertInInjectionContext, computed } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { QueryClient as QueryClient$1, notifyManager, InfiniteQueryObserver, MutationObserver, QueryObserver } from '@tanstack/query-core';
export * from '@tanstack/query-core';
import { Subject, firstValueFrom, takeUntil, isObservable, Observable, shareReplay, distinctUntilChanged, map, filter, tap, takeWhile, startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
const QUERY_CLIENT_OPTIONS = new InjectionToken('QUERY_CLIENT_OPTIONS', {
  providedIn: 'root',
  factory() {
    return {};
  }
});
function provideQueryClientOptions(options) {
  return makeEnvironmentProviders([{
    provide: QUERY_CLIENT_OPTIONS,
    [typeof options === 'function' ? 'useFactory' : 'useValue']: options
  }]);
}
function toPromise({
  source,
  signal
}) {
  const cancel = new Subject();
  if (signal) {
    signal.addEventListener('abort', () => {
      cancel.next();
      cancel.complete();
    });
  }
  return firstValueFrom(source.pipe(signal ? takeUntil(cancel) : s => s));
}
function createSuccessObserverResult(data) {
  return {
    data,
    isLoading: false,
    isError: false,
    isFetching: false,
    isPending: false,
    isSuccess: true,
    status: 'success'
  };
}
function createPendingObserverResult() {
  return {
    isError: false,
    isLoading: true,
    isPending: true,
    isFetching: true,
    isSuccess: false,
    fetchStatus: 'fetching',
    status: 'pending'
  };
}
function shouldThrowError(throwError, params) {
  if (typeof throwError === 'function') {
    return throwError(...params);
  }
  return !!throwError;
}
function queryOptions(options) {
  return options;
}
function normalizeOptions(client, options, injector) {
  const defaultedOptions = client.defaultQueryOptions(options);
  defaultedOptions._optimisticResults = 'optimistic';
  const originalQueryFn = defaultedOptions.queryFn;
  if (originalQueryFn) {
    defaultedOptions.queryFn = function (ctx) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this;
      return runInInjectionContext(injector, () => {
        const value = originalQueryFn.call(_this, ctx);
        if (isObservable(value)) {
          return toPromise({
            source: value,
            signal: ctx.signal
          });
        }
        return value;
      });
    };
  }
  return defaultedOptions;
}
const QueryClientToken = new InjectionToken('QueryClient', {
  providedIn: 'root',
  factory() {
    return new QueryClient(inject(QUERY_CLIENT_OPTIONS));
  }
});
class QueryClientMount {
  constructor() {
    this.instance = inject(QueryClientToken);
    this.instance.mount();
  }
  ngOnDestroy() {
    this.instance.unmount();
  }
  static {
    this.ɵfac = function QueryClientMount_Factory(t) {
      return new (t || QueryClientMount)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QueryClientMount,
      factory: QueryClientMount.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QueryClientMount, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const QueryClientService = new InjectionToken('QueryClientService', {
  providedIn: 'root',
  factory() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      inject(QueryClientMount);
    }
    return inject(QueryClientToken);
  }
});
/** @public */
function provideQueryClient(queryClient) {
  return {
    provide: QueryClientToken,
    useValue: queryClient
  };
}
/** @public */
function injectQueryClient() {
  return inject(QueryClientService);
}
/** should be exported for @test */
class QueryClient extends QueryClient$1 {
  #injector = inject(Injector);
  ensureQueryData(options) {
    const defaultedOptions = normalizeOptions(this, options, this.#injector);
    return super.ensureQueryData(defaultedOptions);
  }
  fetchQuery(options) {
    const defaultedOptions = normalizeOptions(this, options, this.#injector);
    return super.fetchQuery(defaultedOptions);
  }
  prefetchQuery(options) {
    const defaultedOptions = normalizeOptions(this, options, this.#injector);
    return super.prefetchQuery(defaultedOptions);
  }
  fetchInfiniteQuery(options) {
    const defaultedOptions = normalizeOptions(this, options, this.#injector);
    return super.fetchInfiniteQuery(defaultedOptions);
  }
  prefetchInfiniteQuery(options) {
    const defaultedOptions = normalizeOptions(this, options, this.#injector);
    return super.prefetchInfiniteQuery(defaultedOptions);
  }
}
function createBaseQuery({
  client,
  Observer,
  options,
  injector
}) {
  let queryObserver;
  const defaultedOptions = normalizeOptions(client, options, injector);
  const result$ = new Observable(observer => {
    // Lazily create the observer when the first subscription is received
    if (!queryObserver) {
      queryObserver = new Observer(client, defaultedOptions);
    }
    observer.next(queryObserver.getOptimisticResult(defaultedOptions));
    const queryObserverDispose = queryObserver.subscribe(notifyManager.batchCalls(result => {
      if (!result.isFetching && result.isError && shouldThrowError(queryObserver.options.throwOnError, [result.error, queryObserver.getCurrentQuery()])) {
        observer.error(result.error);
      } else {
        observer.next(defaultedOptions.notifyOnChangeProps ? result : queryObserver?.trackResult(result));
      }
    }));
    return () => {
      queryObserverDispose();
      queryObserver = undefined;
    };
  }).pipe(shareReplay({
    bufferSize: 1,
    refCount: true
  }));
  let cachedSignal;
  const isNodeInjector = injector && injector['_tNode'];
  return {
    result$,
    updateOptions: newOptions => {
      if (queryObserver) {
        const normalized = normalizeOptions(client, {
          ...options,
          ...newOptions
        }, injector);
        queryObserver.setOptions(normalized, {
          listeners: false
        });
      }
    },
    // @experimental signal support
    get result() {
      !isNodeInjector && assertInInjectionContext(function queryResultSignal() {
        // noop
      });
      if (!cachedSignal) {
        cachedSignal = toSignal(this.result$, {
          requireSync: true,
          // R3Injector isn't good here because it will cause a leak
          // We only need the NodeInjector as we want the subscription to be destroyed when the component is destroyed
          // We check that it's a NodeInjector by checking if it has a _tNode property
          // Otherwise we just pass undefined and it'll use the current injector
          // and not the R3Injector that we pass in the service
          injector: isNodeInjector ? injector : undefined
        });
      }
      return cachedSignal;
    }
  };
}
class InfiniteQuery {
  #instance = injectQueryClient();
  #injector = inject(Injector);
  use(options) {
    return createBaseQuery({
      client: this.#instance,
      injector: options.injector ?? this.#injector,
      Observer: InfiniteQueryObserver,
      options: options
    });
  }
  static {
    this.ɵfac = function InfiniteQuery_Factory(t) {
      return new (t || InfiniteQuery)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InfiniteQuery,
      factory: InfiniteQuery.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InfiniteQuery, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function injectInfiniteQuery(options) {
  if (options?.injector) {
    return runInInjectionContext(options.injector, () => {
      const query = inject(InfiniteQuery);
      return query.use.bind(query);
    });
  }
  assertInInjectionContext(injectInfiniteQuery);
  const query = inject(InfiniteQuery);
  return query.use.bind(query);
}
class IsFetching {
  #queryClient = injectQueryClient();
  use(filters) {
    const result$ = new Observable(observer => {
      observer.next(this.#queryClient.isFetching(filters));
      const disposeSubscription = this.#queryClient.getQueryCache().subscribe(notifyManager.batchCalls(() => {
        observer.next(this.#queryClient.isFetching(filters));
      }));
      return () => disposeSubscription();
    }).pipe(distinctUntilChanged());
    return {
      result$,
      toSignal: () => toSignal(result$)
    };
  }
  static {
    this.ɵfac = function IsFetching_Factory(t) {
      return new (t || IsFetching)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IsFetching,
      factory: IsFetching.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsFetching, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseIsFetching = new InjectionToken('UseIsFetching', {
  providedIn: 'root',
  factory() {
    const isFetching = new IsFetching();
    return isFetching.use.bind(isFetching);
  }
});
function injectIsFetching() {
  assertInInjectionContext(injectIsFetching);
  return inject(UseIsFetching);
}
class IsMutating {
  #queryClient = injectQueryClient();
  use(filters) {
    const result$ = new Observable(observer => {
      observer.next(this.#queryClient.isMutating(filters));
      const disposeSubscription = this.#queryClient.getMutationCache().subscribe(notifyManager.batchCalls(() => {
        observer.next(this.#queryClient.isMutating(filters));
      }));
      return () => disposeSubscription();
    }).pipe(distinctUntilChanged());
    return {
      result$,
      toSignal: () => toSignal(result$)
    };
  }
  static {
    this.ɵfac = function IsMutating_Factory(t) {
      return new (t || IsMutating)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IsMutating,
      factory: IsMutating.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsMutating, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseIsMutating = new InjectionToken('UseIsFetching', {
  providedIn: 'root',
  factory() {
    const isMutating = new IsMutating();
    return isMutating.use.bind(isMutating);
  }
});
function injectIsMutating() {
  assertInInjectionContext(injectIsMutating);
  return inject(UseIsMutating);
}
class Mutation {
  #instance = injectQueryClient();
  use(options) {
    const mutationObserver = new MutationObserver(this.#instance, {
      ...options,
      mutationFn: variables => {
        const source = options.mutationFn(variables);
        if (isObservable(source)) return toPromise({
          source
        });
        return source;
      }
    });
    const result$ = new Observable(observer => {
      observer.next(mutationObserver.getCurrentResult());
      const disposeSubscription = mutationObserver.subscribe(notifyManager.batchCalls(result => {
        if (result.isError && shouldThrowError(mutationObserver.options.throwOnError, [result.error])) {
          observer.error(result.error);
        } else {
          observer.next(result);
        }
      }));
      return () => disposeSubscription();
    }).pipe(shareReplay({
      bufferSize: 1,
      refCount: true
    }));
    const mutate = (variables, options) => {
      mutationObserver.mutate(variables, options).catch(() => {
        // noop
      });
    };
    let cachedSignal;
    return {
      mutate,
      mutateAsync: mutationObserver.mutate.bind(mutationObserver),
      reset: mutationObserver.reset.bind(mutationObserver),
      setOptions: mutationObserver.setOptions.bind(mutationObserver),
      result$,
      // @experimental signal support
      get result() {
        if (!cachedSignal) {
          cachedSignal = toSignal(this.result$, {
            requireSync: true
          });
        }
        return cachedSignal;
      }
    };
  }
  static {
    this.ɵfac = function Mutation_Factory(t) {
      return new (t || Mutation)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Mutation,
      factory: Mutation.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Mutation, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseMutation = new InjectionToken('UseMutation', {
  providedIn: 'root',
  factory() {
    const mutation = new Mutation();
    return mutation.use.bind(mutation);
  }
});
function injectMutation() {
  return inject(UseMutation);
}
class Query {
  #instance = injectQueryClient();
  #injector = inject(Injector);
  use(options) {
    return createBaseQuery({
      client: this.#instance,
      injector: options.injector ?? this.#injector,
      Observer: QueryObserver,
      options
    });
  }
  static {
    this.ɵfac = function Query_Factory(t) {
      return new (t || Query)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Query,
      factory: Query.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Query, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
/**
 *
 * Optionally pass an injector that will be used than the current one.
 * Can be useful if you want to use it in ngOnInit hook for example.
 *
 * @example
 *
 * injector = inject(Injector);
 *
 * ngOnInit() {
 *  const todos = getTodos({ injector: this.injector }).result;
 * }
 *
 */
function injectQuery(options) {
  if (options?.injector) {
    return runInInjectionContext(options.injector, () => {
      const query = inject(Query);
      return query.use.bind(query);
    });
  }
  assertInInjectionContext(injectQuery);
  const query = inject(Query);
  return query.use.bind(query);
}
function mapResultData(mapFn) {
  return map(result => {
    return {
      ...result,
      data: result.isSuccess ? mapFn(result.data) : result.data
    };
  });
}
function filterSuccessResult() {
  return filter(result => result.isSuccess);
}
function filterErrorResult() {
  return filter(result => result.status === 'error');
}
function tapSuccessResult(cb) {
  return tap(result => {
    if (result.isSuccess) {
      cb(result.data);
    }
  });
}
function tapErrorResult(cb) {
  return tap(result => {
    if (result.isError) {
      cb(result.error);
    }
  });
}
/**
 * An operator that takes values emitted by the source observable
 * until the `isFetching` property on the result is false.
 * It is intended to be used in scenarios where an observable stream should be listened to
 * until the result has finished fetching (e.g success or error).
 */
function takeUntilResultFinalize() {
  return takeWhile(res => res.isFetching, true);
}
/**
 * An operator that takes values emitted by the source observable
 * until the `isSuccess` property on the result is true.
 * It is intended to be used in scenarios where an observable stream should be listened to
 * until a successful result is emitted.
 */
function takeUntilResultSuccess() {
  return takeWhile(res => !res.isSuccess, true);
}
/**
 * An operator that takes values emitted by the source observable
 * until the `isError` property on the result is true.
 * It is intended to be used in scenarios where an observable stream should be listened to
 * until an error result is emitted.
 */
function takeUntilResultError() {
  return takeWhile(res => !res.isError, true);
}
function startWithPendingQueryResult() {
  return startWith(createPendingObserverResult());
}
/**
 *
 *  This operator is used to merge multiple queries into one.
 *  It will return a new base query result that will merge the results of all the queries.
 *
 * @example
 *
 * const query = combineLatest({
 *   todos: todos.result$,
 *   posts: posts.result$,
 * }).pipe(
 *   intersectResults$(({ todos, posts }) => {
 *     return { ... }
 *   })
 * )
 * @example
 *
 * const query = combineLatest([todos.result$, posts.result$]).pipe(
 *   intersectResults$(([todos, posts]) => {
 *     return { ... }
 *   })
 * )
 */
function intersectResults$(mapFn) {
  return map(values => {
    const isArray = Array.isArray(values);
    const toArray = isArray ? values : Object.values(values);
    const refetch = () => Promise.all(toArray.map(v => v.refetch()));
    const mappedResult = {
      all: values,
      isSuccess: toArray.every(v => v.isSuccess),
      isPending: toArray.some(v => v.isPending),
      isLoading: toArray.some(v => v.isLoading),
      isError: toArray.some(v => v.isError),
      isFetching: toArray.some(v => v.isFetching),
      error: toArray.find(v => v.isError)?.error,
      data: undefined,
      refetch
    };
    if (mappedResult.isSuccess) {
      if (isArray) {
        mappedResult.data = mapFn(toArray.map(r => r.data));
      } else {
        const data = Object.entries(values).reduce((acc, [key, value]) => {
          acc[key] = value.data;
          return acc;
        }, {});
        mappedResult.data = mapFn(data);
      }
    }
    return mappedResult;
  });
}

/**
 *
 *  @experimental
 *
 *  This function is used to merge multiple signal queries into one.
 *  It will return a new base query result that will merge the results of all the queries.
 *  Note that it should be used inside injection context
 *
 * @example
 *
 * const query = intersetResults({
 *   todos: todos.result$,
 *   posts: posts.result$,
 * }, ({ todos, posts }) => {
 *   return todos + posts;
 * })
 *
 *
 * @example
 *
 * const query = intersectResults(
 *   [
 *     this.todosService.getTodo('1').result,
 *     this.todosService.getTodo('2').result,
 *   ],
 *  ([todoOne, todoTwo]) => {
 *    return todoOne.title + todoTwo.title;
 *  }
 * );
 */
function intersectResults(signals, mapFn) {
  const isArray = Array.isArray(signals);
  const toArray = isArray ? signals : Object.values(signals);
  const refetch = () => Promise.all(toArray.map(v => v().refetch()));
  return computed(() => {
    const mappedResult = {
      all: signals,
      isSuccess: toArray.every(v => v().isSuccess),
      isPending: toArray.some(v => v().isPending),
      isLoading: toArray.some(v => v().isLoading),
      isError: toArray.some(v => v().isError),
      isFetching: toArray.some(v => v().isFetching),
      error: toArray.find(v => v().isError)?.error,
      data: undefined,
      refetch
    };
    if (mappedResult.isSuccess) {
      if (isArray) {
        mappedResult.data = mapFn(toArray.map(r => r().data));
      } else {
        const data = Object.entries(signals).reduce((acc, [key, value]) => {
          acc[key] = value().data;
          return acc;
        }, {});
        mappedResult.data = mapFn(data);
      }
    }
    return mappedResult;
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { createPendingObserverResult, createSuccessObserverResult, filterErrorResult, filterSuccessResult, injectInfiniteQuery, injectIsFetching, injectIsMutating, injectMutation, injectQuery, injectQueryClient, intersectResults, intersectResults$, mapResultData, provideQueryClient, provideQueryClientOptions, queryOptions, startWithPendingQueryResult, takeUntilResultError, takeUntilResultFinalize, takeUntilResultSuccess, tapErrorResult, tapSuccessResult, toPromise };
