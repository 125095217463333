<div class="content-width" [class.content-width-padded]="showFilters$ | async" [formGroup]="dashboardFormService.filtersFormGroup">
    <kendo-expansionpanel #filterExpansionPanel class="hide-expander-header">
        <div class="dashboard" style="background-color: #F4F8FA;">
            <div class="row">
                @if (customersQueryResults$ | async; as result) {
                <div class="col-3" formGroupName="common" *ngIf="(result.data?.total ?? 0) > 0">
                    <kendo-label text="Customer">
                        <kendo-multiselect [data]="result?.data?.results ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="customers" textField="name" valueField="name"
                            [valuePrimitive]="false">
                        </kendo-multiselect>
                    </kendo-label>
                </div>
                }
                <div class="col-3" formGroupName="cycle" *ngIf="dashboardFormService.dashboardType === DashboardType.CycleTime">
                    @if (stationsWithoutSplcQueryResults$ | async; as result) {
                    <kendo-label text="Origin">
                        <kendo-combobox #originCombo [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="origin" textField="station" valueField="station"
                            (focus)="onFocusCombo(originCombo)">
                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                {{ formattingService.formatStation(dataItem.station, dataItem.stateProvince) }}
                            </ng-template>
                        </kendo-combobox>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="cycle" *ngIf="dashboardFormService.dashboardType === DashboardType.CycleTime">
                    @if (stationsWithoutSplcQueryResults$ | async; as result) {
                    <kendo-label text="Destination">
                        <kendo-combobox #destinationCombo [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="destination" textField="station" valueField="station"
                            (focus)="onFocusCombo(destinationCombo)">
                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                {{ formattingService.formatStation(dataItem.station, dataItem.stateProvince) }}
                            </ng-template>
                        </kendo-combobox>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common" *ngIf="dashboardFormService.dashboardType != DashboardType.CycleTime">
                    @if (countriesQueryResults$ | async; as result) {
                    <kendo-label text="Country">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="countries" textField="name" valueField="code"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common" *ngIf="dashboardFormService.dashboardType != DashboardType.CycleTime">
                    @if (regionsQueryResults$ | async; as result) {
                    <kendo-label text="Region">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="regions" textField="region" valueField="region"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common" *ngIf="dashboardFormService.dashboardType != DashboardType.CycleTime">
                    @if (statesProvincesQueryResults$ | async; as result) {
                    <kendo-label text="State/Province">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="statesProvinces" textField="name" valueField="name"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common" *ngIf="dashboardFormService.dashboardType != DashboardType.CycleTime">
                    @if (stationsWithoutSplcQueryResults$ | async; as result) {
                    <kendo-label text="Station">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="stations" textField="station" valueField="station">
                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                {{ formattingService.formatStation(dataItem.station, dataItem.stateProvince) }}
                            </ng-template>
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common" *ngIf="showCommodity">
                    @if (commoditiesQueryResults$ | async; as result) {
                    <kendo-label text="Commodity">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="commodities">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="railcar" *ngIf="dashboardFormService.dashboardType === DashboardType.RailcarVolume">
                    <kendo-label text="In Motion">
                        <kendo-multiselect [data]="inMotionValues" formControlName="motion"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3" *ngIf="dashboardFormService.dashboardType != DashboardType.CycleTime">
                    <kendo-label formGroupName="common" text="Loaded/Empty">
                        <kendo-multiselect [data]="loadedValues" formControlName="loaded" textField="name"
                            valueField="value" [valuePrimitive]="true"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Travel Status">
                        <kendo-multiselect [data]="travelStatusValues"
                            formControlName="travelStatus"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Minimum Railcar Count">
                        <kendo-numerictextbox formControlName="minimumRailcarCount" [step]="1" [decimals]="0" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="cycle" *ngIf="dashboardFormService.dashboardType === DashboardType.CycleTime">
                    <kendo-label text="Minimum Railcar Count">
                        <kendo-numerictextbox formControlName="minimumRailcarCount" [step]="1" [decimals]="0" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Minimum Dwell (days)">
                        <kendo-numerictextbox formControlName="minimumDwellDays" [step]="1" [decimals]="0" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="cycle" *ngIf="dashboardFormService.dashboardType === DashboardType.CycleTime">
                    <kendo-label text="Minimum Time (days)">
                        <kendo-numerictextbox formControlName="minimumTime" [step]="1" [decimals]="0" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
            </div>
            <br />
            <div class="action-group">
                <button class="btn btn-action-primary" (click)="applyFilters()"
                    [disabled]="this.dashboardFormService.filtersFormGroup.pristine">Apply</button>
                <button class="btn btn-action-secondary" (click)="clearFilters()">Clear</button>
            </div>
        </div>
    </kendo-expansionpanel>
</div>

<!-- <kendo-popup [anchor]="anchor!" *ngIf="showFilters$ | async">
    
</kendo-popup> -->