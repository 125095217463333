import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core';
import { SubSink } from 'subsink';
import { saveAs } from 'file-saver';
import { Report } from '../../dashboard/models/report/report';
import { ReportDataService } from '../../dashboard/submodules/filters/services/report-data.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastReportFormService implements OnDestroy {

  reportQueryResult$?: Observable<QueryObserverResult<Report[], Error>>;
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
    private reportDataService: ReportDataService) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  saveNewReport(filter: string, name: string, type: string,user:string) {
    return this.reportDataService.save({
      name: name,
      type: type,
      filters: filter,
     createdBy:user
    }).pipe(tap(() => {
    }));
  }

  saveEditingReport(filter: string, name: string, type: string, id: any, user: string) {
    return this.reportDataService.save({
      id: id,
      name: name,
      type: type,
      filters: filter,
      createdBy: user
    }).pipe(tap(() => {
    }));
  }

  toggleReportListEditMenu(reportToOpen: any) {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      for (let report of result.data as any[]) {
        if (report.id != reportToOpen.id) {
          report.show = false;
        }
      }
      reportToOpen.show = !reportToOpen.show;
    });
  }

  hideAllReportEditMenu() {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      for (let report of result.data as any[]) {
        report.show = false;
      }
    });
  }

  deleteReport(reportPendingDeleteId: any) {
    return this.reportDataService.delete(reportPendingDeleteId!).pipe(tap(() => {
    }));
  }

  getAllReportsForType(type: string) {
    return this.reportDataService.getAllForType(type);
  }
}
