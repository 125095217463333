import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { UserFormService } from '../services/user-form.service'
import { Customer } from '../../../models/customer';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss',
})
export class UserEditComponent implements OnDestroy {
  private sub = new SubSink();

  constructor(private userFormService: UserFormService) {
    
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get userForm() {
    return this.userFormService.userForm;
  }

  get registeredCustomers() {
    return this.userFormService.registeredCustomers;
  }

  saveUser() {
    this.userFormService.saveUser();
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
}
