<div class="dashboard-cards">
    <div class="dashboard">
      <div class="dashboard-title">
        Historical Performance
      </div>
      <div class="dashboard-divider"></div>
      <div class="dashboard-subtitle">
        Historical trip time performance metrics.
      </div>
      <app-date-filter [startYear]="2021" [formGroup]="filterForm" yearsFormControlName="years"
        monthsFormControlName="months"></app-date-filter>
      <div class="performance-cards">
        <div class="performance-card">
          <div class="performance-title">
            <svg-icon name="timer" viewBox="0 0 24 24" svgClass="timer-icon"></svg-icon>
            Average Trip Time
          </div>
          @if (averageTripTimeQueryResult$ | async; as result) {
          <div class="performance-metric">
            <div class="performance-value">
              <app-ellipsis-loader *ngIf="result.isLoading"></app-ellipsis-loader>
              <div *ngIf="!result.isLoading">{{result.data?.current | number :'1.2-2'}}</div>
            </div>
            <div class="performance-uom">days</div>
          </div>
          <div class="performance-compare">
            <div *ngIf="!result.isLoading" [innerHTML]="getChangePercentText(result.data?.current ?? 0, result.data?.previous ?? 0)"></div>
          </div>
          }
        </div>
        <div class="performance-card">
          <div class="performance-title">
            <svg-icon name="trip-circle" viewBox="0 0 24 24" svgClass="trip-circle-icon"></svg-icon>
            Trip Count
          </div>
          @if (tripCountQueryResult$ | async; as result) {
          <div class="performance-metric">
            <div class="performance-value">
              <app-ellipsis-loader *ngIf="result.isLoading"></app-ellipsis-loader>
              <div *ngIf="!result.isLoading">{{result.data?.current | number}}</div>
            </div>
            <div class="performance-uom">trips</div>
          </div>
          <div class="performance-compare">
            <div *ngIf="!result.isLoading" [innerHTML]="getChangePercentText(result.data?.current ?? 0, result.data?.previous ?? 0)"></div>
          </div>
          }
        </div>
        <!-- <div class="performance-card">
          <div class="performance-title">
            <svg-icon name="average-velocity" viewBox="0 0 24 24" svgClass="average-velocity-icon"></svg-icon>
            Average Velocity
          </div>
          <div class="performance-metric">
            <div class="performance-value muted">
              Coming Soon
              <app-ellipsis-loader *ngIf="isAverageVelocityLoading$ | async"></app-ellipsis-loader>
              <div *ngIf="!(isAverageVelocityLoading$ | async)">{{averageVelocity}}</div>
            </div>
            <div class="performance-uom">km/hr</div>
          </div>
          <div class="performance-compare">
            <div *ngIf="!(isAverageVelocityLoading$ | async)" [innerHTML]="averageVelocityChange"></div>
          </div>
        </div> -->
      </div>
      <div class="area-container mt-3">
        <div class="dashboard-title">
          Average Trip Time
        </div>
        <div class="muted">
          Historical trip times for origin/destination pairs.
        </div>
        <div class="row">
          <app-average-trip-time-map  [class.col-12]="tripTimeFormService.isMapExpanded$ | async" [class.col-6]="!(tripTimeFormService.isMapExpanded$ | async)"></app-average-trip-time-map>
          <app-average-trip-time-table  [class.col-12]="tripTimeFormService.isMapExpanded$ | async" [class.col-6]="!(tripTimeFormService.isMapExpanded$ | async)"></app-average-trip-time-table>
        </div>
      </div>
      <div class="area-container mt-3">
        <div class="d-flex justify-content-end period-chips-container">
          <kendo-chiplist selection="single" (selectedChange)="tripMetricChanged($event)">
              <kendo-chip *ngFor="let tripMetric of tripMetrics; index as i" [selected]="i == 0" rounded="full" fillMode="outline" [label]="tripMetric"></kendo-chip>
          </kendo-chiplist>
        </div>
        <div class="dashboard-title">
          Trip Time, Velocity and Railcar Counts
        </div>
        <div class="muted">
          Historical performance metrics for origin/destination pairs.
        </div>
        <div class="row">
          <app-average-historical-trip-time-table-charts class="col-12"></app-average-historical-trip-time-table-charts>
        </div>
      </div>
    </div>
  </div>