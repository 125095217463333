import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import ColorStop from "@arcgis/core/renderers/visualVariables/support/ColorStop";
import _ from 'lodash';

@Component({
  selector: 'app-map-color-gradient-legend',
  templateUrl: './map-color-gradient-legend.component.html',
  styleUrl: './map-color-gradient-legend.component.css',
})
export class MapColorGradientLegendComponent {
  @Input() set colorVariableStops(colorVariableStops: ColorStop[] | null) {
    if (colorVariableStops?.length) {
      this._colorVariableStops = colorVariableStops;

      this.highestStopValue = this._colorVariableStops!.sort((a, b) => b.value - a.value)[0].value;
      this.gradientCSS = this.generateCSSFromColorVariables(this._colorVariableStops!);
    }
  }; 

  private _colorVariableStops: ColorStop[] = [];

  @Input() label?: string;

  gradientCSS: string = '';
  highestStopValue: number = 0;

  generateCSSFromColorVariables(colorVariables: ColorStop[]) {
    let sortedColorVariableStops: ColorStop[] = colorVariables.sort((a, b) => a.value - b.value);

    let baseColorRgb = sortedColorVariableStops[0].color.toCss();
    let gradientCSS = `background: ${baseColorRgb};`;
    let maxValue = _.maxBy(sortedColorVariableStops, x => x.value)?.value ?? 0;
    gradientCSS += `background: linear-gradient(90deg,`;

    for (let colorVariableStop of sortedColorVariableStops) {
      let currentColorVariableStopRgb = colorVariableStop.color.toCss();
      let percentOfColor = (colorVariableStop.value / maxValue) * 100;
      gradientCSS += `${currentColorVariableStopRgb} ${percentOfColor}%`;

      if (sortedColorVariableStops.indexOf(colorVariableStop) < sortedColorVariableStops.length - 1) {
        gradientCSS += `,`;
      }
    }

    gradientCSS += `);`;

    return gradientCSS;
  }
}


