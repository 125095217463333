import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, startWith } from 'rxjs';
import { DashboardCycleTimeFormService } from './cycle/services/dashboard-cycle-time-form.service';
import { CycleMetrics } from '../../../constants/cycle-metric';
import { DashboardReportFormService } from '../../layout/services/dashboard-report-form.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dashboard-cycle-time',
  templateUrl: './dashboard-cycle-time.component.html',
  styleUrl: './dashboard-cycle-time.component.scss'
})
export class DashboardCycleTimeComponent {
  
}
