
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { UserDataService } from '../../../services/user-data.service';
import { UserDto } from '../../../dto/user-dto';
import { CustomerDataService } from '../../../services/customer-data.service';
import { Customer } from '../../../models/customer';

@Injectable({
  providedIn: 'root'
})
export class UserFormService {

  private sub = new SubSink();

  public isCreating: boolean = false;
  public isUpdating: boolean = false;

  registeredCustomers: Customer[] = [];

  userForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, private userDataService: UserDataService, private customerDataService: CustomerDataService) {
    this.setupForm();

    this.sub.sink = this.registeredCustomers$.subscribe(customers => {
      this.registeredCustomers = customers.data?.results ?? [];
    });
  }

  get registeredCustomers$() {
    return this.customerDataService.getAll('filter=isRegistered~eq~true').result$;
  }

  private setupForm() {
    this.userForm = this.formBuilder.group({
      email: '',
      firstName: '',
      lastName: ''
    });
  }

  saveUser() {
    if (this.userForm.valid) {
      let user = this.userForm.getRawValue() as UserDto;

      if (this.isCreating) {
        this.userDataService.create(user).subscribe(() => {
          this.isUpdating = false;
          this.isCreating = false;
          this.userForm.reset();
        });
      }
      else if (this.isUpdating) {
        this.userDataService.update(user).subscribe(() => {
          this.isUpdating = false;
          this.isCreating = false;
          this.userForm.reset();
        });
      }
    }
  }
}
