import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core';
import { addMonths, startOfMonth } from 'date-fns';
import { formatDate } from '@angular/common';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { ChartSeries } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { ChartSeriesData } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { xorWith } from 'lodash';
import { KpiDataService } from '../../../services/kpi-data.service';
import { SubSink } from 'subsink';
import { VolumeDemandDto } from '../../../../../models/volume-demand-report';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-forecast-volume-demand-chart',
  templateUrl: './forecast-volume-demand-chart.component.html',
  styleUrl: './forecast-volume-demand-chart.component.css',
})
export class ForecastVolumeDemandChartComponent implements OnInit {

  private sub = new SubSink();

  isVolumeDemandReportByMonthLoading$ = new BehaviorSubject<boolean>(true);
  annotations: any = {}; // Initializes 'annotations' as an empty object
  dataSource2: any;
  dataSource3: any;
  dataSource4: any;
  donutWidth = 200;
  donutHeight = 280;

  dataSource: any = {
    chart: {
      theme: "fusion",
      numberSuffix: "%",
      showlegend: '0',
      showLabels: "0",
      valuePosition: "inside",
      valueBgColor: "#000000", // Background color for values (effectively works in tooltip)
      valueBgAlpha: "70", // Opacity for the background color
      // Center label options
      centerLabelFontSize: "14",
      centerLabelFontColor: "black",
      centerLabelBold: "1",
      pieRadius: "100",
      doughnutRadius: "50",
      showpercentvalues: "1",
      decimals: "1",
      // label value styles
      valueFontColor: "#474747", // Change value font color
      patternBgColor: "#F44444",
      labelBorderRadius: 15,
      labelBgColor: "#FAFAFA",
      labelFontSize: 15,
    },
    data: [
      { label: "Fulfilled", value: "0", color: "#1B365D" },
      { label: "Unfulfilled", value: "0", color: "#F24444" },
    ],
  };

  constructor(private kpiDataService: KpiDataService) {

  }

  ngOnInit(): void {
    this.sub.sink = this.kpiDataService.getVolumeDemandReport().subscribe((dwellKpi: VolumeDemandDto) => {
      this.loadVolumeDemandReport(dwellKpi);
      this.isVolumeDemandReportByMonthLoading$.next(false);
    });
  }

  loadVolumeDemandReport(dataset: VolumeDemandDto | null) {
    if (dataset == null) return;
    this.dataSource2 = this.setMonthlyVolumeDemandReport(dataset);
    this.dataSource3 = this.setcurrentYearReport(dataset);
    this.dataSource4 = this.setpreviousYearReport(dataset);
  }

  setMonthlyVolumeDemandReport(dataset: VolumeDemandDto) {
    var monthlyData = cloneDeep(this.dataSource);   
    monthlyData.data[0].value = dataset.monthReport.fulFilled * 100;
    monthlyData.data[1].value = dataset.monthReport.unFulFilled * 100;
    monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText;
    monthlyData.chart.centerLabel = dataset.monthReport.labelText;
    if (monthlyData.data[0].value == 0 && monthlyData.data[1].value == 0) {
      monthlyData.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      monthlyData.chart.showpercentvalues = "0";
      monthlyData.chart.valuePosition = "none";
      monthlyData.chart.showToolTip = "0";
      monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText + " No Data";
      monthlyData.chart.centerLabel = dataset.monthReport.labelText + " No Data";
    }
    return monthlyData;
  }
  setcurrentYearReport(dataset: VolumeDemandDto) {
    var currentYearReport = cloneDeep(this.dataSource);
    currentYearReport.data[0].value = dataset.currentYearReport.fulFilled * 100;
    currentYearReport.data[1].value = dataset.currentYearReport.unFulFilled * 100;
    currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText;
    currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText;
    if (currentYearReport.data[0].value == 0 && currentYearReport.data[1].value == 0) {
      currentYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      currentYearReport.chart.showpercentvalues = "0";
      currentYearReport.chart.valuePosition = "none";
      currentYearReport.chart.showToolTip = "0";
      currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText + " No Data";
      currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText + " No Data";
    }
    return currentYearReport;
  }
  setpreviousYearReport(dataset: VolumeDemandDto) {
    var previousYearReport = cloneDeep(this.dataSource);
    previousYearReport.data[0].value = dataset.previousYearReport.fulFilled * 100;
    previousYearReport.data[1].value = dataset.previousYearReport.unFulFilled * 100;
    previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText;
    previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText;
    if (previousYearReport.data[0].value == 0 && previousYearReport.data[1].value == 0) {
      previousYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      previousYearReport.chart.showpercentvalues = "0";
      previousYearReport.chart.valuePosition = "none";
      previousYearReport.chart.showToolTip = "0";
      previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText + " No Data";
      previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText + " No Data";
    }
    return previousYearReport;
  }
}
