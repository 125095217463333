import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { NotificationRef } from '@progress/kendo-angular-notification';
import { QueryObserverResult } from '@tanstack/query-core';
import { SubSink } from 'subsink';
import { saveAs } from 'file-saver';
import { ReportDataService } from '../../filters/services/report-data.service';
import { DashboardType } from '../../../constants/dashboard-type';
import { DashboardFilters } from '../../../models/filters/dashboard-filters';
import { NetworkDwellFilters } from '../../../models/filters/network-dwell-filters';
import { RailcarFilters } from '../../../models/filters/railcar-filters';
import { Report } from '../../../models/report/report';
import { CycleFilters } from '../../../models/filters/cycle-filters';
import { Station } from '../../../../shared-rail-performance/models/location/station';
import { CustomerFilter } from '../../../../admin/models/customer-filter';

@Injectable({
  providedIn: 'root'
})
export class DashboardReportFormService implements OnDestroy {
  form: FormGroup;

  reportQueryResult$?: Observable<QueryObserverResult<Report[], Error>>;

  editNotificationRef?: NotificationRef;
  exportDataNotificationRef?: NotificationRef;

  isSaveEnabled$ = new BehaviorSubject<boolean>(false);
  showFilters$ = new BehaviorSubject<boolean>(false);

  filtersApplied$ = new Subject<void>();
  exportData$ = new Subject<void>();
  exportDataComplete$ = new Subject<void>();

  showExportDataLoading$ = new BehaviorSubject<boolean>(false);

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private reportDataService: ReportDataService) {
    this.form = this.formBuilder.group({
      name: new FormControl<string>('', {
        validators: [
          Validators.required
        ]
      }),
      selectedReport: new FormControl<Report | undefined>(undefined),
      pendingDeleteReport: new FormControl<Report | undefined>(undefined),
      pendingEditReport: new FormControl<Report | undefined>(undefined),
      dashboardType: new FormControl<string>(''),
      filters: this.formBuilder.group({
        common: this.formBuilder.group({
          customers: new FormControl<CustomerFilter[]>([]),
          countries: new FormControl<string[]>([]),
          regions: new FormControl<string[]>([]),
          statesProvinces: new FormControl<string[]>([]),
          stations: new FormControl<Station[]>([]),
          commodities: new FormControl<string[]>([]),
          loaded: new FormControl<string[]>([]),
        }),
        railcar: this.formBuilder.group({
          motion: new FormControl<string[]>([]),
        }),
        networkDwell: this.formBuilder.group({
          travelStatus: new FormControl<string[]>([]),
          minimumRailcarCount: new FormControl<number | undefined>(undefined),
          minimumDwellDays: new FormControl<number | undefined>(undefined),
        }),
        cycle: this.formBuilder.group({
          origin: new FormControl<Station | undefined>(undefined),
          destination: new FormControl<Station | undefined>(undefined),
          minimumRailcarCount: new FormControl<number | undefined>(undefined),
          minimumTime: new FormControl<number | undefined>(undefined)
        }),
      })
    });

    this.sub.sink = this.filtersFormGroup.valueChanges.subscribe(() => {
      let filters = this.getFiltersByDashboard<any>(this.dashboardType);

      if (this.selectedReport?.filters === JSON.stringify(filters)) {
        this.isSaveEnabled$.next(false);
      }
      else {
        this.selectedReportFormControl.reset();
        this.isSaveEnabled$.next(this.filtersFormGroup.dirty);
      }
    });

    this.sub.sink = this.selectedReportFormControl.valueChanges.subscribe(() => {
      if (!!this.selectedReport) {
        let selectedFilters: DashboardFilters = JSON.parse(this.selectedReport.filters);
        this.setFilterFormGroups(selectedFilters);
        this.filtersApplied$.next();
      }
    });

    this.sub.sink = this.dashboardTypeFormControl.valueChanges.subscribe(() => {
      this.reportQueryResult$ = this.reportDataService.getAllForType(this.dashboardType).result$;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get nameFormControl(): FormControl<string> {
    return this.form.get('name') as FormControl<string>;
  }

  get selectedReportFormControl(): FormControl<Report | undefined> {
    return this.form.get('selectedReport') as FormControl<Report | undefined>;
  }

  get selectedReport(): Report | undefined {
    return this.selectedReportFormControl.value;
  }

  get pendingDeleteReportFormControl(): FormControl<Report | undefined> {
    return this.form.get('pendingDeleteReport') as FormControl<Report | undefined>;
  }

  get pendingDeleteReport(): Report | undefined {
    return this.pendingDeleteReportFormControl.value;
  }

  get pendingEditReportFormControl(): FormControl<Report | undefined> {
    return this.form.get('pendingEditReport') as FormControl<Report | undefined>;
  }

  get pendingEditReport(): Report | undefined {
    return this.pendingEditReportFormControl.value;
  }

  get dashboardTypeFormControl(): FormControl<string> {
    return this.form.get('dashboardType') as FormControl<string>;
  }

  get dashboardType(): string {
    return this.dashboardTypeFormControl.value;
  }

  get filtersFormGroup(): FormGroup {
    return this.form.get('filters') as FormGroup;
  }

  get commonFiltersFormGroup(): FormGroup {
    return this.filtersFormGroup.get('common') as FormGroup;
  }

  get commonFilters(): DashboardFilters {
    return this.commonFiltersFormGroup.getRawValue();
  }

  get railcarFiltersFormGroup(): FormGroup {
    return this.filtersFormGroup.get('railcar') as FormGroup;
  }

  get railcarFilters(): RailcarFilters {
    return {
      ...this.commonFilters,
      ...this.railcarFiltersFormGroup.value
    };
  }

  get networkDwellFiltersFormGroup(): FormGroup {
    return this.filtersFormGroup.get('networkDwell') as FormGroup;
  }

  get networkDwellFilters(): NetworkDwellFilters {
    return {
      ...this.commonFilters,
      ...this.networkDwellFiltersFormGroup.value
    };
  }

  get cycleFiltersFormGroup(): FormGroup {
    return this.filtersFormGroup.get('cycle') as FormGroup;
  }

  get cycleFilters(): CycleFilters {
    return {
      ...this.commonFilters,
      ...this.cycleFiltersFormGroup.value
    };
  }

  getFiltersByDashboard<T>(dashboardType: string) {
    switch (dashboardType) {
      case DashboardType.RailcarVolume:
        return this.railcarFilters as T;
      case DashboardType.NetworkDwell:
        return this.networkDwellFilters as T;
      default:
        return this.cycleFilters as T;
    }
  }

  applyFilters() {
    this.filtersApplied$.next();
  }

  reset() {
    this.pendingDeleteReportFormControl.reset();
    this.pendingEditReportFormControl.reset();
    this.selectedReportFormControl.reset();
  }

  setDashboardType(dashboardType: string) {
    this.reset();
    this.dashboardTypeFormControl.setValue(dashboardType);
  }

  toggleFiltersVisible() {
    this.showFilters$.next(!this.showFilters$.value);
  }

  saveNewReport() {
    return this.reportDataService.save({
      name: this.nameFormControl.value,
      type: this.dashboardType,
      filters: JSON.stringify(this.getFiltersByDashboard(this.dashboardType))
    }).pipe(tap(() => {
      this.nameFormControl.reset();
    }));
  }

  saveEditingReport() {
    return this.reportDataService.save({
      id: this.pendingEditReport!.id,
      name: this.pendingEditReport!.name,
      type: this.pendingEditReport!.type,
      filters: JSON.stringify(this.getFiltersByDashboard(this.dashboardType))
    }).pipe(tap(() => {
      this.pendingEditReportFormControl.reset();
    }));
  }

  setPendingReportDelete(report: Report) {
    this.pendingDeleteReportFormControl.setValue(report);
    this.hideAllReportEditMenu();
  }

  setPendingReportEdit(report: Report) {
    let filters: DashboardFilters = JSON.parse(report.filters);
    this.setFilterFormGroups(filters);

    this.showFilters$.next(true);

    this.pendingEditReportFormControl.setValue(report);

    this.hideAllReportEditMenu();
  }

  setFilterFormGroups(filters: DashboardFilters) {
    this.commonFiltersFormGroup.patchValue(filters);
    this.railcarFiltersFormGroup.patchValue(filters);
    this.networkDwellFiltersFormGroup.patchValue(filters);
    this.cycleFiltersFormGroup.patchValue(filters);
  }

  cancelPendingReportDelete() {
    this.pendingDeleteReportFormControl.reset();
  }

  cancelPendingReportEdit() {
    this.pendingEditReportFormControl.reset();
  }

  toggleReportListEditMenu(reportToOpen: any) {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      for (let report of result.data as any[]) {
        if (report.id != reportToOpen.id) {
          report.show = false;
        }
      }

      reportToOpen.show = !reportToOpen.show;
    });
  }

  hideAllReportEditMenu() {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      for (let report of result.data as any[]) {
        report.show = false;
      }
    });
  }

  deleteReport() {
    let reportPendingDeleteId = this.pendingDeleteReport?.id;

    return this.reportDataService.delete(reportPendingDeleteId!).pipe(tap(() => {
      this.pendingDeleteReportFormControl.reset();
    }));
  }

  getAllReportsForType(type: string) {
    return this.reportDataService.getAllForType(type);
  }

  exportData() {
    this.exportData$.next();
  }
}
