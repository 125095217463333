import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { DashboardTripTimeFormService } from '../../trip/services/dashboard-trip-time-form.service';

@Component({
  selector: 'app-average-trip-time-table',
  templateUrl: './average-trip-time-table.component.html',
  styleUrl: './average-trip-time-table.component.css',
})
export class AverageTripTimeTableComponent {
  constructor(public formService: DashboardTripTimeFormService,
              public formattingService: FormattingService
  ) {
    
  }
}
