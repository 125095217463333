import { Injectable } from "@angular/core";
import { DataService } from "../../shared-rail-performance/services/data/data.service";
import { CustomerDto } from "../dto/customer-dto";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../models/customer";
import { PagedModel } from "../../shared-rail-performance/services/data/paged-model";
import { tap } from "rxjs";
import { NotificationService } from "@progress/kendo-angular-notification";

@Injectable({
    providedIn: 'root'
})
export class CustomerDataService extends DataService<Customer, CustomerDto> {
    constructor(httpClient: HttpClient, notificationService: NotificationService) {
        super(httpClient, notificationService, {
            resourceEndpoint: 'Customers'
        });
    }
}