import { QueryCache } from "@tanstack/query-core";

export class DataQueryServiceConfiguration<T> {
    key: string;
    url: string;
    staleTime: number;

    constructor(key: string, url: string, staleTime: number = Infinity) {
        this.key = key;
        this.url = url;
        this.staleTime = staleTime;
    }
}