import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-schedule',
  // standalone: true,
  //imports: [],
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.scss'
})
export class ScheduleComponent {

  viewMode: string = "tiles";

  onEmitEventViewMode(event: string) {
    this.viewMode = event;
  }
}
