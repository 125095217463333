import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { Result } from '@ngneat/query/lib/types';
import { QueryObserverResult } from '@tanstack/query-core';
import { addMonths, startOfMonth } from 'date-fns';
import { formatDate } from '@angular/common';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { ChartSeries } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { ChartSeriesData } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { RailcarFilters } from '../../../../../models/filters/railcar-filters';
import { RailcarVolumeHistory } from '../../../../../models/railcar/railcar-volume-history';
import { RailcarDataService } from '../../../../railcar/services/railcar-data.service';

@Component({
  selector: 'app-railcar-volume-history-graph',
  templateUrl: './railcar-volume-history-graph.component.html',
  styleUrl: './railcar-volume-history-graph.component.css',
})
export class RailcarVolumeHistoryGraphComponent implements OnInit {  
  public queryResult$?: Observable<QueryObserverResult<DataSource, Error>>;

  constructor(private railcarDataService: RailcarDataService) {
    
  }

  ngOnInit(): void {
    let filters = new RailcarFilters();
    filters.startDate = this.getStartDate();
    
    this.queryResult$ = this.railcarDataService.getRailcarVolumeHistoryWithAverageChartData(filters, this.populateChartWithData).result$;
  }

  getStartDate(): Date {
    return addMonths(startOfMonth(new Date()), -36);
  }

  populateChartWithData(railcarVolumeHistoryRecords$: Observable<RailcarVolumeHistory[]>) {
    return railcarVolumeHistoryRecords$.pipe(map((records) => {
      let categories: string[] = [];
      let railcars: ChartSeriesData[] = [];
      let historical: ChartSeriesData[] = [];
  
      records.forEach((railcarVolumeHistory) => {
        // x axis
        categories.push(formatDate(`${railcarVolumeHistory.year}-${railcarVolumeHistory.month}-01`, 'MMM yyyy', 'en-US'));
  
        // y axis column
        railcars.push({ value: (railcarVolumeHistory.volume).toString() });
        // y axis another option line chart
        historical.push({ value: (railcarVolumeHistory.monthlyAverage).toString() });
      });
  
      let chartSeries: ChartSeries[] = [{
        "seriesname": "Railcar Count",
        "renderAs": "column",
        "data": railcars,
      },
      {
        "seriesname": "Historical Average",
        "renderAs": "line",
        "data": historical
      }];

      let dataSource: DataSource = {
        "chart": {
          "divlineColor": "#999999",
          "divLineIsDashed": "1",
          "divLineDashLen": "1",
          "divLineGapLen": "1",
          "toolTipColor": "#ffffff",
          "toolTipBorderThickness": "0",
          "toolTipBgColor": "#000000",
          "toolTipBgAlpha": "80",
          "toolTipBorderRadius": "2",
          "toolTipPadding": "5",
          "theme": "fusion"
        },
        categories: [Categories.getCategoriesFromStringList(categories)],
        dataset: chartSeries
      };
    
      console.log('query result rail car', dataSource);
      return dataSource;
    }));
  }
}
