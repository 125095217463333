import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, from, mergeMap, startWith, tap } from 'rxjs';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { AverageCycleTimeMapService } from '../services/average-cycle-time-map.service';
import { DashboardCycleTimeFormService } from '../services/dashboard-cycle-time-form.service';

@Component({
  selector: 'app-average-cycle-time-map',
  templateUrl: './average-cycle-time-map.component.html',
  styleUrl: './average-cycle-time-map.component.css'
})
export class AverageCycleTimeMapComponent implements OnInit, OnDestroy {
  @ViewChild('mapViewNode', { static: true }) private mapViewEl!: ElementRef;

  public filterForm: FormGroup = this.formService.filterForm;

  private sub = new SubSink();

  constructor(public formService: DashboardCycleTimeFormService,
              private cycleTimeMapService: AverageCycleTimeMapService,
              private dashboardFormService: DashboardReportFormService,
              private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.sub.sink = combineLatest([
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.formService.yearsFormControl.valueChanges.pipe(startWith([])),
      this.formService.monthsFormControl.valueChanges.pipe(startWith([]))
    ]).subscribe(() => {
      this.formService.loadCycleMap(this.mapViewEl, this.expandButtonClick);
    });

    this.formService.loadCycleMap(this.mapViewEl, this.expandButtonClick);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get cyclesQueryResult$() {
    return this.formService.cyclesQueryResult$;
  }

  get colorVariableStops$() {
    return this.cycleTimeMapService.colorVariableStops$;
  }

  private expandButtonClick = () => {
    //Map is run outside of Angular, need to run this inside Angular
    this.ngZone.run(() => {
      this.formService.isMapExpanded$.next(!this.formService.isMapExpanded$.value);
    });
  }
}
