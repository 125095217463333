import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";  
import { FusionChartsModule } from "angular-fusioncharts";
import { SharedRailPerformanceModule } from "../../../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../../../common/common-imports";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSvgIconModule } from "angular-svg-icon";
import { DashboardDwellByLocationChartComponent } from "./components/dashboard-dwell-by-location-chart/dashboard-dwell-by-location-chart.component";
import { DashboardDwellByLocationMapComponent } from "./components/dashboard-dwell-by-location-map/dashboard-dwell-by-location-map.component";
import { DashboardDwellByLocationTableComponent } from "./components/dashboard-dwell-by-location-table/dashboard-dwell-by-location-table.component";
import { DashboardDwellTotalChartComponent } from "./components/dashboard-dwell-total-chart/dashboard-dwell-total-chart.component";
import { DashboardNetworkDwellComponent } from "./components/dashboard-network-dwell.component";
import { NetworkDwellDashboardRoutingModule } from "./network-dwell-dashboard-routing.module";

@NgModule({
    imports: [
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        NetworkDwellDashboardRoutingModule
    ],
    exports: [
    ],
    declarations: [
        DashboardNetworkDwellComponent,
        DashboardNetworkDwellComponent,
        DashboardDwellByLocationMapComponent,
        DashboardDwellByLocationChartComponent,
        DashboardDwellByLocationTableComponent,
        DashboardDwellTotalChartComponent
    ],
    schemas: [],
})
export class NetworkDwellDashboardModule { 
  constructor() {
  }
}
