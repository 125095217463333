import { SharedRailPerformanceModule } from "../../../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../../../common/common-imports";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FusionChartsModule } from "angular-fusioncharts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgModule } from "@angular/core";
import { DashboardRailcarComponent } from "./components/dashboard-railcar.component";
import { DashboardRailcarGraphComponent } from "./components/dashboard-railcar-graph/dashboard-railcar-graph.component";
import { DashboardRailcarTableComponent } from "./components/dashboard-railcar-table/dashboard-railcar-table.component";
import { RailcarDashboardRoutingModule } from "./railcar-dashboard-routing.module";

@NgModule({
    imports: [
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        RailcarDashboardRoutingModule
    ],
    exports: [
    ],
    declarations: [
        DashboardRailcarComponent,
        DashboardRailcarGraphComponent,
        DashboardRailcarTableComponent,
    ],
    schemas: [],
})
export class RailcarDashboardModule { 

}
