<div class="d-flex flex-column h-100 position-relative" [formGroup]="filterForm">
    <div class="row">
        <div class="col-12">
            <div class="map-container d-flex flex-column">
                <div class="map" #mapViewNode [class.invisible]="formService.isMapLoading$ | async"></div>
                <div *ngIf="formService.isMapLoading$ | async"
                    class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <div class="spinner-border" role="status">
                    </div>
                </div>
                <app-map-color-gradient-legend [class.invisible]="formService.isMapLoading$ | async" label="Trip Time (Days)"
                    [colorVariableStops]="colorVariableStops$ | async"></app-map-color-gradient-legend>
            </div>
        </div>
    </div>
</div>