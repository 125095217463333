
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerDto } from '../../../dto/customer-dto';
import { CustomerDataService } from '../../../services/customer-data.service';
import { forkJoin, map, shareReplay } from 'rxjs';
import { SubSink } from 'subsink';
import { Customer } from '../../../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerFormService {

  private sub = new SubSink();

  public isCreating: boolean = false;
  public isUpdating: boolean = false;

  unregisteredCustomers: Customer[] = [];
  registeredCustomers: Customer[] = [];
  allCustomers: Customer[] = [];
  associatedCustomers: Customer[] = [];

  customerForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, private customerDataService: CustomerDataService) {
    this.setupForm();

    this.unregisteredCustomers$.subscribe(customers => {
      this.unregisteredCustomers = customers.data?.results ?? [];
      this.allCustomers = [...this.registeredCustomers, ...this.unregisteredCustomers].sort((a, b) => a.name.localeCompare(b.name));
    });

    this.registeredCustomers$.subscribe(customers => {
      this.registeredCustomers = customers.data?.results ?? [];
      this.allCustomers = [...this.registeredCustomers, ...this.unregisteredCustomers].sort((a, b) => a.name.localeCompare(b.name));
    });

    this.sub.sink = this.customerIdFormControl?.valueChanges.subscribe(() => {
      this.setAssociatedCustomers();
    });
  }

  get registeredCustomers$() {
    return this.customerDataService.getAll('filter=isRegistered~eq~true').result$;
  }

  get unregisteredCustomers$() {
    return this.customerDataService.getAll('filter=isRegistered~eq~false').result$;
  }

  get customerIdFormControl() {
    return this.customerForm.get('customerId');
  }

  setAssociatedCustomers() {
    if (!!this.customerForm.get('customerId')?.value) {
      this.associatedCustomers = this.allCustomers.filter(x => x.customerId !== this.customerIdFormControl?.value);
    }
    else {
      this.associatedCustomers = this.allCustomers;
    }
  }

  private setupForm() {
    this.customerForm = this.formBuilder.group({
      customerId: new FormControl<string>({ value: '', disabled: true }, { validators: Validators.required }),
      name: new FormControl<string>('', { validators: Validators.required }),
      address: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      phoneNumber: '',
      scac: '',
      railforceGlobalAccountId: '',
      railincFleet: '',
      primaryContactName: '',
      primaryContactEmail: '',
      primaryContactPhone: '',
      isActive: false,
      hasYardManagementAccess: false,
      hasShipmentManagementAccess: false,
      hasDataverseAccess: false,
      hasCustomerApiAccess: false,
      hasWaybillManagementAccess: false,
      hasRailPerformanceAccess: false,
      hasForecastDemandAccess: false,
      hasActivityBasedCostingAccess: false,
      childCustomerIds: [],
      isRegistered: false,
    });

    this.sub.sink = this.customerForm.get('name')?.valueChanges.subscribe((name: string) => {
      let customer = this.unregisteredCustomers.find(x => x.name == name);

      if (customer) {
        this.customerForm.get('customerId')?.setValue(customer.customerId);
      }
      else {
        this.customerForm.get('customerId')?.reset();
      }
    });
  }

  saveCustomer() {
    if (this.customerForm.valid) {
      let customer = this.customerForm.getRawValue() as CustomerDto;

      if (this.isCreating) {
        this.customerDataService.create(customer).subscribe(() => {
          this.isUpdating = false;
          this.isCreating = false;
          this.customerForm.reset();
        });
      }
      else if (this.isUpdating) {
        this.customerDataService.update(customer).subscribe(() => {
          this.isUpdating = false;
          this.isCreating = false;
          this.customerForm.reset();
        });
      }
    }
  }
}
