<div class="dashboard">
  <div class="dashboard-title">
    Active Performance
  </div>
  <div class="dashboard-divider"></div>
  <div class="dashboard-subtitle">
    Current Railcar Count Metrics Based On Latest Railcar Messages
  </div>
  <div class="performance-cards">
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="location-marker" svgClass="location-marker-icon" viewBox="0 0 15 20"></svg-icon>
        Dwell
      </div>
      @if (dwellSummaryQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric label="Network Avg" [value]="result.data?.dwellAverage" [decimalsToDisplay]="2"
            unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="Origin" [value]="result.data?.dwellOrigin" [decimalsToDisplay]="2"
            unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="In Transit" [value]="result.data?.dwellMotion" [decimalsToDisplay]="2"
            unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="Destination" [value]="result.data?.dwellDestination" [decimalsToDisplay]="2"
            unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="location-marker" svgClass="location-marker-icon" viewBox="0 0 15 20"></svg-icon>
        Dwell by Location
      </div>
      @if (dwellLocationQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric [label]="result.data?.location1" [value]="result.data?.location1Days"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.location2" [value]="result.data?.location2Days"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.location3" [value]="result.data?.location3Days"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [showIfZero]="false" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card" *ngIf="showCommodity">
      <div class="performance-title">
        <svg-icon name="checklist" svgClass="checklist-icon" viewBox="0 0 18 18"></svg-icon>
        Dwell by Commodity
      </div>
      @if (dwellCommodityQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric [label]="result.data?.commodity1Type" [value]="result.data?.commodity1Dwell"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity2Type" [value]="result.data?.commodity2Dwell"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity3Type" [value]="result.data?.commodity3Dwell"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity4Type" [value]="result.data?.commodity4Dwell"
            [decimalsToDisplay]="2" unitOfMeasure="Days" [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="closed-box" svgClass="closed-box-icon" viewBox="0 0 22 20"></svg-icon>
        Station Dwell
      </div>
      @if (dwellStationQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric label="> 4 Days" [value]="result.data?.days4To8" unitOfMeasure="Stations"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="> 8 Days" [value]="result.data?.days8To16" unitOfMeasure="Stations"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="> 16 Days" [value]="result.data?.days16To20" unitOfMeasure="Stations"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="> 20 Days" [value]="result.data?.daysGreaterThan20" unitOfMeasure="Stations"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
  </div>
</div>
<div class="content-width">
  <div class="content-width dashboard-cards">
    <div class="dashboard">
      <div class="dashboard-title">
        Historical Performance
      </div>
      <div class="dashboard-divider"></div>
      <div class="muted">
        Historical Average Dwell By Location
      </div>
      <app-date-filter [startYear]="2021" [formGroup]="filterForm" yearsFormControlName="years"
        monthsFormControlName="months"></app-date-filter>
      <div class="area-container">
        <div class="dashboard-title d-flex justify-content-between">
          <span>Average Dwell Map</span>
          <svg-icon *ngIf="showFilterLimitsToolTip" name="info-circle" svgClass="info-circle" title="Filters for Commodities, Loaded and Stations do not apply to data in the Average Dwell Map"></svg-icon>
        </div>
        <div class="muted">
          Historical Average Dwell By Location
        </div>
        <app-dashboard-dwell-by-location-map></app-dashboard-dwell-by-location-map>
      </div>
      <div class="area-container mt-3">
        <div class="dashboard-title d-flex justify-content-between">
          <span>Average Dwell by Location</span>
          <svg-icon *ngIf="showFilterLimitsToolTip" name="info-circle" svgClass="info-circle" title="Filters for Commodities, Loaded and Stations do not apply to data in the Average Dwell Map"></svg-icon>
        </div>
        <div class="muted">
          Historical Average Dwell By Location
        </div>
        <div class="d-flex justify-content-end period-chips-container">
          <kendo-chiplist selection="single" (selectedChange)="locationChange($event)">
              <kendo-chip *ngFor="let location of locations; index as i" [selected]="i == 0" rounded="full" fillMode="outline" [label]="location"></kendo-chip>
          </kendo-chiplist>
        </div>
        <app-dashboard-dwell-by-location-chart></app-dashboard-dwell-by-location-chart>
        <app-dashboard-dwell-by-location-table></app-dashboard-dwell-by-location-table>
      </div>
      <div class="area-container mt-3">
        <div class="dashboard-title d-flex justify-content-between">
          <span>Total Dwell</span>
          <svg-icon name="info-circle" svgClass="info-circle" title="Only Filters for Month(s), Year(s) and Minimum Dwell Days apply to data in Total Dwell"></svg-icon>
        </div>
        <div class="muted">
          Historical Average Dwell By Location
        </div>
        <div class="d-flex justify-content-end period-chips-container">
          <kendo-chiplist selection="single" (selectedChange)="periodFilterChange($event)">
              <kendo-chip *ngFor="let period of periods; index as i" [selected]="i == 0" rounded="full" fillMode="outline" [label]="period"></kendo-chip>
          </kendo-chiplist>
        </div>
        <app-dashboard-dwell-total-chart></app-dashboard-dwell-total-chart>
      </div>
    </div>
  </div>
</div>