import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AverageHistoricalCycleTime } from '../../../../../../models/cycle/average-historical-cycle-time';
import { DataSource } from '../../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-average-historical-cycle-time-chart',
  templateUrl: './average-historical-cycle-time-chart.component.html',
  styleUrl: './average-historical-cycle-time-chart.component.css',
})
export class AverageHistoricalCycleTimeChartComponent {
  @Input() dataSource?: DataSource;
}
