import { Component, OnInit } from '@angular/core';
import { LocationSettingService } from '../../services/location-settings.service';
import { LocationFilters } from '../../../../../shared-rail-performance/models/filters/location-filters';
import { Observable } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core';
import { SubSink } from 'subsink';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-location-settings',
  templateUrl: './location-settings.component.html',
  styleUrl: './location-settings.component.scss',
})
export class LocationSettingsComponent implements OnInit {
  public queryResult$?: Observable<QueryObserverResult<string[], Error>>;
  public queryCustomerResult$?: Observable<QueryObserverResult<string[], Error>>;
  private sub = new SubSink();
  public opened = false;
  openedClmData = false;
  public showDefaultPage = true;

  public formGroup = new FormGroup({
    Destinations: new FormControl(false),
    Carriers: new FormControl(false),
    Commodities: new FormControl(false),
  });

  checkBoxListItem = [{ label: 'Destinations', text: 'All origin destination pairs from this location will be imported from CLM data. ' }, { label: 'Carriers', text: 'All carriers servicing this location will be imported from CLM data. ' }, { label: 'Commodities', text: 'All commodities transported to/from this location will be imported from CLM data. ' }]

  constructor(private locationSettingService: LocationSettingService) {

  }

  protected mapLocationDataSource(result: string[] | undefined, headingName: string) {
    this.locationData.forEach((data: any) => {
      if (data.headingName === headingName) {
        data.content.forEach((element: any) => {
          element.forEach((item: any) => {
            if (item.label === 'Commodity') {
              item.value = result;
            }
            else if (item.label === 'Customers') {
              item.value = result;
            }
          });

        });
      }
    });
  }

  ngOnInit(): void {
    let filters = new LocationFilters();

    this.queryResult$ = this.locationSettingService.getAllCommoditites(filters).result$;
    this.queryCustomerResult$ = this.locationSettingService.getAllCustomers(filters).result$;
    this.sub.sink = this.queryResult$.subscribe((result) => {
      if (result.isLoading) {
        this.LoadintialData();
      }
      else {

        this.mapLocationDataSource(result.data, "COMMODITIES")
      }
    });
    this.sub.sink = this.queryCustomerResult$.subscribe((result) => {
      if (!result.isLoading) {
        this.mapLocationDataSource(result.data, "DESTINATIONS")
      }
    });
  }

  // Sample array for options
  options = [
    'Location 1',
    'Location 2',
    'Location 3',
    'Location 4',
  ];

  listItems: Array<string> = ["Small", "Medium", "Large"];
  selectedValue = '';
  showLocationEditPage = false;
  currentSelection = "";

  // Variable to bind selected value
  selectedOption = 'Locations';

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  locationData: any[] = []

  jsonLocationData =
    {
      'LOCATION INFO': [[{ label: 'Location Name', value: 'Location XXX' }, { label: 'Capacity', value: '0000 Railcars', field: 'input' },
      { label: 'Loading Days', value: [{ selected: true, label: 'Mon' }, { selected: false, label: 'Tue' }, { selected: false, label: 'Wed' }, { selected: false, label: 'Thu' }, { selected: true, label: 'Fri' }, { selected: false, label: 'Sat' }], field: 'chip' }]],

      COMMODITIES: [[
        { label: 'Commodity', value: ['Grain', 'Propane'], field: 'selection', selectedValue: 'Grain' }, { label: 'Units', value: ['Tonnes', 'Litres', 'Kilograms'], field: 'selection', selectedValue: 'Tonnes' }, { label: 'Railcar Capacity', value: '0000 tonnes/railcar', field: 'input' }, { label: 'Loading Capacity', value: '--', field: 'input' },
      ]],

      CARRIERS: [
        [{ label: 'Class | Carrier', value: ['CN', 'FN', 'GN'], field: 'selection', selectedValue: 'CN' }, { label: 'Switching Days', value: [{ selected: false, label: 'Mon', numberOfSwitch: 0 }, { selected: true, label: 'Tue', numberOfSwitch: 1 }, { selected: false, label: 'Wed', numberOfSwitch: 0 }, { selected: true, label: 'Thu', numberOfSwitch: 3 }, { selected: false, label: 'Fri', numberOfSwitch: 0 }, { selected: false, label: 'Sat', numberOfSwitch: 0 }], field: 'chip', showLabel: true }],
      ],
      DESTINATIONS: [[
        { label: 'Destination', value: ['Vancouver', 'test1', 'test3'], field: 'selection', selectedValue: 'Vancouver' }, { label: 'Customers', value: ['Cando Rail', 'Cando Sarnia', 'Cargill', 'Cemex'], field: 'multiselect', selectedValue: 'Cando Rail,Cando Sarnia' }],

      ]
    }

  private LoadintialData() {
    let filters = new LocationFilters();
    this.queryResult$ = this.locationSettingService.getAllCommoditites(filters).result$;
    this.queryCustomerResult$ = this.locationSettingService.getAllCustomers(filters).result$;
    this.sub.sink = this.queryResult$.subscribe((result) => {
      if (!result.isLoading) {
        this.mapLocationDataSource(result.data, "COMMODITIES");
      }
    });
    this.sub.sink = this.queryCustomerResult$.subscribe((result) => {
      if (!result.isLoading) {
        this.mapLocationDataSource(result.data, "DESTINATIONS");
      }
    });
  }

  removeItem(headerName: string, index: number) {
    this.locationData.find(x => x.headingName === headerName).content.splice(index, 1);
    console.log(headerName, index);

    this.jsonLocationData['LOCATION INFO']
  }

  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

  isArray(value: any): boolean {
    return Array.isArray(value)
  }

  showEditPage() {
    this.showLocationEditPage = true;
  }

  onValueChange(event: string, headerName: string, itemIndex: number, contentIndex: number) {
    console.log('Selected option:', event, headerName, itemIndex, contentIndex);
    this.locationData.find(x => x.headingName === headerName).content[contentIndex][itemIndex].selectedValue = event
  }

  valueChange(event: string) {

    if (event !== "Locations") {

      this.showDefaultPage = false;

      if (event !== this.currentSelection) {
        this.currentSelection = event;
        this.locationData = [{
          headingName: 'LOCATION INFO', content: []
        }, {
          headingName: 'COMMODITIES', content: []
        },
        {
          headingName: 'CARRIERS', content: []
        },
        {
          headingName: 'DESTINATIONS', content: []
        }]
      }
    } else {
      this.showDefaultPage = true;
      this.locationData = [];
    }
  }

  onSubmit() {
    console.log(this.formGroup)
    this.openedClmData = false;
    if (this.locationData[0].content.length === 0) {
      this.locationData[0].content.push(...this.jsonLocationData['LOCATION INFO'])
    }

    if (this.formGroup.value.Commodities && this.locationData[1].content.length === 0) {
      this.locationData[1].content.push(...this.jsonLocationData.COMMODITIES)
    }

    if (this.formGroup.value.Carriers && this.locationData[2].content.length === 0) {
      this.locationData[2].content.push(...this.jsonLocationData.CARRIERS)

    }

    if (this.formGroup.value.Destinations && this.locationData[3].content.length === 0) {
      this.locationData[3].content.push(...this.jsonLocationData.DESTINATIONS)

    }
            }


  daysChanged(selectedIds: any[], headerName: String, itemIndex: number, contentIndex: number) {

    this.locationData.find(x => x.headingName === headerName).content[contentIndex][itemIndex].value.forEach((element: any, index: number) => {
      if (selectedIds.includes(index)) {
        element.selected = true;
      } else {
        element.selected = false;
            }
          });

    // console.log(this.locationData)
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  openClmData() {
    this.openedClmData = true;
  }

  closeClmData() {
    this.openedClmData = false;
  }

  LoadLocationInfo() {
    this.LoadintialData();
  }

  onTextboxChange(event: string, headerName: string, itemIndex: number, contentIndex: number, valueIndex?: number) {
    console.log('Selected option:', event, headerName, itemIndex, contentIndex);
    if (valueIndex) {
      this.locationData.find(x => x.headingName === headerName).content[contentIndex][itemIndex].value[valueIndex].numberOfSwitch = event
    } else {
    this.locationData.find(x => x.headingName === headerName).content[contentIndex][itemIndex].value = event
    }
    // console.log(this.locationData)
  }

  addNewField(heading: string) {
    const data = this.locationData.find(x => x.headingName === heading);

    switch (data.headingName) {
      case 'COMMODITIES':
        data.content.push([{ label: 'Commodity', value: ['Grain', 'Propane'], field: 'selection' }, { label: 'Units', value: ['Tonnes', 'Litres', 'Kilograms'], field: 'selection' }, { label: 'Railcar Capacity', value: '', field: 'input' }, { label: 'Loading Capacity', value: '', field: 'input' }])
        break;
      case 'CARRIERS':
        data.content.push([{ label: 'Class | Carrier', value: ['CN', 'FN', 'GN'], field: 'selection' }, { label: 'Switching Days', value: [{ selected: false, label: 'Mon', numberOfSwitch: 0 }, { selected: false, label: 'Tue', numberOfSwitch: 0 }, { selected: false, label: 'Wed', numberOfSwitch: 0 }, { selected: false, label: 'Thu', numberOfSwitch: 0 }, { selected: false, label: 'Fri', numberOfSwitch: 0 }, { selected: false, label: 'Sat', numberOfSwitch: 0 }], field: 'chip', showLabel: true }])
        break;
      case 'DESTINATIONS':
        data.content.push([
          { label: 'Destination', value: ['Vancouver', 'test1', 'test3'], field: 'selection' }, { label: 'Customers', value: [{ name: 'Customers1', value: 'Customers1' }, { name: 'Customer2', value: 'Customer2' }, { name: 'Customer3', value: 'Customer3' }], field: 'multiselect' }])
        break;
      default:
        data.content.push([{ label: 'Capacity', value: '0000 Railcars', field: 'input' },
        { label: 'Loading Days', value: [{ selected: false, label: 'Mon' }, { selected: false, label: 'Tue' }, { selected: false, label: 'Wed' }, { selected: false, label: 'Thu' }, { selected: false, label: 'Fri' }, { selected: false, label: 'Sat' }], field: 'chip' }])

    }

  }

  onCancel() {
    this.showLocationEditPage = false;
  }

  onSave() {
    this.showLocationEditPage = false;
    console.log(this.locationData);
  }
}
