import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { MessageService, SharedModule } from 'primeng/api';
//import { CaptchaModule } from 'primeng/captcha'; //appears not to be in this version?
import { DialogService } from 'primeng/dynamicdialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer-component/footer.component';
import { HeaderComponent } from './components/header-component/header.component';
import { ForecastComponent } from './routes/forecast/forecast.component';
import { COMMON_PRIME_NG_IMPORTS } from './common/common-imports';
import { SharedRailPerformanceModule } from './modules/shared-rail-performance/shared-rail-performance.module';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { environment } from '../environments/environment';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularDateHttpInterceptor } from './interceptors/date.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { provideAuth0 } from '@auth0/auth0-angular';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authHttpInterceptorFn } from '@auth0/auth0-angular';
import { AdminModule } from './modules/admin/admin.module';
import { dateHttpInterceptorFn } from './interceptors/date.interceptor.function';
import { ForecastDashboardModule } from './modules/forecast-dashboard/forecast-dashboard.module';
import { NotificationService } from '@progress/kendo-angular-notification';
import { KENDO_DROPDOWNTREE } from '@progress/kendo-angular-dropdowns';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ...KENDO_DROPDOWNTREE,
    ...COMMON_PRIME_NG_IMPORTS,
    SharedModule,
    SharedRailPerformanceModule,
    DashboardModule,
    ForecastDashboardModule,
    AuthenticationModule,
    AdminModule,
    FontAwesomeModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/svg/icons.json',
    }),
  ],
  exports: [
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ForecastComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    //{ provide: APP_BASE_HREF, useValue: '/#' }, 
    MessageService, //primeng
    DialogService, //primeng
    NotificationService, //kendo
    environment.production ? [] : provideQueryDevTools(),
    { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true },
    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0.authorizationParams.audience,
                scope: 'profile email'
              },
            }
          }
        ]
      }
    }),
    provideHttpClient(withInterceptors([authHttpInterceptorFn, dateHttpInterceptorFn])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
