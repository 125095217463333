import { NgModule } from "@angular/core";
import { LoginButtonComponent } from "./components/login-button/login-button.component";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SharedRailPerformanceModule } from "../shared-rail-performance/shared-rail-performance.module";

@NgModule({
    imports: [
      CommonModule,
      AngularSvgIconModule,
      SharedRailPerformanceModule
    ],
    exports: [
      LoginButtonComponent
    ],
    declarations: [
      LoginButtonComponent
    ],
    schemas: [],
})
export class AuthenticationModule { 
  constructor() {

  }
}
