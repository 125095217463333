import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SubSink } from 'subsink';
import { combineLatest, startWith } from 'rxjs';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { CycleMetric } from '../../../../../constants/cycle-metric';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { DashboardCycleTimeFormService } from '../services/dashboard-cycle-time-form.service';
import { QueryObserverResult } from '@tanstack/query-core';

@Component({
  selector: 'app-average-historical-cycle-time-table-charts',
  templateUrl: './average-historical-cycle-time-table-charts.component.html',
  styleUrl: './average-historical-cycle-time-table-charts.component.css'
})
export class AverageHistoricalCycleTimeTableChartsComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(public cycleTimeFormService: DashboardCycleTimeFormService,
              private dashboardFormService: DashboardReportFormService,
              public formattingService: FormattingService) {
    
  }

  ngOnInit(): void {
    this.sub.sink = combineLatest([
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.cycleTimeFormService.cycleMetricControl.valueChanges.pipe(startWith(CycleMetric.CycleTime)),
      this.cycleTimeFormService.yearsFormControl.valueChanges.pipe(startWith([])),
      this.cycleTimeFormService.monthsFormControl.valueChanges.pipe(startWith([]))
    ]).subscribe(() => {
        this.cycleTimeFormService.queryCycleHistory();
    });

    this.cycleTimeFormService.queryCycleHistory();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  pageChange(event: PageChangeEvent) {
    this.cycleTimeFormService.cycleHistoryPaging.skip = event.skip;
    this.cycleTimeFormService.cycleHistoryPaging.take = event.take;

    this.cycleTimeFormService.queryCycleHistory();
  }

  getWidthOfChartColumn(result: QueryObserverResult<GridDataResult, Error>) {
    return result.data?.data[0].columns.length * 75;
  }
}
