import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardType } from "./constants/dashboard-type";
import { reportResolver } from "./resolvers/report-resolver";
import { DashboardCycleTimeComponent } from "./submodules/cycle/components/dashboard-cycle-time.component";
import { DashboardLandingComponent } from "./submodules/landing/components/dashboard-landing.component";
import { DashboardReportLayoutComponent } from "./submodules/layout/components/dashboard-report-layout/dashboard-report-layout.component";
import { DashboardNetworkDwellComponent } from "./submodules/network-dwell/components/dashboard-network-dwell.component";
import { DashboardRailcarComponent } from "./submodules/railcar/components/dashboard-railcar.component";
import { UserResolver } from "../authentication/resolvers/user-resolver";

const routes: Routes = [
  {
    path: '',
    component: DashboardLandingComponent
  },
  {
    path: '',
    component: DashboardReportLayoutComponent,
    children: [
      { 
        path: 'cycle-time',
        loadChildren: () =>
          import('./submodules/cycle/cycle-dashboard.module').then((m) => m.CycleDashboardModule),
        data: {
          dashboardType: DashboardType.CycleTime
        },
        resolve: { reports: reportResolver },
      }, 
      { 
        path: 'network-dwell', 
        loadChildren: () =>
          import('./submodules/network-dwell/network-dwell-dashboard.module').then((m) => m.NetworkDwellDashboardModule),
        data: {
          dashboardType: DashboardType.NetworkDwell
        },
        resolve: { reports: reportResolver },
      }, 
      { 
        path: 'railcar-volume', 
        loadChildren: () =>
          import('./submodules/railcar/railcar-dashboard.module').then((m) => m.RailcarDashboardModule),
        data: {
          dashboardType: DashboardType.RailcarVolume
        },
        resolve: { reports: reportResolver },
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }