import { Directive, Input } from "@angular/core";
import { DataBindingDirective, GridComponent } from "@progress/kendo-angular-grid";
import { DataService } from "./data.service";
import { SubSink } from "subsink";
import { FilterDescriptor, toDataSourceRequestString } from "@progress/kendo-data-query";
import { filter, tap } from "rxjs";

@Directive({
    selector: '[railPerformanceBindingDirective]'
})
export class RailPerformanceBindingDirective extends DataBindingDirective {
    @Input() public railPerformanceBindingDirective?: DataService<any, any>;

    private sub = new SubSink();

    constructor(grid: GridComponent) {
        super(grid);
    }

    public override ngOnInit(): void {
        super.ngOnInit(); // Call the base implementation.

        this.rebind(); // Custom function for querying the data.

        this.sub.sink = this.railPerformanceBindingDirective?.isLoading$.subscribe((isLoading) => {
            this.grid.loading = isLoading;
        });
    }

    public override rebind(): void {
        this.grid.loading = true;
        let requestString = toDataSourceRequestString(this.state);

        this.sub.sink = this.railPerformanceBindingDirective?.getAll(requestString).result$.pipe(filter((result) => {
            return result.isSuccess;
        })).subscribe((result) => {
            this.grid.data = {
                data: result?.data?.results ?? [],
                total: result?.data?.total ?? 0
            };
            this.grid.loading = false;

            this.notifyDataChange(); // Notify the grid that the data is changed.
        });
    }
}