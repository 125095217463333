<div class="forecast-switching-performance-report">
  <div class="d-flex justify-content-between forecast-switching-performance-border">
    <app-forecast-demand-report-filters [reportTitle]="reportTitle"></app-forecast-demand-report-filters>
  </div>
  <div class="row d-flex justify-content-between forecast-switching-performance-border">
    <div class="col-4">
      <div class="visual_title ps-1">Switching Performance</div>
      <div class="visual_subtitle ps-1">Description</div>
    </div>
    <div>
      <div class="legend">
        <span class="dot-planned"></span>
        <span>Performed as Planned</span>
        &nbsp;
        &nbsp;
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-margin">Inaccurate</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-margin">Missed</span>
      </div>
    </div>
    <div class="d-flex justify-content-around" id="switchingPerformanceYearLineReport" #switchingPerformanceYearLineReport>
      @if (dataSource && !noData) {
      <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource" [renderAt]="'switchingPerformanceYearLineReport'">
      </fusioncharts>
      }
      @if(noData)
      {
      <div class="parentdiv">
        <div>
          <div class="nodataheader">No Data</div>
          <div class="nodatacontent">Select a report or adjust filters to view data.</div>
        </div>
      </div>
      }
    </div>
    <div>
      <div class="d-flex justify-content-center gap-5">
        @if (dataSource2) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource2">
        </fusioncharts>
        }
        @if (dataSource3) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource3">
        </fusioncharts>
        }
        @if (dataSource4) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource4">
        </fusioncharts>
        }
      </div>
    </div>

    <div class="h-100">
      <div class="col-12 card-bg">
        <table class="borderenabled w-100 firstmilelastmile-table">
          <tr>
            <td class="padding-3px"> <label></label></td>
            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
              <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Performed as Planned</label></td>
            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
              <label class="fulfilled-color fw-bolder">{{dt.performedAsPlanned | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Inaccurate</label></td>
            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
              <label class="fulfilled-color fw-bolder">{{dt.inaccurate | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Missed</label></td>
            <td class="padding-3px" *ngFor="let dt  of fulfillmentData">
              <label class="fulfilled-color fw-bolder">{{dt.missed | percent: '1.2-2'}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
