import { Component } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { UserFormService } from '../services/user-form.service';
import { EditEvent } from '@progress/kendo-angular-grid';
import { UserDataService } from '../../../services/user-data.service';
import { UserDto } from '../../../dto/user-dto';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrl: './user-grid.component.scss',
})
export class UserGridComponent {
  public filters: CompositeFilterDescriptor;

  public userToEdit?: UserDto;

  constructor(private userFormService: UserFormService, public userDataService: UserDataService) {
    this.filters = {
      logic: 'and',
      filters: [
        {
          field: 'isRegistered',
          operator: 'eq',
          value: true,
        }
      ],
    };
  }

  get isCreating(): boolean {
    return this.userFormService.isCreating;
  }
  
  get isUpdating(): boolean {
    return this.userFormService.isUpdating;
  }

  get modalWindowTitle(): string {
    return this.isCreating ? 'Create New User' : 'Update User';
  }

  closeUserModal() {
    this.userFormService.isCreating = false;
    this.userFormService.isUpdating = false;

    this.userFormService.userForm.reset();
  }

  createNewUserClick() {
    this.userFormService.isCreating = true;
  }

  editHandler(event: EditEvent) {
    let user = event.dataItem as UserDto;

    //user.childCustomerIds = customer.childCustomers.map(c => c.customerId);
    this.userFormService.userForm.patchValue(user, { emitEvent: false });

    this.userFormService.isUpdating = true;
  }
}
