import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { injectInfiniteQuery, injectQuery, injectQueryClient } from '@ngneat/query';
import { BehaviorSubject, catchError, from, map, tap, throwError } from 'rxjs';
import { PagedModel } from '../data/paged-model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';

export interface ServiceConfig {
  resourceEndpoint: string;
}

export const SERVICE_CONFIG = new InjectionToken<ServiceConfig>('ServiceConfig');

@Injectable({
  providedIn: 'root',
})
export class DataService<TModel, TDto> {
  protected readonly baseUrl: string;
  protected readonly resourceEndpoint: string;

  protected readonly query = injectQuery();
  protected readonly queryClient = injectQueryClient();

  public isLoading$ = new BehaviorSubject<boolean>(false);

  private activeNotification?: NotificationRef;

  constructor(
    protected httpClient: HttpClient,
    private notificationService: NotificationService,
    @Inject(SERVICE_CONFIG) protected config: ServiceConfig
  ) {
    this.baseUrl = environment.apiUrl;
    this.resourceEndpoint = config.resourceEndpoint;
  }

  getAll(queryString: string) {
    this.isLoading$.next(true);

    return this.query({
      queryKey: [this.config.resourceEndpoint, queryString] as const,
      queryFn: () => {
        return this.httpClient.get<PagedModel<TModel>>(`${this.baseUrl}${this.resourceEndpoint}?${queryString}`).pipe(tap(() => {
          this.isLoading$.next(false);
        }),
        catchError((error) => this.handleError(error)));
      },
      throwOnError: true,
      staleTime: Infinity,
      retry: false,
    });
  }

  get(id: number) {
    this.isLoading$.next(true);

    return this.query({
      queryKey: [this.config.resourceEndpoint] as const,
      queryFn: () => {
        return this.httpClient.get<TModel[]>(`${this.baseUrl}${this.resourceEndpoint}/${id}`).pipe(tap(() => {
          this.isLoading$.next(false);
        }),
        catchError((error) => this.handleError(error)));
      },
      throwOnError: true,
      staleTime: Infinity,
      retry: false
    });
  }

  create(dto: TDto) {
    return this.httpClient.post<TModel>(`${this.baseUrl}${this.resourceEndpoint}`, dto).pipe(tap(() => {
      this.isLoading$.next(true);

      return from(this.queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === this.config.resourceEndpoint
      }));
    }));
  }

  update(dto: TDto) {
    return this.httpClient.put<TModel>(`${this.baseUrl}${this.resourceEndpoint}`, dto).pipe(tap(() => {
      this.isLoading$.next(true);

      return from(this.queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === this.config.resourceEndpoint
      }));
    }));
  }

  delete(id: number) {
    return this.httpClient.delete<number>(`${this.baseUrl}${this.resourceEndpoint}/${id}`).pipe(tap(() => {
      this.isLoading$.next(true);

      return from(this.queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === this.config.resourceEndpoint
      }));
    }));
  }

  private handleError(error: HttpErrorResponse) {
    if (this.activeNotification) {
      this.activeNotification.hide();
  }

    if (error.headers.has('BadRequestMessage')) {
      this.activeNotification = this.notificationService.show({
          content: error.headers.get('Badrequestmessage')!,
          cssClass: 'error',
          hideAfter: 5000,
      });
    }
    else {
      this.activeNotification = this.notificationService.show({
          content: 'An error occurred. Please try again.',
          type: { style: "error", icon: true },
          hideAfter: 5000,
          cssClass: ["validation-message-notification"]
      });
    }

    return throwError(() => error);
  }
}