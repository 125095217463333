import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { CurrentPrevious } from '../../../../shared-rail-performance/models/current-previous';
import { VolumeDemandDto, VolumeDemandYearReport } from '../../../models/volume-demand-report';
import { SwitchingPerformanceDto, SwitchingPerformanceYearReport } from '../../../models/switching-performance-report';
import { FirstMileLastMileAccurate, FirstMileLastMileDto } from '../../../models/first-mile-last-mile-report';
import { ForecastSummaryReport } from '../../../models/forecast-summary-report';

@Injectable({
  providedIn: 'root'
})
export class KpiDataService {

  constructor(private http: HttpClient) { }

  getRailcarVolumeKpi() {
    let railcarVolumeKpiUrl: string = environment.apiUrl + 'Kpi/RailcarVolume';
    return this.http.get<CurrentPrevious>(railcarVolumeKpiUrl);
  }

  getNetworkDwellKpi() {
    let networkDwellKpiUrl: string = environment.apiUrl + 'Kpi/NetworkDwell';
    return this.http.get<CurrentPrevious>(networkDwellKpiUrl);
  }

  getCycleTimeKpi() {
    let cycleTimeKpiUrl: string = environment.apiUrl + 'Kpi/CycleTime';
    return this.http.get<CurrentPrevious>(cycleTimeKpiUrl);
  }

  getVolumeDemandReport() {
    let volumeDemandReportUrl: string = environment.apiUrl + 'RailcarVolume/VolumeDemandReport';
    return this.http.get<VolumeDemandDto>(volumeDemandReportUrl);
  }

  getVolumeDemandYearReport() {
    let volumeDemandReportUrl: string = environment.apiUrl + 'RailcarVolume/GetVolumeDemandYearReport';
    return this.http.get<VolumeDemandYearReport[]>(volumeDemandReportUrl);
  }

  getSwitchingPerformanceReport() {
    let switchingPerformanceReportUrl: string = environment.apiUrl + 'RailcarVolume/SwitchingPerformanceReport';
    return this.http.get<SwitchingPerformanceDto>(switchingPerformanceReportUrl);
  }

  getSwitchingPerformanceYearReport() {
    let switchingPerformanceReportUrl: string = environment.apiUrl + 'RailcarVolume/GetSwitchingPerformanceYearReport';
    return this.http.get<SwitchingPerformanceYearReport[]>(switchingPerformanceReportUrl);
  }

  getFirstMileLastMileReport() {
    let firstMileLastMileReportUrl: string = environment.apiUrl + 'RailcarVolume/FirstMileLastMileReport';
    return this.http.get<FirstMileLastMileDto>(firstMileLastMileReportUrl);
  }

  GetFirstMileLastMileYearReport() {
    let firstMileLastMileReportUrl: string = environment.apiUrl + 'RailcarVolume/GetFirstMileLastMileYearReport';
    return this.http.get<FirstMileLastMileAccurate[]>(firstMileLastMileReportUrl);
  }

  getForecastSummaryReport() {
    let firstMileLastMileReportUrl: string = environment.apiUrl + 'RailcarVolume/ForecastSummaryReport';
    return this.http.get<ForecastSummaryReport>(firstMileLastMileReportUrl);
  }

  getForecastTemplate(forecastType: string) {
    let forecastTemplateURL: string = environment.apiUrl + 'RailcarVolume/GetForecastTemplate';
    return this.http.post(forecastTemplateURL, new String(forecastType), { observe: 'response', responseType: 'blob', headers: new HttpHeaders().append("Content-Type", "application/json") });

  }
}
