import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FusionChartsModule } from "angular-fusioncharts";
import { AngularSvgIconModule } from "angular-svg-icon";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from '../../../../../common/common-imports';
import { ForecastReportsComponent } from './components/forecast-reports.component';
import { SharedRailPerformanceModule } from '../../../../shared-rail-performance/shared-rail-performance.module';
import { ForecastSwitchingPerformanceReportComponent } from './components/forecast-switching-performance-report/forecast-switching-performance-report.component';
import { ForecastFirstMileLastMileReportComponent } from "./components/forecast-first-mile-last-mile-report/forecast-first-mile-last-mile-report.component";
import { ForecastVolumeDemandReportComponent } from "./components/forecast-volume-demand-report/forecast-volume-demand-report.component";
import { ForecastDemandReportComponent } from "./components/forecast-demand-report/forecast-demand-report.component";
import { ForecastDemandReportFiltersComponent } from "./components/forecast-demand-report-filters/forecast-demand-report-filters.component";
import { IntlModule } from "@progress/kendo-angular-intl";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { FormFieldModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { IconsModule } from "@progress/kendo-angular-icons";

@NgModule({
  imports: [
    HttpClientModule,
    SharedRailPerformanceModule,
    ...COMMON_PRIME_NG_IMPORTS,
    ...COMMON_KENDO_IMPORTS,
    IntlModule,
    DateInputsModule,
    LabelModule,
    FormFieldModule,
    ButtonsModule,
    IconsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FusionChartsModule,
    FontAwesomeModule,
    AngularSvgIconModule
  ],
  exports: [
    ForecastVolumeDemandReportComponent,
    ForecastDemandReportComponent,
    ForecastFirstMileLastMileReportComponent,
    ForecastSwitchingPerformanceReportComponent,
    ForecastReportsComponent,
    ForecastDemandReportFiltersComponent
  ],
  declarations: [
    // CycleTimeAverageByLocationGraphComponent,
    // KpiMetricsComponent,
    // NetworkDwellAverageByLocationMapComponent,
    // RailcarVolumeHistoryGraphComponent,
    ForecastDemandReportFiltersComponent,
    ForecastVolumeDemandReportComponent,
    ForecastDemandReportComponent,
    ForecastFirstMileLastMileReportComponent,
    ForecastSwitchingPerformanceReportComponent,
    ForecastReportsComponent
  ],
  schemas: [],
})
export class ForecastReportModule {

}
