import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, startWith } from 'rxjs';
import { NetworkDwellStatusGridRow } from '../../../../models/network-dwell/status/network-dwell-status-grid-row';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SubSink } from 'subsink';
import _ from 'lodash';
import { GridColumn } from '../../../../../shared-rail-performance/models/grid-column';
import { LocationType } from '../../../../constants/location-type';
import { DashboardReportFormService } from '../../../layout/services/dashboard-report-form.service';
import { DashboardNetworkDwellFormService } from '../../services/dashboard-network-dwell-form.service';

@Component({
  selector: 'app-dashboard-dwell-by-location-table',
  templateUrl: './dashboard-dwell-by-location-table.component.html',
  styleUrl: './dashboard-dwell-by-location-table.component.scss',
})
export class DashboardDwellByLocationTableComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  data: NetworkDwellStatusGridRow[] = [];

  constructor(public networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService
    ) {
  }

  ngOnInit(): void {
    this.networkDwellFormService.queryNetworkDwellByLocation();

    this.sub.sink = combineLatest([
      this.networkDwellFormService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.networkDwellFormService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.networkDwellFormService.graphLocationTypeControl.valueChanges.pipe(startWith(LocationType.StateProvince)),
    ]).subscribe(() => {
      this.networkDwellFormService.queryNetworkDwellByLocation();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get dwellByLocationTableResult$() {
    return this.networkDwellFormService.dwellByLocationTableResult$;
  }

  pageChange(event: PageChangeEvent) {
    this.networkDwellFormService.dwellByLocationTablePagingState.skip = event.skip;
    this.networkDwellFormService.dwellByLocationTablePagingState.take = event.take;

    this.networkDwellFormService.queryNetworkDwellByLocation();
  }

  rowClassCallback = (context: RowClassArgs) => {
    if (context.index > 0 && context.dataItem.isNewStateProvince) {
      return { 'new-state-province-row': true };
    }

    return { };
  };

  getColumnTitle(monthYear: string, columnList: string[]) {
    let monthYearSplit = monthYear.split(' ');

    if ((columnList?.length ?? 0) > 0 && columnList[0] == monthYear) {
      return `${monthYearSplit[1]}<br/>${monthYearSplit[0]}`;
    }
    else if (monthYearSplit[0].startsWith('Jan')) {
      return `${monthYearSplit[1]}<br/>${monthYearSplit[0]}`;
    }
    else {
      return monthYearSplit[0];
    }
  }

  getColumnWidth(column: GridColumn) {
    return Math.max(80, (column.title?.length ?? 5) * 15);
  }
}
