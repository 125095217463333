import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import Polyline from "@arcgis/core/geometry/Polyline";
import Color from "@arcgis/core/Color";
import Graphic from "@arcgis/core/Graphic";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import _ from 'lodash';
import { geodesicDensify } from '@arcgis/core/geometry/geometryEngine';
import SizeVariable from '@arcgis/core/renderers/visualVariables/SizeVariable';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import FeatureLayer  from "@arcgis/core/layers/FeatureLayer";
import { MapService } from '../../../../../../shared-rail-performance/services/map-service';
import { Cycle } from '../../../../../models/cycle/cycle';

@Injectable({
  providedIn: 'root'
})
export class AverageCycleTimeMapService extends MapService implements OnDestroy {
  colorVariableStops$ = new BehaviorSubject<any[]>([]);

  private sub = new SubSink();

  constructor(ngZone: NgZone) {
    super(ngZone);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadColorVariableStops(max: number, mean?: number) {
    let adjustedMax = Math.max(Math.ceil(max), 1);
    let middle = mean ?? adjustedMax / 2;

    this.colorVariableStops$.next([
      {
        value: 0,
        color: new Color([140, 192, 89]),
        size: 1
      },
      {
        value: middle + ((adjustedMax - middle) / 2),
        color: new Color([243, 207, 96]),
        size: 1.5
      },
      {
        value: adjustedMax,
        color: new Color([212, 104, 93]),
        size: 2
      }
    ]);
  }

  loadCycles(mapViewElement: ElementRef, cycles: Cycle[], expandButtonClick?: () => void) {
    return this.ngZone.runOutsideAngular(async () => {
      if (cycles) {
        let max = Math.max(...cycles.map(x => x.cycleTimeAverage ?? 0));
        let mean = _.mean(cycles.map(x => x.cycleTimeAverage ?? 0));

        this.loadColorVariableStops(max, mean);
      }

      const simpleLineSymbol = new SimpleLineSymbol({
        width: 1,
        cap: "round",
        join: "round",
        style: "solid"
      });

      const cycleRenderer = new SimpleRenderer({
        symbol: simpleLineSymbol,
        label: "Cycle",
        visualVariables: [
          new SizeVariable({
            field: 'average-cyle-time',
            stops: this.colorVariableStops$.value
          }),
          new ColorVariable({
            field: 'average-cyle-time',
            stops: this.colorVariableStops$.value
          })
        ]
      });

      let cycleLineGraphics = this.getCycleLineGraphics(cycles);

      let cycleLayer = new FeatureLayer({
        renderer: cycleRenderer,
        id: 'cycles-layer',
        objectIdField: "OBJECTID",
        outFields: ['*'],
        fields: [
          {
            name: 'average-cyle-time',
            type: 'string'
          },
          {
            name: "OBJECTID",
            type: "oid"
          }
        ],
        source: cycleLineGraphics
      });

      return this.loadMap(mapViewElement, {
        layers: [cycleLayer],
        zoomLevel: 2,
        expandButtonClick,
      }).then((view: MapView) => {
        //this.disableZooming(view);
        return view;
      }).then((view: MapView) => {
        return view;
      });
    });
  }

  expandButtonClick() {

  }

  getCycleLineGraphics(cycles: Cycle[]) {
    let graphics: Graphic[] = [];

    for (let cycle of cycles) {
      let polyline = new Polyline({
        paths: [
          [
            [cycle.originLongitude, cycle.originLatitude],
            [cycle.destinationLongitude, cycle.destinationLatitude],
          ]
        ],
      });

      let curvedPolyline = geodesicDensify(polyline, 1000);

      let graphic = new Graphic({
        geometry: curvedPolyline,
        attributes: {
          'average-cyle-time': cycle.cycleTimeAverage,
          'cycle-count': cycle.cycleCount,
        },
      });

      graphics.push(graphic);
    }

    return graphics;
  }

  private clickedStationEvent(response: any, mapView: MapView) {
    if (response.results.length) {
      const graphic = response.results[0].graphic as Graphic;
      const attributes = graphic.attributes;
      const name = attributes['state-province-code'];
    }
  }
}
