import { PopupModule } from "@progress/kendo-angular-popup";
import { LabelModule } from "@progress/kendo-angular-label";
import { AutoCompleteModule, ComboBoxModule, DropDownListModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { ExpansionPanelModule, LayoutModule } from "@progress/kendo-angular-layout";
import { SharedModule } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { DialogModule } from "primeng/dialog";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { InputTextModule } from "primeng/inputtext";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { RippleModule } from "primeng/ripple";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { GridModule } from "@progress/kendo-angular-grid";
import { DialogsModule, WindowModule } from "@progress/kendo-angular-dialog";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PivotGridModule } from '@progress/kendo-angular-pivotgrid';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { KENDO_NOTIFICATION } from '@progress/kendo-angular-notification';

export const COMMON_PRIME_NG_IMPORTS = [
    AvatarModule,
    ButtonModule,
    CardModule,
    ChartModule,
    DialogModule,
    DynamicDialogModule,
    InputTextModule,
    MenuModule,
    MenubarModule,
    RippleModule,
    SharedModule,
    TooltipModule,
    ToastModule
];

export const COMMON_KENDO_IMPORTS = [
    PopupModule,
    AutoCompleteModule,
    LabelModule,
    MultiSelectModule,
    ExpansionPanelModule,
    DropDownListModule,
    IconsModule,
    ButtonsModule,
    GridModule,
    DialogsModule,
    InputsModule,
    ListViewModule,
    NotificationModule,
    PivotGridModule,
    LayoutModule,
    ComboBoxModule,
    ToolBarModule,
    ...KENDO_NOTIFICATION
];