import { Component } from '@angular/core';

@Component({
  selector: 'app-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrl: './schedule-report.component.scss'
})
export class ScheduleReportComponent {


  // Sample array for options
  options = [
    'Location 1',
    'Location 2',
    'Location 3',
    'Location 4',
  ];

  valueChange(event: any) {

  }

  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }
}
