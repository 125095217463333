import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardNetworkDwellComponent } from "./components/dashboard-network-dwell.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardNetworkDwellComponent,
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NetworkDwellDashboardRoutingModule { }