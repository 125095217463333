import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FusionChartsModule } from "angular-fusioncharts";
import { AngularSvgIconModule } from "angular-svg-icon";
import { COMMON_PRIME_NG_IMPORTS, COMMON_KENDO_IMPORTS } from "../../../../common/common-imports";
import { SharedRailPerformanceModule } from "../../../shared-rail-performance/shared-rail-performance.module";
import { DashboardCycleTimeComponent } from "./components/dashboard-cycle-time.component";
import { CycleDashboardRoutingModule } from "./cycle-dashboard-routing.module";
import { AverageCycleTimeMapComponent } from "./components/cycle/average-cycle-time-map/average-cycle-time-map.component";
import { AverageCycleTimeTableComponent } from "./components/cycle/average-cycle-time-table/average-cycle-time-table.component";
import { AverageHistoricalCycleTimeChartComponent } from "./components/cycle/average-historical-cycle-time-table-charts/average-historical-cycle-time-chart/average-historical-cycle-time-chart.component";
import { AverageHistoricalCycleTimeTableChartsComponent } from "./components/cycle/average-historical-cycle-time-table-charts/average-historical-cycle-time-table-charts.component";
import { AverageHistoricalTripTimeChartComponent } from "./components/trip/average-historical-trip-time-table-charts/average-historical-trip-time-chart/average-historical-trip-time-chart.component";
import { AverageHistoricalTripTimeTableChartsComponent } from "./components/trip/average-historical-trip-time-table-charts/average-historical-trip-time-table-charts.component";
import { AverageTripTimeMapComponent } from "./components/trip/average-trip-time-map/average-trip-time-map.component";
import { AverageTripTimeTableComponent } from "./components/trip/average-trip-time-table/average-trip-time-table.component";
import { TripDashboardTabComponent } from "./components/trip/trip-dashboard-tab/trip-dashboard-tab.component";
import { CycleDashboardTabComponent } from "./components/cycle/cycle-dashboard-tab/cycle-dashboard-tab.component";

@NgModule({
    imports: [
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        CycleDashboardRoutingModule
    ],
    exports: [
    ],
    declarations: [
        DashboardCycleTimeComponent,
        TripDashboardTabComponent,
        CycleDashboardTabComponent,
        AverageCycleTimeTableComponent,
        AverageHistoricalCycleTimeTableChartsComponent,
        AverageHistoricalCycleTimeChartComponent,
        AverageCycleTimeMapComponent,
        AverageTripTimeTableComponent,
        AverageHistoricalTripTimeTableChartsComponent,
        AverageHistoricalTripTimeChartComponent,
        AverageTripTimeMapComponent,
    ],
    schemas: [],
})
export class CycleDashboardModule { 

}
