import { compileFilter } from '@progress/kendo-data-query';
function forEachDesc(desc, callbackfn) {
    if ('filters' in desc) {
        desc.filters.map(child => forEachDesc(child, callbackfn));
    }
    else {
        callbackfn(desc);
    }
}
const filterFields = (filter, callback) => {
    const descriptors = Array.isArray(filter) ? filter : filter.filters;
    descriptors.forEach(desc => forEachDesc(desc, callback));
};
/** @hidden */
export const initializeFiltering = (rows, columns, filter) => {
    const compositeFilter = filter ?
        (Array.isArray(filter) ? { logic: 'and', filters: filter } : filter) : undefined;
    const predicate = compositeFilter ? compileFilter(compositeFilter) : () => true;
    const axes = rows.concat(columns);
    const fieldValues = [];
    filterFields(filter || [], (desc) => {
        if ('field' in desc && desc.field) {
            fieldValues.push(desc.field);
        }
    });
    const result = { hasFilter: false, predicate, filteringAxes: [] };
    if (fieldValues.some(f => typeof f === 'function')) {
        result.filteringAxes = axes.slice();
    }
    else if (fieldValues.length > 0) {
        const filteringFields = Array.from(new Set(fieldValues).values());
        const filteringAxes = axes.filter(a => filteringFields.indexOf(a.key) >= 0);
        result.filteringAxes = filteringAxes;
    }
    result.hasFilter = result.filteringAxes.length > 0;
    return result;
};
