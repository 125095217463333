<div class="content-width">
  <kendo-tabstrip class="mt-5">
    <kendo-tabstrip-tab title="CYCLES" [selected]="true">
      <ng-template kendoTabContent>
        <app-cycle-dashboard-tab></app-cycle-dashboard-tab>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="TRIPS">
      <ng-template kendoTabContent>
        <app-trip-dashboard-tab></app-trip-dashboard-tab>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>