import { Component } from '@angular/core';
import { CustomerDataService } from '../../../services/customer-data.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CustomerFormService } from '../services/customer-form.service';
import { EditEvent } from '@progress/kendo-angular-grid';
import { CustomerDto } from '../../../dto/customer-dto';

@Component({
  selector: 'app-customer-grid',
  templateUrl: './customer-grid.component.html',
  styleUrl: './customer-grid.component.scss',
})
export class CustomerGridComponent {
  public filters: CompositeFilterDescriptor;

  public customerToEdit?: CustomerDto;

  constructor(private customerFormService: CustomerFormService, public customerDataService: CustomerDataService) {
    this.filters = {
      logic: 'and',
      filters: [
        {
          field: 'isRegistered',
          operator: 'eq',
          value: true,
        }
      ],
    };
  }

  get isCreating(): boolean {
    return this.customerFormService.isCreating;
  }
  
  get isUpdating(): boolean {
    return this.customerFormService.isUpdating;
  }

  get modalWindowTitle(): string {
    return this.isCreating ? 'Create New Customer' : 'Update Customer';
  }

  closeCustomerModal() {
    this.customerFormService.isCreating = false;
    this.customerFormService.isUpdating = false;

    this.customerFormService.customerForm.reset();
  }

  createNewCustomerClick() {
    this.customerFormService.isCreating = true;
  }

  editHandler(event: EditEvent) {
    let customer = event.dataItem as CustomerDto;

    customer.childCustomerIds = customer.childCustomers.map(c => c.customerId);
    this.customerFormService.customerForm.patchValue(customer, { emitEvent: false });
    this.customerFormService.setAssociatedCustomers();

    this.customerFormService.isUpdating = true;
  }
}
