import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';
import { UserService } from '../../modules/admin/services/user.service';
import { Role } from '../../modules/admin/constants/role';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  showLogin: boolean = false;
  public areaData = [
    {
      categoryName: "Dashboard",


    },
    {
      categoryName: "Storage",

      subCategories: [
        { subCategoryName: "Wall Shelving" },
        { subCategoryName: "Floor Shelving" },
        { subCategoryName: "Kids Storage" },
      ],
    },

  ];
  loggedIn = false;

  public idleState = 'NOT_STARTED';
  public lastPing?: Date = new Date();
  public userSubs$ = new Subject();
  public subscription!: Subscription;
  public openMenuModal = false;

  Role = Role;

  private sub = new SubSink();

  constructor(public dialogService: DialogService, public userService: UserService) {

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {

  }

  get trinityRailMarketUrl(): string {
    return environment.trinityRailMarketUrl;
  }

  get showForecastDemand(): boolean {
    return environment.showForecastDemand;
  }

  openLogin() {
    if (this.showLogin) {
      this.showLogin = false;
    }
    else {
      this.showLogin = true;
    }
  }

  closeLogin(e: Event) {
    if (e.target != e.currentTarget) {
      return;
    }

    if (this.showLogin) {
      this.showLogin = false;
    }
    else {
      this.showLogin = true;
    }
  }
}
