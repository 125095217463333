import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CommodityFilters } from '../models/filters/commodity-filters';
import { environment } from '../../../../environments/environment';
import { SubSink } from 'subsink';
import { combineAll, combineLatest, forkJoin, Observable } from 'rxjs';
import { DataQueryService } from './data-query/data-query.service';
import { DataQueryServiceConfiguration } from './data-query/data-query-service-configuration';


@Injectable({
  providedIn: 'root'
})
export class CommodityDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([this.prefetchGet(CommodityDataServiceConfiguration.AllCommodities)]);
  }

  getCommodities(filters?: CommodityFilters) {
    return this.get<string[]>(CommodityDataServiceConfiguration.AllCommodities);
  }
}

abstract class CommodityDataServiceConfiguration {
  static readonly AllCommodities = new DataQueryServiceConfiguration<string[]>(
      'commodities',
       environment.apiUrl + 'Commodities'
  );
}
