import { Injectable, OnDestroy } from "@angular/core";
import { SubSink } from "subsink";
import { DashboardTripTimeFormService } from "../trip/services/dashboard-trip-time-form.service";
import { DashboardCycleTimeFormService } from "../cycle/services/dashboard-cycle-time-form.service";
import { combineLatest, filter } from "rxjs";
import { DataExportService } from "../../../../../shared-rail-performance/services/data-export/data-export.service";
import { DashboardReportFormService } from "../../../layout/services/dashboard-report-form.service";

@Injectable({
    providedIn: 'root'
})
export class DashboardCycleFormService implements OnDestroy {
    private sub = new SubSink();

    constructor(private cycleFormService: DashboardCycleTimeFormService,
                private tripFormService: DashboardTripTimeFormService,
                private dataExportService: DataExportService,
                private reportFormService: DashboardReportFormService) {

    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    exportData() {
        this.cycleFormService.querySummaryData();
        this.cycleFormService.queryCycleHistory();
        this.cycleFormService.queryCycles();

        this.tripFormService.querySummaryData();
        this.tripFormService.queryTripHistory();
        this.tripFormService.queryTrips();

        this.sub.sink = combineLatest({
            averageCycleTime: this.cycleFormService.averageCycleTimeQueryResult$!,
            cycleCount: this.cycleFormService.cycleCountQueryResult$!,
            cyclesQuery: this.cycleFormService.cyclesQueryResult$!,
            cycleHistory: this.cycleFormService.cycleHistoryQueryResult$!,
            averageTripTime: this.tripFormService.averageTripTimeQueryResult$!,
            tripCount: this.tripFormService.tripCountQueryResult$!,
            trips: this.tripFormService.tripsQueryResult$!,
            tripHistory: this.tripFormService.tripHistoryQueryResult$!
        }).pipe(filter(({ averageCycleTime, cycleCount, cyclesQuery, cycleHistory, averageTripTime, tripCount, trips, tripHistory }) => {
            return averageCycleTime.isSuccess && cycleCount.isSuccess && cyclesQuery.isSuccess && cycleHistory.isSuccess && averageTripTime.isSuccess && tripCount.isSuccess && trips.isSuccess && tripHistory.isSuccess;
        })).subscribe(({ averageCycleTime, cycleCount, cyclesQuery, cycleHistory, averageTripTime, tripCount, trips, tripHistory }) => {
            let workbook = this.dataExportService.createWorkbook();

            this.dataExportService.addWorksheet(workbook, 'Cycle Average', [averageCycleTime.data!], [
                { header: 'Current Month', key: 'current' },
                { header: 'Previous Month', key: 'previous' }
            ]);

            this.dataExportService.addWorksheet(workbook, 'Cycle Count', [cycleCount.data!], [
                { header: 'Current Month', key: 'current' },
                { header: 'Previous Month', key: 'previous' }
            ]);

            this.dataExportService.addWorksheet(workbook, 'Cycles', cyclesQuery.data ?? [], [
                { header: 'Origin City', key: 'originCity' },
                { header: 'Origin State/Province', key: 'originStateName' },
                { header: 'Destination City', key: 'destinationCity' },
                { header: 'Destination State/Province', key: 'destinationStateName' },
                { header: 'Count', key: 'cycleCount' },
                { header: 'Average', key: 'cycleTimeAverage' },
            ]);

            this.dataExportService.addWorksheet(workbook, 'Cycle History', cycleHistory.data?.data?.flatMap(x => x.rawData) ?? [], [
                { header: 'Origin City', key: 'originCity' },
                { header: 'Origin State/Province', key: 'originStateProvince' },
                { header: 'Destination City', key: 'destinationCity' },
                { header: 'Destination State/Province', key: 'destinationStateProvince' },
                { header: 'Month', key: 'month' },
                { header: 'Year', key: 'year' },
                { header: 'Count', key: 'cycleCount' },
                { header: 'Average', key: 'cycleTimeAverage' },
            ]);

            this.dataExportService.addWorksheet(workbook, 'Trip Average', [averageTripTime.data!], [
                { header: 'Current Month', key: 'current' },
                { header: 'Previous Month', key: 'previous' }
            ]);

            this.dataExportService.addWorksheet(workbook, 'Trip Count', [tripCount.data!], [
                { header: 'Current Month', key: 'current' },
                { header: 'Previous Month', key: 'previous' }
            ]);

            this.dataExportService.addWorksheet(workbook, 'Trips', trips.data ?? [], [
                { header: 'Origin City', key: 'originCity' },
                { header: 'Origin State/Province', key: 'originStateName' },
                { header: 'Destination City', key: 'destinationCity' },
                { header: 'Destination State/Province', key: 'destinationStateName' },
                { header: 'Count', key: 'tripCount' },
                { header: 'Average', key: 'tripTimeAverage' },
            ]);

            this.dataExportService.addWorksheet(workbook, 'Trip History', tripHistory.data?.data?.flatMap(x => x.rawData) ?? [], [
                { header: 'Origin City', key: 'originCity' },
                { header: 'Origin State/Province', key: 'originStateProvince' },
                { header: 'Destination City', key: 'destinationCity' },
                { header: 'Destination State/Province', key: 'destinationStateProvince' },
                { header: 'Month', key: 'month' },
                { header: 'Year', key: 'year' },
                { header: 'Count', key: 'tripCount' },
                { header: 'Average', key: 'tripTimeAverage' },
            ]);

            this.dataExportService.saveWorkbook(workbook, 'Cycle-Trip');

            this.reportFormService.exportDataComplete$.next();
        });
    }
}