import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, startWith } from 'rxjs';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Period, Periods } from '../../../../constants/period';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../../layout/services/dashboard-report-form.service';
import { DashboardRailcarFormService } from '../../services/dashboard-railcar-form.service';
import { RailcarDataService } from '../../services/railcar-data.service';

@Component({
  selector: 'app-dashboard-railcar-graph',
  templateUrl: './dashboard-railcar-graph.component.html',
  styleUrl: './dashboard-railcar-graph.component.css',
})
export class DashboardRailcarGraphComponent implements OnInit, OnDestroy {
  faCaretDown = faCaretDown;
  public periods = Periods;
  public filterForm = this.formService.filterForm;

  private sub = new SubSink();

  constructor(private railcarDataService: RailcarDataService,
    private formService: DashboardRailcarFormService,
    private dashboardFormService: DashboardReportFormService
  ) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.formService.queryGraphData();

    this.sub.sink = combineLatest([
      this.formService.filterForm.valueChanges.pipe(startWith({})), 
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))]).subscribe(() => {
        this.formService.queryGraphData();
    });
  } 

  get railcarVolumeHistoryByPeriodQuery$() {
    return this.formService.railcarVolumeHistoryByPeriodQuery$;
  }

  periodFilterChange(event: any) {
    let periodIndex = event[0];
    let period = Periods[periodIndex];

    if (period == Period.Annually || period == Period.Quarterly) {
      this.formService.monthsFormControl.setValue([]);
      this.formService.monthsFormControl.disable();
    }
    else {
      this.formService.monthsFormControl.enable();
    }

    this.formService.periodFormControl.setValue(period);
  }
}
