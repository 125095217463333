import { NgModule } from "@angular/core";
import { SharedRailPerformanceModule } from "../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../common/common-imports";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";  
import { FusionChartsModule } from "angular-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { environment } from "../../../environments/environment";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RailcarDashboardModule } from "./submodules/railcar/railcar-dashboard.module";
import { NetworkDwellDashboardModule } from "./submodules/network-dwell/network-dwell-dashboard.module";
import { LayoutModule } from "./submodules/layout/layout.module";
import { CycleDashboardModule } from "./submodules/cycle/cycle-dashboard.module";
import { DashboardFiltersModule } from "./submodules/filters/dashboard-filters.module";
import { LandingDashboardModule } from "./submodules/landing/landing-dashboard.module";

@NgModule({
    imports: [
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        DashboardRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule,
        AngularSvgIconModule,
        RailcarDashboardModule,
        NetworkDwellDashboardModule,
        LayoutModule,
        CycleDashboardModule,
        DashboardFiltersModule,
        LandingDashboardModule
    ],
    exports: [
    ],
    declarations: [

    ],
    schemas: [],
})
export class DashboardModule { 
  constructor() {
    FusionChartsModule.fcRoot(FusionCharts, Column2D, FusionTheme);

    FusionCharts.options.creditLabel = false;
    (FusionCharts.options as any).license({
      key: environment.fusionChartApiKey,
      creditLabel: false,
    });
  }
}
