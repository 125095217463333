import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { CustomerFormService } from '../services/customer-form.service'
import { Customer } from '../../../models/customer';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrl: './customer-edit.component.scss',
})
export class CustomerEditComponent implements OnDestroy {
  private sub = new SubSink();

  constructor(private customerFormService: CustomerFormService) {
    
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get associatedCustomers(): Customer[] {
    return this.customerFormService.associatedCustomers;
  }

  get customerForm() {
    return this.customerFormService.customerForm;
  }

  get unregisteredCustomers() {
    return this.customerFormService.unregisteredCustomers;
  }

  saveCustomer() {
    this.customerFormService.saveCustomer();
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
}
