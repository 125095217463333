/** @hidden */
export const createFlatSchemaDimensions = (dimensions, measures) => {
    const result = Object.keys(dimensions).map(dim => ({
        caption: dim,
        defaultHierarchy: dim,
        description: '',
        name: dim,
        uniqueName: dim,
        hierarchyUniqueName: dim,
        measure: true,
        type: 1 // https://github.com/telerik/kendo-pivotgrid-common/blob/develop/src/models/responseDiscover.ts#L12-L14
    }));
    if (measures.length) {
        result.push({
            caption: 'Measures',
            children: measures.map(m => ({
                aggregator: '1',
                caption: String(m.name),
                defaultFormat: '',
                description: '',
                displayFolder: '',
                groupName: String(m.name),
                name: String(m.name),
                uniqueName: String(m.name)
            })),
            type: 2,
            description: '',
            name: 'Measures',
            uniqueName: '[Measures]'
        });
    }
    return result;
};
