import { NgModule } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { SharedRailPerformanceModule } from "../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../common/common-imports";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FusionChartsModule } from "angular-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { environment } from "../../../environments/environment";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LandingForecastDashboardModule } from "./submodules/landing/landing-forecast-dashboard.module";
import { LocationSettingsComponent } from './submodules/location-settings/components/location-settings/location-settings.component';

import { ForecastDashboardRoutingModule } from "./forecast-dashboard-routing.module";
import { ForecastReportModule } from "./submodules/reports/forecast-reports/forecast-reports.module";
import { ScheduleModule } from "./submodules/schedule/schedule.module";

@NgModule({
  imports: [
    HttpClientModule,
    SharedRailPerformanceModule,
    ...COMMON_PRIME_NG_IMPORTS,
    ...COMMON_KENDO_IMPORTS,
    ForecastDashboardRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FusionChartsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    // RailcarDashboardModule,
    ScheduleModule,
    ForecastReportModule,
    LandingForecastDashboardModule
  ],
  exports: [
    LocationSettingsComponent

  ],
  declarations: [
    LocationSettingsComponent

  ],
  schemas: [],
})
export class ForecastDashboardModule {
  constructor() {
    // FusionChartsModule.fcRoot(FusionCharts, Column2D, FusionTheme);

    // FusionCharts.options.creditLabel = false;
    // (FusionCharts.options as any).license({
    //   key: environment.fusionChartApiKey,
    //   creditLabel: false,
    // });
  }
}
