import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core';
import { addMonths, startOfMonth } from 'date-fns';
import { formatDate } from '@angular/common';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { ChartSeries } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { ChartSeriesData } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { KpiDataService } from '../../../services/kpi-data.service';
import { SubSink } from 'subsink';
import { cloneDeep } from 'lodash';
import { SwitchingPerformanceDto } from '../../../../../models/switching-performance-report';
@Component({
  selector: 'app-forecast-switching-performance',
  templateUrl: './forecast-switching-performance.component.html',
  styleUrl: './forecast-switching-performance.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ForecastSwitchingPerformanceComponent implements OnInit {

  private sub = new SubSink();
  isSwitchingPerformanceReportByMonthLoading$ = new BehaviorSubject<boolean>(true);

  donutWidth = 200;
  donutHeight = 280;
  dataSource2: any;
  dataSource3: any;
  dataSource4: any;

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      numberSuffix: "%",
      showlegend: "0",
      showLabels: "0",
      defaultcenterlabel: "Oct 2024",
      // Center label options
      centerLabel: "Oct 2024",
      centerLabelFontSize: "14",
      centerLabelFontColor: "black",
      centerLabelBold: "1",
      showpercentvalues: "1",
      aligncaptionwithcanvas: "0",
      pieRadius: "100",
      doughnutRadius: "50",
      captionpadding: "0",
      decimals: "1",
      // label value styles
      valueFontColor: "#474747", // Change value font color
      patternBgColor: "#F44444",
      labelBorderRadius: 14,
      labelBgColor: "#FAFAFA", //#FDFDFD,
      labelFontSize: 14,
      // "dataLabelPosition": "insideStart",
      valuePosition: "inside",
      valueBgColor: "#000000", // Background color for values (effectively works in tooltip)
      valueBgAlpha: "60", // Opacity for the background color
    },
    data: [
      { label: "Performed as Planned", value: "0", color: "#1B365D" },
      { label: "Inaccurate", value: "0", color: "#F2D675" },
      { label: "Missed", value: "0", color: "#F24444" },
    ]
  };

  constructor(private kpiDataService: KpiDataService) {

  }
  initialized(event: any) {
    console.log(event)
  }
  ngOnInit(): void {
    this.sub.sink = this.kpiDataService.getSwitchingPerformanceReport().subscribe((dwellKpi: SwitchingPerformanceDto) => {
      this.loadSwitchingPerformanceReport(dwellKpi);
      this.isSwitchingPerformanceReportByMonthLoading$.next(false);
    });
  }

  loadSwitchingPerformanceReport(dataset: SwitchingPerformanceDto | null) {
    if (dataset == null) return;
    this.dataSource2 = this.setMonthlyReport(dataset);
    this.dataSource3 = this.setcurrentYearReport(dataset);
    this.dataSource4 = this.setpreviousYearReport(dataset);
  }

  setMonthlyReport(dataset: SwitchingPerformanceDto) {
    var monthlyData = cloneDeep(this.dataSource);
    monthlyData.data[0].value = dataset.monthReport.performedAsPlanned * 100;
    monthlyData.data[1].value = dataset.monthReport.inaccurate * 100;
    monthlyData.data[2].value = dataset.monthReport.missed * 100;
    monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText;
    monthlyData.chart.centerLabel = dataset.monthReport.labelText;
    if (dataset.monthReport.performedAsPlanned == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.inaccurate == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.missed == 0) {
      const index = monthlyData.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        monthlyData.data.splice(index, 1);
      }
    }
    if (dataset.monthReport.performedAsPlanned == 0 &&
      dataset.monthReport.inaccurate == 0 &&
      dataset.monthReport.missed == 0) {
      monthlyData.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      monthlyData.chart.showToolTip = "0";
      monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText + " No Data";
      monthlyData.chart.centerLabel = dataset.monthReport.labelText + " No Data";
    }

    if (monthlyData.data.length <= 1) {
      monthlyData.chart.showpercentvalues = "0";
      monthlyData.chart.valuePosition = "none";
    }
    return monthlyData;
  }

  setcurrentYearReport(dataset: SwitchingPerformanceDto) {
    var currentYearReport = cloneDeep(this.dataSource);
    currentYearReport.data[0].value = dataset.currentYearReport.performedAsPlanned * 100;
    currentYearReport.data[1].value = dataset.currentYearReport.inaccurate * 100;
    currentYearReport.data[2].value = dataset.currentYearReport.missed * 100;
    currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText;
    currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText;
    if (dataset.currentYearReport.performedAsPlanned == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.inaccurate == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.missed == 0) {
      const index = currentYearReport.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        currentYearReport.data.splice(index, 1);
      }
    }
    if (dataset.currentYearReport.performedAsPlanned == 0 &&
      dataset.currentYearReport.inaccurate == 0 &&
      dataset.currentYearReport.missed == 0) {
      currentYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      currentYearReport.chart.showToolTip = "0";
      currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText + " No Data";
      currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText + " No Data";
    }
    if (currentYearReport.data.length <= 1) {
      currentYearReport.chart.showpercentvalues = "0";
      currentYearReport.chart.valuePosition = "none";
    }
    return currentYearReport;
  }
  setpreviousYearReport(dataset: SwitchingPerformanceDto) {
    var previousYearReport = cloneDeep(this.dataSource);
    previousYearReport.data[0].value = dataset.previousYearReport.performedAsPlanned * 100;
    previousYearReport.data[1].value = dataset.previousYearReport.inaccurate * 100;
    previousYearReport.data[2].value = dataset.previousYearReport.missed * 100;
    if (dataset.previousYearReport.performedAsPlanned == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Performed as Planned");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    if (dataset.previousYearReport.inaccurate == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Inaccurate");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    if (dataset.previousYearReport.missed == 0) {
      const index = previousYearReport.data.findIndex((item: any) => item.label === "Missed");
      if (index !== -1) {
        previousYearReport.data.splice(index, 1);
      }
    }
    previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText;
    previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText;
    if (dataset.previousYearReport.performedAsPlanned == 0 &&
      dataset.previousYearReport.inaccurate == 0 &&
      dataset.previousYearReport.missed == 0) {
      previousYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      previousYearReport.chart.showToolTip = "0";
      previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText + " No Data";
      previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText + " No Data";
    }
    if (previousYearReport.data.length <= 1) {
      previousYearReport.chart.showpercentvalues = "0";
      previousYearReport.chart.valuePosition = "none";
    }
    return previousYearReport;
  }

}
