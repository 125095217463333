import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { addMonths, startOfMonth } from 'date-fns';
import { BehaviorSubject, combineLatest, filter, from, map, mergeMap, Observable } from 'rxjs';
import { QueryObserverResult } from '@ngneat/query';
import { AverageCycleTimeMapService } from './average-cycle-time-map.service';
import MapView from '@arcgis/core/views/MapView';
import _ from 'lodash';
import { sortBy } from 'sort-by-typescript';
import ColorScale from "color-scales";
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { CurrentPrevious } from '../../../../../../shared-rail-performance/models/current-previous';
import { PagedResultWithPointers } from '../../../../../../shared-rail-performance/models/paging/paged-result';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { DashboardType } from '../../../../../constants/dashboard-type';
import { Cycle } from '../../../../../models/cycle/cycle';
import { CycleHistory } from '../../../../../models/cycle/cycle-history';
import { CycleFilters } from '../../../../../models/filters/cycle-filters';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { CycleDataService } from './cycle-data.service';
import { groupBy } from '../../../../../../shared-rail-performance/functions/array-functions';
import { Month } from "../../../../../../shared-rail-performance/models/month";
import { CycleMetric } from '../../../../../constants/cycle-metric';
import { DataExportService } from '../../../../../../shared-rail-performance/services/data-export/data-export.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardCycleTimeFormService implements OnDestroy {
  private mapView?: MapView;
  isMapExpanded$ = new BehaviorSubject<boolean>(false);

  filterForm: FormGroup;

  isMapLoading$ = new BehaviorSubject<boolean>(false);

  averageCycleTimeQueryResult$?: Observable<QueryObserverResult<CurrentPrevious, Error>>;
  cycleCountQueryResult$?: Observable<QueryObserverResult<CurrentPrevious, Error>>;
  cyclesQueryResult$?: Observable<QueryObserverResult<Cycle[], Error>>;
  cycleHistoryQueryResult$?: Observable<QueryObserverResult<GridDataResult, Error>>;

  cycleHistoryPaging: State = {
    skip: 0,
    take: 10,
  };

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
    private dashboardReportFormService: DashboardReportFormService,
    private cycleDataService: CycleDataService,
    private cycleMapService: AverageCycleTimeMapService,
    private formattingService: FormattingService,
    private dataExportService: DataExportService) {
    let months = this.getLast6Months().map(x => x.getMonth() + 1);
    let years = this.getLast6Months().map(x => x.getFullYear()).filter(this.onlyUnique);

    this.filterForm = this.formBuilder.group({
      years: new FormControl<number[]>(years),
      months: new FormControl<number[]>(months),
      cycleMetric: new FormControl<string>(CycleMetric.CycleTime)
    });
  }

  querySummaryData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<CycleFilters>(DashboardType.CycleTime);

    this.averageCycleTimeQueryResult$ = this.cycleDataService.getCycleTimeAverage(filters).result$;
    this.cycleCountQueryResult$ = this.cycleDataService.getCycleCount(filters).result$;
  }

  queryCycleHistory() {
    let page = this.cycleHistoryPaging.skip! / this.cycleHistoryPaging.take!;

    this.queryCycleHistoryByPage(page);
  }

  queryCycleHistoryByPage(page: number) {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<CycleFilters>(DashboardType.CycleTime);
    filters.months = this.months;
    filters.years = this.years;
    filters.pageSize = this.cycleHistoryPaging.take;
    filters.page = page;
    filters.cycleMetric = this.cycleMetric;

    let transform = (cycleHistory$: Observable<PagedResultWithPointers<CycleHistory, CycleFilters>>) => this.aggregateHistoryRecordsByOriginDestination(cycleHistory$, this.cycleMetric);

    this.cycleHistoryQueryResult$ = this.cycleDataService.getHistoricalCycles(filters, transform).result$;
  }

  aggregateHistoryRecordsByOriginDestination(cycleHistory$: Observable<PagedResultWithPointers<CycleHistory, CycleFilters>>, cycleMetric: string) {
    return cycleHistory$.pipe(map((cycleHistoryRecords) => {
      let categories: string[] = [];

      let uniqueMonthYears = groupBy<CycleHistory>(cycleHistoryRecords.results.sort(sortBy('year', 'month')), (record: any) => this.formattingService.formatMonthNameYear(new Date(record.year, record.month - 1, 1)));

      uniqueMonthYears.forEach((uniqueMonthYear: any) => {
        categories.push(uniqueMonthYear.key);
      });

      let uniqueOriginDestinations = groupBy<CycleHistory>(cycleHistoryRecords.results.sort(sortBy('originCity', 'originStateProvince', 'destinationCity', 'destinationStateProvince', 'year', 'month')), (record) => {
        return record.originCity + '-' + record.originStateProvince + '-' + record.destinationCity + '-' + record.destinationStateProvince;
      });

      let maxCycleTimeAverage = _.maxBy(cycleHistoryRecords.results, x => x.cycleTimeAverage)?.cycleTimeAverage ?? 0;
      let maxCycleCount = _.maxBy(cycleHistoryRecords.results, x => x.cycleCount)?.cycleCount ?? 0;

      let maxValueToUse = 1;

      if (cycleMetric == CycleMetric.CycleTime) {
        maxValueToUse = maxCycleTimeAverage;
      }
      else if (cycleMetric == CycleMetric.CycleCount) {
        maxValueToUse = maxCycleCount;
      }

      let cycleTimeColorScale = new ColorScale(0, maxValueToUse, ["#00FF00", "#FFFF00", "#FF0000"], 1);

      let gridDataResult: GridDataResult = {
        data: [],
        total: cycleHistoryRecords.total
      };

      uniqueOriginDestinations!.sort(sortBy('key')).forEach((group: any) => {
        let dataSource: DataSource = {
          "chart": {
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipBgAlpha": "0",
            "showValues": "1",
            "placeValuesInside": "0",
            "showYAxisValues": "0",
            "bgAlpha": "0",
            "canvasbgAlpha": "0",
            "chartLeftMargin": "0",
            "chartTopMargin": "0",
            "chartRightMargin": "0",
            "chartBottomMargin": "0",
            "showHoverEffect": "0",
            "plotHoverEffect": "0",
            "showBorder": "0",
            "showPlotBorder": "0",
            "borderThickness": "0",
            "theme": "fusion",
            "showToolTip": "0",
            "adjustDiv": "0",
            "yAxisMinValue": "0",
            "yAxisMaxValue": (maxValueToUse * 2).toString(),
            "numDivLines": "0"
          },
          categories: [Categories.getCategoriesFromStringList(categories)],
          columns: categories,
          data: group.items.sort(sortBy('year', 'month')).map((item: any) => {
            if (cycleMetric == CycleMetric.CycleTime && item.cycleTimeAverage && item.cycleTimeAverage > 0) {
              return {
                value: item.cycleTimeAverage.toString(),
                color: cycleTimeColorScale.getColor(item.cycleTimeAverage).toHexString()
              };
            }
            else if (cycleMetric == CycleMetric.CycleCount && item.cycleCount && item.cycleCount > 0) {
              return {
                value: item.cycleCount.toString(),
                color: cycleTimeColorScale.getColor(item.cycleCount).toHexString()
              };
            }
            else {
              return {
                value: 'N/A'
              };
            }
          }),
          rawData: group.items
        };

        gridDataResult.data.push(dataSource);
      });

      return gridDataResult;
    }));
  }

  loadCycleMap(mapViewElement: ElementRef, expandButtonClick: () => void) {
    this.isMapLoading$.next(true);
    this.queryCycles();

    this.sub.sink = this.cyclesQueryResult$?.pipe(mergeMap((results) => {
      return from(this.cycleMapService.loadCycles(mapViewElement, results.data ?? [], expandButtonClick).then((mapView: any) => {
        this.mapView = mapView;
      })).pipe(map(() => {
        return results;
      }))
    }), filter((results) => {
      return !results.isLoading;
    })).subscribe(() => {
      this.isMapLoading$.next(false);
    });
  }

  queryCycles() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<CycleFilters>(DashboardType.CycleTime);
    filters.months = this.months;
    filters.years = this.years;
    
    this.cyclesQueryResult$ = this.cycleDataService.getCycles(filters).result$;
  }

  onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  getLast6Months(): Date[] {
    let months: Date[] = [];
    let currentMonth = startOfMonth(new Date());

    for (let x = 0; x < 6; x++) {
      months.push(currentMonth);
      currentMonth = addMonths(currentMonth, -1);
    }

    return months;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get yearsFormControl(): FormControl<number[]> {
    return this.filterForm.get('years') as FormControl<number[]>;
  }

  get years(): number[] {
    return this.yearsFormControl.value as number[];
  }

  get monthsFormControl(): FormControl<number[]> {
    return this.filterForm.get('months') as FormControl<number[]>;
  }

  get months(): number[] {
    return this.monthsFormControl.value as number[];
  }

  get cycleMetricControl(): FormControl<string> {
    return this.filterForm.get('cycleMetric') as FormControl<string>;
  }

  get cycleMetric(): string {
    return this.cycleMetricControl.value as string;
  }

  updateMonths(months: Month[]) {
    this.monthsFormControl.setValue(months.map(x => x.number));
  }

  updateYears(years: number[]) {
    this.yearsFormControl.setValue(years);
  }
}
