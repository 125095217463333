<kendo-grid [railPerformanceBindingDirective]="userDataService" [skip]="0" [pageSize]="10" [pageable]="true"
    [filterable]="true" [filter]="filters" (edit)="editHandler($event)">
    <ng-template kendoGridToolbarTemplate position="top">
        <kendo-grid-spacer></kendo-grid-spacer>
        <button (click)="createNewUserClick()" kendoGridAddCommand>Create New User</button>
    </ng-template>
    <kendo-grid-column title="Name" [width]="400" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.firstName }} {{ dataItem.lastName }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="email" title="Email" [filterable]="false"></kendo-grid-column>
    <kendo-grid-column field="createdOn" title="Created On" format="g" [filterable]="false"></kendo-grid-column>
    <kendo-grid-column field="lastModified" title="Last Modified" format="g" [filterable]="false"></kendo-grid-column>
    <kendo-grid-command-column>
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand class="btn btn-action-primary">Edit</button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
<kendo-dialog [title]="modalWindowTitle" *ngIf="isCreating || isUpdating" (close)="closeUserModal()"
    [minWidth]="400" [width]="900">
    <app-user-edit></app-user-edit>
</kendo-dialog>