<div class="content-width action-row d-flex flex-row" #reportActions>
    <div class="heading py-2 ms-1">
        Schedule
    </div>
    <div class="py-2 report-list-container d-flex justify-content-center">
        <kendo-dropdownlist [data]="options" (valueChange)="valueChange($event)" defaultItem="Location"
            [itemDisabled]="itemDisabled"></kendo-dropdownlist>
    </div>
    <div class="py-2">
        <button class="report-header-action">
            <svg-icon name="checklist-no-border" viewBox="0 0 18 10" svgClass="icon"></svg-icon>
            Manage Reports
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action">
            <svg-icon name="save" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
            Save Report
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action">
            <svg-icon name="filter" viewBox="0 0 18 12" svgClass="icon"></svg-icon>
            Filter
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action">
            <svg-icon name="export" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
            Export
        </button>
    </div>
</div>