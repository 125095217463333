import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SubSink } from 'subsink';
import { combineLatest, startWith } from 'rxjs';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { TripMetric } from '../../../../../constants/trip-metric';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { DashboardTripTimeFormService } from '../services/dashboard-trip-time-form.service';
import { QueryObserverResult } from '@tanstack/query-core';

@Component({
  selector: 'app-average-historical-trip-time-table-charts',
  templateUrl: './average-historical-trip-time-table-charts.component.html',
  styleUrl: './average-historical-trip-time-table-charts.component.css'
})
export class AverageHistoricalTripTimeTableChartsComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(public tripTimeFormService: DashboardTripTimeFormService,
              private dashboardFormService: DashboardReportFormService,
              public formattingService: FormattingService) {
    
  }

  ngOnInit(): void {
    this.sub.sink = combineLatest([
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.tripTimeFormService.tripMetricControl.valueChanges.pipe(startWith(TripMetric.TripTime)),
      this.tripTimeFormService.yearsFormControl.valueChanges.pipe(startWith([])),
      this.tripTimeFormService.monthsFormControl.valueChanges.pipe(startWith([]))
    ]).subscribe(() => {
        this.tripTimeFormService.queryTripHistory();
    });

    this.tripTimeFormService.queryTripHistory();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  pageChange(event: PageChangeEvent) {
    this.tripTimeFormService.tripHistoryPaging.skip = event.skip;
    this.tripTimeFormService.tripHistoryPaging.take = event.take;

    this.tripTimeFormService.queryTripHistory();
  }

  getWidthOfChartColumn(result: QueryObserverResult<GridDataResult, Error>) {
    return result.data?.data[0].columns.length * 75;
  }
}
