import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Report } from "../models/report/report";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { ReportDataService } from "../submodules/filters/services/report-data.service";

export const reportResolver: ResolveFn<Report[]> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, reportDataService: ReportDataService = inject(ReportDataService)): Observable<Report[]> => 
        reportDataService.getAllForType(route.data["dashboardType"]).result$.pipe(map((result) => {
        return result.data ?? [];
    }));