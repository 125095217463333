import { Component } from '@angular/core';

@Component({
  selector: 'app-schedule-tile',
  // standalone: true,
  // imports: [],
  templateUrl: './schedule-tile.component.html',
  styleUrl: './schedule-tile.component.scss'
})
export class ScheduleTileComponent {

  data = [{
    dateMonth: 'Monday,January 1', status: 'Completed', info: [{ label: 'Empty on Hand', value: '5', selected: false }, { label: 'In-Transit', value: '20', selected: false }, { label: 'Loaded Dwelling', value: '20', selected: false }, { label: 'Loaded Today', value: '20', selected: false }, { label: 'Ready to Ship', value: '20', selected: false }, { label: 'Daily Storage', value: '20', selected: true }, { label: 'Rollover Orders(Day-1)', value: '20', selected: false }, { label: 'Forecasted', value: '20', selected: false }, { label: 'Released', value: '20', selected: false }, { label: 'Orders Missed', value: '20', selected: false }]
  }]

}
