<div class="d-flex flex-column h-100 position-relative">
    @if (formService.tripsQueryResult$ | async; as result;) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    <kendo-grid #dwellAverageStateProvinceTable *ngIf="!result.isLoading"
    [data]="result?.data ?? []" [scrollable]="'scrollable'"
        [pageSize]="10" [height]="520">
        <kendo-grid-column title="Origin" [width]="90">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{formattingService.formatStation(dataItem.originCity, dataItem.originStateName)}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Destination" [width]="90">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{formattingService.formatStation(dataItem.destinationCity, dataItem.destinationStateName)}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tripCount" title="Trip Count" [width]="60" format="# Trips"></kendo-grid-column>
        <kendo-grid-column field="tripTimeAverage" format="#.00 Days" title="Average Trip Time"
            [width]="60"></kendo-grid-column>
    </kendo-grid>
    }
</div>