import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { ForecastComponent } from './routes/forecast/forecast.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [authGuardFn]
  },
  {
    path: "forecast", component: ForecastComponent,
    canActivate: [authGuardFn]
  },
  {
    path: "admin",
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuardFn]
  },
  {
    path: 'forecast-dashboard',
    loadChildren: () =>
      import('./modules/forecast-dashboard/forecast-dashboard.module').then((m) => m.ForecastDashboardModule),
  },
  { path: "**", redirectTo: "dashboard" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
