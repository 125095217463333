"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortBy = void 0;
/**
 * Sorts an array of items by a given property keys. {@link https://github.com/bameyrick/sort-by-typescript#readme | View documentation}
 */
function sortBy() {
    var properties = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        properties[_i] = arguments[_i];
    }
    return function (obj1, obj2) {
        var props = properties.filter(function (prop) { return typeof prop === 'string'; });
        var map = properties.filter(function (prop) { return typeof prop === 'function'; })[0];
        var i = 0;
        var result = 0;
        if (props.length) {
            props = props.map(function (prop) { return (prop.replace('-', '').length ? prop : '-^'); });
        }
        else {
            props = ['^'];
        }
        var numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = sort(props[i], map)(obj1, obj2);
            i++;
        }
        return result;
    };
}
exports.sortBy = sortBy;
/**
 * Does the sort calculation for an item
 */
function sort(property, map) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    if (property[property.length - 1] === '^') {
        property = property.substr(0, property.length - 1);
        map = function (_key, value) { return (typeof value === 'string' ? value.toLowerCase() : value); };
    }
    var apply;
    if (map) {
        apply = map;
    }
    else {
        apply = function (_key, value) { return value; };
    }
    return function (a, b) {
        var result = 0;
        var mappedA = cast(apply(property, property ? objectPath(a, property) : a));
        var mappedB = cast(apply(property, property ? objectPath(b, property) : b));
        if (typeof mappedA === 'string') {
            result = mappedA.localeCompare(mappedB);
        }
        else if (mappedA < mappedB) {
            result = -1;
        }
        else if (mappedA > mappedB) {
            result = 1;
        }
        return result * sortOrder;
    };
}
/**
 * Navigates to the part of the object using the path provided
 */
function objectPath(object, path) {
    var pathParts = path.split('.');
    var result = object;
    pathParts.forEach(function (part) {
        result = isNullOrUndefined(result[part]) ? '' : result[part];
    });
    return result;
}
/**
 * Takes a value and casts it for more effective sorting.
 */
function cast(value) {
    // If the value is a number as a string, cast back to an actual number to sort
    if (typeof value === 'string' && !!value.trim().length && !isNaN(value)) {
        return parseFloat(value);
    }
    return value;
}
function isNullOrUndefined(value) {
    return value === null || value === undefined;
}
