<kendo-grid [railPerformanceBindingDirective]="customerDataService" [skip]="0" [pageSize]="10" [pageable]="true"
    [filterable]="true" [filter]="filters" (edit)="editHandler($event)">
    <ng-template kendoGridToolbarTemplate position="top">
        <kendo-grid-spacer></kendo-grid-spacer>
        <button (click)="createNewCustomerClick()" kendoGridAddCommand>Create New Customer</button>
    </ng-template>
    <kendo-grid-column field="name" title="Name" [width]="400" [filterable]="false"></kendo-grid-column>
    <kendo-grid-column field="isActive" title="Active" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.isActive ? 'Yes' : 'No' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Primary Contact" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.primaryContactName }}
            {{ dataItem.primaryContactEmail }}
            {{ dataItem.primaryContactPhone }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Access" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.hasYardManagementAccess ? 'Yard Management' : '' }}
            {{ dataItem.hasShipmentManagementAccess ? 'Shipment Management' : '' }}
            {{ dataItem.hasDataverseAccess ? 'Dataverse' : '' }}
            {{ dataItem.hasCustomerApiAccess ? 'Customer API' : '' }}
            {{ dataItem.hasWaybillManagementAccess ? 'Waybill Management' : '' }}
            {{ dataItem.hasRailPerformanceAccess ? 'Rail Performance' : '' }}
            {{ dataItem.hasForecastDemandAccess ? 'Forecast Demand' : '' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createdOn" title="Created On" format="g" [filterable]="false"></kendo-grid-column>
    <kendo-grid-column field="lastModified" title="Last Modified" format="g" [filterable]="false"></kendo-grid-column>
    <kendo-grid-command-column>
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand class="btn btn-action-primary">Edit</button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
<kendo-dialog [title]="modalWindowTitle" *ngIf="isCreating || isUpdating" (close)="closeCustomerModal()"
    [minWidth]="400" [width]="900">
    <app-customer-edit></app-customer-edit>
</kendo-dialog>