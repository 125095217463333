import { Component, OnDestroy } from '@angular/core';
import { PrefetchService } from './modules/shared-rail-performance/services/prefetch.service';
import { SubSink } from 'subsink';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnDestroy {
  private sub = new SubSink();

  constructor(private prefetchService: PrefetchService, private authService: AuthService) {
    this.sub.sink = this.prefetchService.prefetch().subscribe();
  }

  get isLoading$(): Observable<boolean> {
    return this.authService.isLoading$;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  
  title = 'Rail Performance';
}
