import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, startWith } from 'rxjs';
import { SubSink } from 'subsink';
import { CycleMetrics } from '../../../../../constants/cycle-metric';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { DashboardCycleTimeFormService } from '../../cycle/services/dashboard-cycle-time-form.service';
import { DashboardTripTimeFormService } from '../services/dashboard-trip-time-form.service';
import { TripMetrics } from '../../../../../constants/trip-metric';
import { DashboardCycleFormService } from '../../services/dashboard-cycle-form.service';

@Component({
  selector: 'app-trip-dashboard-tab',
  templateUrl: './trip-dashboard-tab.component.html',
  styleUrl: './trip-dashboard-tab.component.scss',
})
export class TripDashboardTabComponent {
  public filterForm: FormGroup = this.tripTimeFormService.filterForm;
  public tripMetrics = TripMetrics;

  private sub = new SubSink();

  constructor(public tripTimeFormService: DashboardTripTimeFormService,
              private cycleTimeFormService: DashboardCycleTimeFormService,
              private dashboardFormService: DashboardReportFormService,
              private dashboardCycleFormService: DashboardCycleFormService
  ) {
    
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.dashboardCycleFormService.exportData();
    });

    this.sub.sink = combineLatest([
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))
    ]).subscribe(() => {
      this.tripTimeFormService.querySummaryData();
    });

    this.tripTimeFormService.querySummaryData();
  }

  get tripCountQueryResult$() {
    return this.tripTimeFormService.tripCountQueryResult$;
  } 

  get averageTripTimeQueryResult$() {
    return this.tripTimeFormService.averageTripTimeQueryResult$;
  } 

  getChangePercentText(current: number, previous: number) {
    if (!previous || previous === 0) {
      return '';
    }

    let change = Math.round(((100 * (current - previous)) / previous));

    if (change > 0) { 
      return `<img src=\"/assets/icons_reports/dashboard_uptrend.svg\"> &nbsp; up ${Math.abs(change).toFixed(0)}% since last month`; 
    }
    else if (change == 0) { 
      return `no change since last month`; 
    }
    else  { 
      return `<img src=\"/assets/icons_reports/dashboard_downtrend.svg\"> &nbsp; down ${Math.abs(change).toFixed(0)}% since last month`;
    }
  }

  tripMetricChanged(event: any) {
    let tripMetricIndex = event[0];
    let tripMetric = this.tripMetrics[tripMetricIndex];

    this.tripTimeFormService.tripMetricControl.setValue(tripMetric);
  }
}
