import { Component, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { SubSink } from 'subsink';
import { cloneDeep } from 'lodash';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';
import { VolumeDemandDto, VolumeDemandYearReport } from '../../../../../models/volume-demand-report';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-forecast-volume-demand-report',
  templateUrl: './forecast-volume-demand-report.component.html',
  styleUrl: './forecast-volume-demand-report.component.scss',
  providers: [PercentPipe]
})
export class ForecastVolumeDemandReportComponent implements AfterViewInit {
  private sub = new SubSink();
  isVolumeDemandReportByLoading$ = new BehaviorSubject<boolean>(true);

  private sub2 = new SubSink();
  isVolumeDemandReportByDonutsLoading$ = new BehaviorSubject<boolean>(true);

  fulfillmentData: VolumeDemandYearReport[] = [];
  width = "80%";
  height = "280";
  type = "msline";
  dataFormat = "json";
  dataSource: any;
  dataSource2: any;
  dataSource3: any;
  dataSource4: any;
  noData: boolean = true;

  reportTitle = "Volume Demand";

  @ViewChild('volumeDemandYearLineReport') childelement: ElementRef | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.chartWidth();
  }

  ngAfterViewInit() {
    this.chartWidth();
  }

  private chartWidth() {
    const child = this.childelement?.nativeElement;
    this.width = (Number(child.offsetWidth) - 100).toString();
  }

  ngOnInit(): void {
    this.sub.sink = this.kpiDataService.getVolumeDemandYearReport().subscribe((dwellKpi: VolumeDemandYearReport[]) => {
      this.loadVolumeDemandYearReport(dwellKpi);
      this.isVolumeDemandReportByLoading$.next(false);
    });

    this.sub2.sink = this.kpiDataService.getVolumeDemandReport().subscribe((dwellKpi: VolumeDemandDto) => {
      this.loadVolumeDemandReport(dwellKpi);
      this.isVolumeDemandReportByDonutsLoading$.next(false);
    });
  }

  loadVolumeDemandReport(dataset: VolumeDemandDto | null) {
    if (dataset == null) return;
    this.dataSource2 = this.setMonthlyVolumeDemandReport(dataset);
    this.dataSource3 = this.setcurrentYearReport(dataset);
    this.dataSource4 = this.setpreviousYearReport(dataset);
  }

  setMonthlyVolumeDemandReport(dataset: VolumeDemandDto) {
    var monthlyData = cloneDeep(this.donutDataSource);
    monthlyData.data[0].value = dataset.monthReport.fulFilled * 100;
    monthlyData.data[1].value = dataset.monthReport.unFulFilled * 100;
    monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText;
    monthlyData.chart.centerLabel = dataset.monthReport.labelText;
    if (monthlyData.data[0].value == 0 && monthlyData.data[1].value == 0) {
      monthlyData.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      monthlyData.chart.showpercentvalues = "0";
      monthlyData.chart.valuePosition = "none";
      monthlyData.chart.showToolTip = "0";
      monthlyData.chart.defaultcenterlabel = dataset.monthReport.labelText + " No Data";
      monthlyData.chart.centerLabel = dataset.monthReport.labelText + " No Data";
    }
    return monthlyData;
  }
  setcurrentYearReport(dataset: VolumeDemandDto) {
    var currentYearReport = cloneDeep(this.donutDataSource);
    currentYearReport.data[0].value = dataset.currentYearReport.fulFilled * 100;
    currentYearReport.data[1].value = dataset.currentYearReport.unFulFilled * 100;
    currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText;
    currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText;
    if (currentYearReport.data[0].value == 0 && currentYearReport.data[1].value == 0) {
      currentYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      currentYearReport.chart.showpercentvalues = "0";
      currentYearReport.chart.valuePosition = "none";
      currentYearReport.chart.showToolTip = "0";
      currentYearReport.chart.defaultcenterlabel = dataset.currentYearReport.labelText + " No Data";
      currentYearReport.chart.centerLabel = dataset.currentYearReport.labelText + " No Data";
    }
    return currentYearReport;
  }
  setpreviousYearReport(dataset: VolumeDemandDto) {
    var previousYearReport = cloneDeep(this.donutDataSource);
    previousYearReport.data[0].value = dataset.previousYearReport.fulFilled * 100;
    previousYearReport.data[1].value = dataset.previousYearReport.unFulFilled * 100;
    previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText;
    previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText;
    if (previousYearReport.data[0].value == 0 && previousYearReport.data[1].value == 0) {
      previousYearReport.data = [{ label: "No Data", value: 100, color: "#f0f0f0" }];
      previousYearReport.chart.showpercentvalues = "0";
      previousYearReport.chart.valuePosition = "none";
      previousYearReport.chart.showToolTip = "0";
      previousYearReport.chart.defaultcenterlabel = dataset.previousYearReport.labelText + " No Data";
      previousYearReport.chart.centerLabel = dataset.previousYearReport.labelText + " No Data";
    }
    return previousYearReport;
  }
  loadVolumeDemandYearReport(dataset: VolumeDemandYearReport[]) {
    if (dataset == null) return;
    this.fulfillmentData = dataset;
    this.dataSource2 = this.setVolumeDemandTableReport(dataset);
  }

  setVolumeDemandTableReport(dataset: VolumeDemandYearReport[]) {
    var monthlyData = cloneDeep(this.dataSource);
    this.mapReportData(dataset);
  }


  mapReportData(dataset: VolumeDemandYearReport[]) {
    var reportDataSource = cloneDeep(this.getVolumeDemandReportLineChart());
    let categories = [{ category: dataset.map(item => ({ label: item.monthName })) }];

    let fulfillmentList = dataset.map(item => ({ value: this.percentPipe.transform(item.fulfillment, '1.2-2') }));
    let minFulfillmentList = dataset.map(item => ({ value: this.percentPipe.transform(item.minFulfillment, '1.2-2') }));
    let maxFulfillmentList = dataset.map(item => ({ value: this.percentPipe.transform(item.maxFulfillment, '1.2-2') }));

    (reportDataSource.categories as { category: { label: string }[] }[]) = categories;

    reportDataSource.dataset.forEach((line: any) => {
      if (line.seriesname == "Fulfilled") {
        line.data = fulfillmentList;
      }
      if (line.seriesname == "Minimum") {
        line.data = minFulfillmentList;
      }
      if (line.seriesname == "Maximum") {
        line.data = maxFulfillmentList;
      }
    });   
    dataset.forEach((line: any) => {
      if (line.fulfillment > 0 || line.minFulfillment > 0 || line.maxFulfillment > 0) {
        this.noData = false;
      }
    });
    this.dataSource = reportDataSource;
  }

  constructor(private kpiDataService: KpiDataService,
    private percentPipe: PercentPipe
  ) {

  }

  donutDataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      numberSuffix: "%",
      showlegend: "0",
      showLabels: "0",
      defaultcenterlabel: "May 2024",
      useEllipsesWhenOverflow: true,
      labelDistance: "50",
      centerLabel: "May 2024",
      centerLabelFontSize: "14",
      centerLabelFontColor: "black",
      centerLabelBold: "1",
      showpercentvalues: "1",
      aligncaptionwithcanvas: "0",
      pieRadius: "100",
      doughnutRadius: "50",
      captionpadding: "0",
      decimals: "1",
      // label value styles
      valueFontColor: "#474747", // Change value font color
      patternBgColor: "#F44444",
      labelBorderRadius: 14,
      labelBgColor: "#FAFAFA", //#FDFDFD,
      labelFontSize: 14,
      valuePosition: "inside",
      valueBgColor: "#000000", // Background color for values (effectively works in tooltip)
      valueBgAlpha: "60", // Opacity for the background color
    },
    data: [
      { label: "Fulfilled", value: "88", color: "#1B365D", visible: "0" },
      { label: "Unfulfilled", value: "12", color: "#F44444" }
    ],

  };
  getVolumeDemandReportLineChart() {

    let lineChartData = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        showlegend: "0",
        numbersuffix: "%",
        drawcrossline: "1",
        drawAnchors: "0",
        plottooltext: "<b>$dataValue</b> of $seriesName",
        theme: "fusion"
      },
      categories: [],
      dataset: [
        {
          seriesname: "Fulfilled",
          color: "#1B365D",
          data: []
        },
        {
          seriesname: "Minimum",
          color: "#F24444",
          dashed: "1",
          data: []
        },
        {
          seriesname: "Maximum",
          color: "#F2D675",
          dashed: "1",
          data: []
        }
      ]
    }

    return lineChartData;
  }
}
