export const Role = {
    SuperAdministrator: 'Super Administrator',
    CustomerAdministrator: 'Customer Administrator',
    CustomerUser: 'Customer User',
};

export const Roles = [
    Role.SuperAdministrator,
    Role.CustomerAdministrator,
    Role.CustomerUser,
];