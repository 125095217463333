import { HttpEvent, HttpErrorResponse, HttpResponse, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export const dateHttpInterceptorFn: HttpInterceptorFn = (req, next) => {
    return next(req).pipe(
        tap({
            next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const body = event.body;
                convertToDate(body);
            }
            },
            error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                }
            }
            },
        }),
    );
}

export const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

export function convertToDate(body: any) {
    if (typeof body === 'object' && body !== null) {
        const casted = body as { [key: string]: any };

        for (const key of Object.keys(casted)) {
            const value = casted[key];

            if (isIso8601(value)) {
                casted[key] = new Date(value);
            } 
            else if (typeof value === 'object' && value !== null) {
                convertToDate(value);
            }
        }
    } else {
        return body;
    }
}

export function isIso8601(value: unknown): value is string {
    return typeof value === 'string' ? iso8601.test(value) : false;
}