import { Injectable } from "@angular/core";
import { OperatorFunction } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { DataSource } from "../../../../shared-rail-performance/models/charts/fusion-charts/data-source";
import { LocationFilters } from '../../../../shared-rail-performance/models/filters/location-filters';
import { DataQueryService } from "../../../../shared-rail-performance/services/data-query/data-query.service";
import { DataQueryServiceConfiguration } from "../../../../shared-rail-performance/services/data-query/data-query-service-configuration";

@Injectable({
    providedIn: 'root'
})
export class LocationSettingService extends DataQueryService {
    getAllCommoditites(filters?: LocationFilters) {
        return this.get(LocationSettingServiceConfiguration.Commodities);
    }
    getAllCustomers(filters?: LocationFilters) {
        return this.get(LocationSettingServiceConfiguration.Customers);
    }
}

class LocationSettingServiceConfiguration {
    static readonly Commodities = new DataQueryServiceConfiguration<string[]>(
        'Commodities',
        environment.apiUrl + 'Commodities'
    );
    static readonly Customers = new DataQueryServiceConfiguration<string[]>(
        'Customers',
        environment.apiUrl + 'Customers'
    );

}