import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardRailcarComponent } from "./components/dashboard-railcar.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardRailcarComponent,
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RailcarDashboardRoutingModule { }