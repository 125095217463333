<form class="k-form k-form-md p-3" [formGroup]="userForm">
    <fieldset class="k-form-fieldset">
        <div class="row">
            <div class="col-8">
                <legend class="k-form-legend mb-1">Customer Information</legend>
            </div>
            <div class="col-4">
                <legend class="k-form-legend mb-1">Access</legend>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="name" text="Name"></kendo-label>
                    <kendo-autocomplete [data]="registeredCustomers" #name formControlName="name" valueField="name"
                        [virtual]="true"></kendo-autocomplete>
                    <kendo-formerror *ngIf="userForm.controls.name.errors?.required">Name is
                        required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="customerId" text="Customer ID"></kendo-label>
                    <kendo-textbox #customerId formControlName="customerId"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="address" text="Address"></kendo-label>
                    <kendo-textbox formControlName="address" #address formControlName="address"></kendo-textbox>
                    <kendo-formerror>Address is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="city" text="City"></kendo-label>
                    <kendo-textbox formControlName="city" #city formControlName="city"></kendo-textbox>
                    <kendo-formerror>City is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="stateProvince" text="State/Province"></kendo-label>
                    <kendo-textbox formControlName="stateProvince" #stateProvince required></kendo-textbox>
                    <kendo-formerror>State/Province is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="postalCode" text="Postal Code"></kendo-label>
                    <kendo-textbox formControlName="postalCode" #postalCode required></kendo-textbox>
                    <kendo-formerror>Postal Code is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="phoneNumber" text="Phone Number"></kendo-label>
                    <kendo-textbox formControlName="phoneNumber" #phoneNumber required></kendo-textbox>
                    <kendo-formerror>Phone Number is required</kendo-formerror>
                </kendo-formfield>
            </div>
            <div class="col">
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="scac" text="SCAC"></kendo-label>
                    <kendo-textbox formControlName="scac" #scac required></kendo-textbox>
                    <kendo-formerror>SCAC is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="railforceGlobalAccountId"
                        text="Railforce Global Account Id"></kendo-label>
                    <kendo-textbox formControlName="railforceGlobalAccountId" #railforceGlobalAccountId
                        required></kendo-textbox>
                    <kendo-formerror>Railforce Global Account Id is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="railincFleet" text="Railinc Fleet"></kendo-label>
                    <kendo-textbox formControlName="railincFleet" #railincFleet required></kendo-textbox>
                    <kendo-formerror>Railinc Fleet is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="primaryContactName"
                        text="Primary Contact Name"></kendo-label>
                    <kendo-textbox formControlName="primaryContactName" #primaryContactName required></kendo-textbox>
                    <kendo-formerror>Primary Contact Name is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="primaryContactEmail"
                        text="Primary Contact Email"></kendo-label>
                    <kendo-textbox formControlName="primaryContactEmail" #primaryContactEmail required></kendo-textbox>
                    <kendo-formerror>Primary Contact Email is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label labelCssClass="k-form-label" [for]="primaryContactPhone"
                        text="Primary Contact Phone"></kendo-label>
                    <kendo-textbox formControlName="primaryContactPhone" #primaryContactPhone required></kendo-textbox>
                    <kendo-formerror>Primary Contact Phone is required</kendo-formerror>
                </kendo-formfield>
            </div>
            <div class="col">
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #isActive type="checkbox" kendoCheckBox formControlName="isActive" />
                        <kendo-label [for]="isActive" class="k-checkbox-label" text="Active"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasYardManagementAccess type="checkbox" kendoCheckBox
                            formControlName="hasYardManagementAccess" />
                        <kendo-label [for]="hasYardManagementAccess" class="k-checkbox-label"
                            text="Yard Management"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasShipmentManagementAccess type="checkbox" kendoCheckBox
                            formControlName="hasShipmentManagementAccess" />
                        <kendo-label [for]="hasShipmentManagementAccess" class="k-checkbox-label"
                            text="Shipment Management"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasDataverseAccess type="checkbox" kendoCheckBox formControlName="hasDataverseAccess" />
                        <kendo-label [for]="hasDataverseAccess" class="k-checkbox-label" text="Dataverse"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasCustomerApiAccess type="checkbox" kendoCheckBox
                            formControlName="hasCustomerApiAccess" />
                        <kendo-label [for]="hasCustomerApiAccess" class="k-checkbox-label"
                            text="Customer API"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasWaybillManagementAccess type="checkbox" kendoCheckBox
                            formControlName="hasWaybillManagementAccess" />
                        <kendo-label [for]="hasWaybillManagementAccess" class="k-checkbox-label"
                            text="Waybill Management"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasRailPerformanceAccess type="checkbox" kendoCheckBox
                            formControlName="hasRailPerformanceAccess" />
                        <kendo-label [for]="hasRailPerformanceAccess" class="k-checkbox-label"
                            text="Rail Performance"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasForecastDemandAccess type="checkbox" kendoCheckBox
                            formControlName="hasForecastDemandAccess" />
                        <kendo-label [for]="hasForecastDemandAccess" class="k-checkbox-label"
                            text="Forecast/Demand"></kendo-label>
                    </div>
                </kendo-formfield>
                <kendo-formfield>
                    <div class="k-checkbox-wrap">
                        <input #hasActivityBasedCostingAccess type="checkbox" kendoCheckBox
                            formControlName="hasActivityBasedCostingAccess" />
                        <kendo-label [for]="hasActivityBasedCostingAccess" class="k-checkbox-label"
                            text="Activity Based Costing"></kendo-label>
                    </div>
                </kendo-formfield>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <kendo-formfield showHints="always">
                    <kendo-label text="Customers"></kendo-label>
                    <kendo-multiselect [data]="registeredCustomers" formControlName="childCustomerIds" [virtual]="true"
                            valueField="userId" textField="name" [valuePrimitive]="true" [tagMapper]="tagMapper"></kendo-multiselect>
                    <kendo-formhint>Customers that this user can access.</kendo-formhint>
                </kendo-formfield>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="k-form-buttons d-flex justify-content-end">
                    <button class="btn btn-action-primary mt-3" type="button" (click)="saveUser()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </fieldset>
</form>