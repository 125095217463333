import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ScheduleTileComponent } from './components/schedule-tile/schedule-tile.component';
import { AngularSvgIconModule } from "angular-svg-icon";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../../../common/common-imports";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ScheduleReportComponent } from './components/schedule-report/schedule-report.component'
import { ScheduleInfoComponent } from './components/schedule-info/schedule-info.component'

@NgModule({
  declarations: [
    ScheduleComponent,
    ScheduleTileComponent,
    ScheduleReportComponent,
    ScheduleInfoComponent

  ],
  exports: [
    ScheduleComponent,
    ScheduleTileComponent,
    ScheduleReportComponent,
    ScheduleInfoComponent
  ],
  schemas: [],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    IconsModule,
    ...COMMON_PRIME_NG_IMPORTS,
    ...COMMON_KENDO_IMPORTS,
  ]
})
export class ScheduleModule { }
