import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AverageHistoricalTripTime } from '../../../../../../models/trip/average-historical-trip-time';
import { DataSource } from '../../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-average-historical-trip-time-chart',
  templateUrl: './average-historical-trip-time-chart.component.html',
  styleUrl: './average-historical-trip-time-chart.component.css',
})
export class AverageHistoricalTripTimeChartComponent {
  @Input() dataSource?: DataSource;
}
