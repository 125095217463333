import { HttpClient } from "@angular/common/http";
import { User } from "../models/user";
import { environment } from "../../../../environments/environment";
import { map, Observable, shareReplay } from "rxjs";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { DataService } from "../../shared-rail-performance/services/data/data.service";
import { SubSink } from "subsink";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUser$: Observable<User>;

    constructor(private httpClient: HttpClient) {
        this.currentUser$ = this.getCurrentUser();
    }

    getCurrentUser(): Observable<User> {
        if (!!this.currentUser$) {
            return this.currentUser$;
        }
        else {
            return this.httpClient.get<User>(`${environment.apiUrl}User`).pipe(shareReplay(1));
        }
    }

    isUserInRole(role: string): Observable<boolean> {
        return this.getCurrentUser().pipe(map(user => user?.customerUserRoles?.some(x => x.roleName === role)));
    }
}
