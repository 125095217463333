"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAlphaValue = exports.validateColorStops = exports.validateMinMaxValues = void 0;
function validateMinMaxValues(min, max) {
    if (min === max) {
        throw new Error('The minimum value cannot be equal to the maximum value.');
    }
    else if (min > max) {
        throw new Error('The minimum value must be less than the maximum value.');
    }
}
exports.validateMinMaxValues = validateMinMaxValues;
function validateColorStops(colorStops) {
    if (colorStops.length < 2) {
        throw new Error('At least two colors must be provided.');
    }
}
exports.validateColorStops = validateColorStops;
function validateAlphaValue(alpha) {
    if (alpha < 0 || alpha > 1) {
        throw new Error('The alpha value must be between 0 and 1.');
    }
}
exports.validateAlphaValue = validateAlphaValue;
