<div class="content-width">

    <div class="row">
        <div class="col">
            <div class="action-row d-flex flex-row justify-content-between">
                <div class="heading  ms-1 col-8">
                    <div class="row">
                        <label class="col-auto p-0">
                            Location Settings</label>
                        <div class="col-6 pb-2">
                            <kendo-dropdownlist [data]="options" (valueChange)="valueChange($event)"
                                defaultItem="Location" [itemDisabled]="itemDisabled"></kendo-dropdownlist>
                        </div>
                        <div class="col-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#206481"
                                class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg>
                            <button type="button" *ngIf="!opened" (click)="open()"
                                class="col-auto btn btn-link text-decoration-none text-primary me-1 fw-bold">CREATE
                                LOCATION</button>
                            <kendo-dialog *ngIf="opened" (close)="close()" [minWidth]="250" [width]="450">


                                <div class="d-flex flex-column border-bottom">
                                    <h4 class="fw-bold">CREATE LOCATION</h4>

                                    <p class="new-location">Create a new location.
                                    </p>
                                </div>
                                <kendo-dropdownlist [data]="options" [style.marginTop.rem]="1" [style.padding.rem]="0.4"
                                    (valueChange)="valueChange($event)" defaultItem="Select"
                                    [itemDisabled]="itemDisabled"></kendo-dropdownlist>


                                <kendo-textbox [style.width.rem]="22" [style.marginTop.rem]="1"
                                    [style.padding.rem]="0.4" placeholder="Enter" [clearButton]="false"
                                    fillMode="outline"></kendo-textbox>

                                <div class="action-btn mt-3">
                                    <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3"
                                        (click)="close()">Cancel</button>

                                    <button type="button"
                                        class="btn btn-action-primary btn-above-overlay ms-2 m-3">Save</button>

                                </div>
                            </kendo-dialog>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <button type="button" *ngIf="!openedClmData" (click)="openClmData()"
                        class="col-auto btn btn-link text-decoration-none text-primary me-1 fw-bold"><svg-icon
                            name="arrow-clockwise" viewBox="0 0 18 18" svgClass="icon"></svg-icon>UPDATE
                        FROM CLM DATA</button>

                    <kendo-dialog *ngIf="openedClmData" (close)="closeClmData()" [minWidth]="250" [width]="570"
                        [height]="500">
                        <div class="d-flex flex-column border-bottom">
                            <h4 class="fw-bold">UPDATE FROM CLM DATA</h4>

                            <p class="location-settings">Location settings will be auto populated from the latest
                                CLM data. Select all that apply.
                            </p>
                </div>
                        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                            <div class="d-flex flex-column">
                                <div *ngFor="let item of checkBoxListItem" class="p-3">
                                    <kendo-checkbox #checkedState [formControlName]="item.label"></kendo-checkbox>
                                    &nbsp;
                                    <kendo-label [style.font-color]="''" class="k-checkbox-label fs-5"
                                        [for]="checkedState" [text]="item.label"></kendo-label>
                                    <div class="label-text">{{item.text}}</div>
            </div>
        </div>

                            <div class="action-btn">
                                <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3"
                                    (click)="closeClmData()">Cancel</button>

                                <button type="submit" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                                    (click)="LoadLocationInfo()">Confirm</button>

    </div>
                        </form>
                    </kendo-dialog>

                </div>
            </div>
        </div>
    </div>
    <div class="row card" *ngIf="!showDefaultPage">
        <div class="col-12 p-0">
            <div class="row">
                <div class="col-6">
                    <button *ngIf="!showLocationEditPage" type="button"
                        class="btn btn-action-primary btn-above-overlay ms-2 m-3" (click)="showEditPage()">Edit</button>
                </div>
            </div>
            <div *ngFor="let data of locationData" class="row">
                <div class="col-12">
                    <div class="card border-light ms-2 m-3">
                        <div class="card-header border-light">
                            <h5>{{data.headingName}}</h5>
                            <span *ngIf="showLocationEditPage && data.headingName != 'LOCATION INFO' ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#206481"
                                    class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                </svg>

                                <button type="button"
                                    class="col-auto btn btn-link text-decoration-none text-primary me-1 fw-bold"
                                    (click)="addNewField(data.headingName)">
                                    {{data.headingName}}</button>
                            </span>

                        </div>
                        <div class="card-body" *ngIf="!showLocationEditPage">

                            <div *ngIf="data.content.length === 0;else content" class="no-info">
                                No Info
                            </div>
                            <ng-template #content>
                            <div class="row">
                                <div *ngFor="let content of data?.content" class="d-flex">
                                    <div *ngFor="let item of content" class="w-25">
                                        <label>{{item.label}}
                                                <p *ngIf="!isArray(item.value)" class="fw-bold"> {{item.value}}</p>
                                            <span *ngIf="isArray(item.value)" class="d-flex">
                                                <ng-container *ngFor="let value of item.value">
                                                    <p class="fw-bold"
                                                        *ngIf="value.numberOfSwitch && value.selected; else switch">
                                                        {{value.label}}
                                                        ({{value.numberOfSwitch}}) ,</p>
                                                    <ng-template #switch>
                                                            <p class="fw-bold" *ngIf="value.selected"> {{value.label}},
                                                            </p>
                                                    </ng-template>
                                                    <ng-container
                                                        *ngIf="!item.selectedValue && value.selected === undefined ">
                                                        <p>noraml test</p>
                                                        <p class="fw-bold">{{item.selectedValue}},</p>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="item.selectedValue">
                                                    <p class="fw-bold">{{item.selectedValue}},</p>
                                                </ng-container>

                                            </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            </ng-template>
                        </div>
                        <div class="card-body" *ngIf="showLocationEditPage">

                            <div class="row">
                                <div *ngFor="let content of data?.content;let contentIndex = index" class=" row">
                                    <ng-container *ngFor="let item of content;let itemIndex = index">
                                        <div *ngIf=" item.field==='input'" class=" col-3">
                                            <kendo-label class="k-form" [text]="item.label"> </kendo-label>

                                            <div>
                                                <kendo-textbox fillMode="flat" [style.width.%]="90"
                                                    [style.font-weight]="'bold'" [value]="item.value"
                                                    [clearButton]="false" placeholder="railsCard"
                                                    (valueChange)="onTextboxChange($event,data.headingName,itemIndex,contentIndex)">
                                                </kendo-textbox>
                                            </div>
                                        </div>
                                        <div *ngIf="item.field ==='selection'"
                                            [class]="item.label === 'Units'? 'col-2':'col-3'">
                                            <kendo-label class="k-form" [text]="item.label"> </kendo-label>
                                            <div>
                                                <kendo-combobox [data]="item.value" [value]="item.selectedValue"
                                                    [clearButton]="false" [style.font-weight]="'bold'" fillMode="flat"
                                                    (valueChange)=" onValueChange($event,data.headingName,itemIndex,contentIndex)"></kendo-combobox>
                                            </div>
                                        </div>
                                        <div *ngIf="item.field ==='multiselect'" class="col-3"
                                            [class]="item.label === 'Customers'? 'margin-right':''">
                                            <kendo-label class="k-form" [text]="item.label"> </kendo-label>
                                            <div>
                                                <kendo-multiselect [data]="item.value" [style.font-weight]="'bold'">
                                                </kendo-multiselect>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end period-chips-container"
                                            *ngIf="item.field ==='chip'" class="col-8">
                                            <kendo-label class="k-form" [text]="item.label"> </kendo-label><br />
                                            <div class="d-flex flex-column">
                                                <kendo-chiplist selection="multiple"
                                                    (selectedChange)="daysChanged($event,data.headingName,itemIndex, contentIndex)">
                                                    <ng-container *ngFor="let val of item.value">

                                                        <kendo-chip [selected]="val?.selected" rounded="full"
                                                            fillMode="outline" [label]="val.label"></kendo-chip>&nbsp;

                                                    </ng-container>

                                                </kendo-chiplist>

                                                <kendo-label *ngIf="item.showLabel" class="k-form mt-2"
                                                    text="# of switches">
                                                </kendo-label>
                                                <span class="d-flex gap-4">

                                                    <ng-container *ngFor="let val of item.value;let valueIndex=index">

                                                        <kendo-textbox *ngIf="val.numberOfSwitch>=0" fillMode="flat"
                                                            [style.width.px]="50" [value]="val.numberOfSwitch"
                                                            (valueChange)="onTextboxChange($event,data.headingName,itemIndex,contentIndex,valueIndex)"
                                                            [clearButton]="false" placeholder="railsCard">
                                                        </kendo-textbox>

                                                    </ng-container>
                                                </span>
                                            </div>
                                        </div>

                                    </ng-container>
                                    <div class="col-1 mt-3" (click)="removeItem(data.headingName, contentIndex)"
                                        *ngIf="data.headingName != 'LOCATION INFO' ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="action-btn" *ngIf="showLocationEditPage">


            <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                (click)="onSave()">Save</button>
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3" data-bs-toggle="modal"
                data-bs-target="#cancelModal">
                Cancel
            </button>


        </div>

    </div>

    <div class="empty-message" *ngIf="showDefaultPage">
        <div class="fw-bold">No Location Information</div>
        <div>Select a location from the top menu to edit settings.</div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="cancelModal" tabindex="-1" role="dialog" aria-labelledby="cancelModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="cancelModalLabel">Warning</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to remove the location?
                    Removing this will remove all the data for the location.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3"
                        data-bs-dismiss="modal" (click)="onCancel()">Close</button>
                    <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                        data-bs-dismiss="modal" (click)="onSave()">Save
                        changes</button>
                </div>
            </div>
        </div>
    </div>
</div>