import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CustomerFilters } from '../models/filters/customer-filters';
import { environment } from '../../../../environments/environment';
import { SubSink } from 'subsink';
import { combineLatest, Observable } from 'rxjs';
import { DataQueryService } from './data-query/data-query.service';
import { DataQueryServiceConfiguration } from './data-query/data-query-service-configuration';
import { PagedResult } from '../models/paging/paged-result';
import { Customer } from '../../admin/models/customer';
import { CustomerFilter } from '../../admin/models/customer-filter';

@Injectable({
  providedIn: 'root'
})
export class CustomerListDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([this.prefetchGet(CustomerListDataServiceConfiguration.AllCustomers)]);
  }

  getCustomers(filters?: CustomerFilters) {
    return this.get<PagedResult<CustomerFilter>>(CustomerListDataServiceConfiguration.AllCustomers);
  }
}

abstract class CustomerListDataServiceConfiguration {
  static readonly AllCustomers = new DataQueryServiceConfiguration<PagedResult<CustomerFilter>>(
      'customer-filters',
       environment.apiUrl + 'Customers/Filters'
  );
}

