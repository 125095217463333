import { CustomerFilter } from "../../../admin/models/customer-filter";

export class DashboardFilters {
  customers?: CustomerFilter[] = [];
  countries?: string[] = [];
  regions?: string[] = [];
  stations?: string[] = [];
  statesProvinces?: string[] = [];
  commodities?: string[] = [];
  loaded?: string[] = [];
}
