import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardCycleTimeComponent } from "./components/dashboard-cycle-time.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardCycleTimeComponent,
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CycleDashboardRoutingModule { }