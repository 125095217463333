import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForecastDashboardLandingComponent } from "./submodules/landing/components/forecast-dashboard-landing.component";
import { LocationSettingsComponent } from './submodules/location-settings/components/location-settings/location-settings.component';
import { ScheduleComponent } from './submodules/schedule/components/schedule/schedule.component';
import { ForecastSwitchingPerformanceReportComponent } from "./submodules/reports/forecast-reports/components/forecast-switching-performance-report/forecast-switching-performance-report.component";
import { ForecastFirstMileLastMileReportComponent } from "./submodules/reports/forecast-reports/components/forecast-first-mile-last-mile-report/forecast-first-mile-last-mile-report.component";
import { ForecastVolumeDemandReportComponent } from "./submodules/reports/forecast-reports/components/forecast-volume-demand-report/forecast-volume-demand-report.component";
import { ForecastDemandReportComponent } from "./submodules/reports/forecast-reports/components/forecast-demand-report/forecast-demand-report.component";

const routes: Routes = [
  {
    path: '',
    component: ForecastDashboardLandingComponent,
  },
  {
    path: 'schedule',
    component: ScheduleComponent,
  },
  {
    path: 'reports',

    children: [{
      path: 'switching-performance',
      component: ForecastSwitchingPerformanceReportComponent
    },
    {
      path: 'first-mile-last-mile',
      component: ForecastFirstMileLastMileReportComponent
    },
    {
      path: 'volume-demand',
      component: ForecastVolumeDemandReportComponent
    },
    {
      path: 'forecast-demand',
      component: ForecastDemandReportComponent
    }]
  },
  {
    path: 'location-settings',
    component: LocationSettingsComponent,
  },
  // {
  //   path: 'location-settings',
  //   loadChildren: () =>
  //     import('./submodules/location-settings/location-settings.module').then((m) => m.LocationSettingsModule),
  // },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ForecastDashboardRoutingModule { }