<div>
    <div class="tile-layout" *ngFor="let item of data">
        <div class="text-center tile-layout-heading">
            {{item.dateMonth}}
        </div>
        <div class="text-center">
            <span class=" tile-layout-status-color1">
                {{item.status}}
            </span>
        </div>
        <ng-container *ngFor="let data of item.info">
            <div class="label-container-div label-container-gray" [class]="data?.selected ? 'label-container-green':''">
                <span class="left">
                    {{data.label}}
                </span>
                <span class="right">
                    {{data.value}}
                </span>
            </div>

        </ng-container>
    </div>

</div>