<div class="performance-metric w-100">
    @if (isLoading) {
        <div class="placeholder-glow w-100">
            <div class="placeholder w-100"></div>
        </div>
    }
    @else if (isError) {
        <span>N/A</span>
    }
    @else if (!showIfZero && value === 0)
    {
        <span class="performance_label">&nbsp;</span>
        <span class="performance-value">&nbsp;</span>
        <span class="performance-uom">&nbsp;</span>
    }
    @else {
        <span class="performance_label" [title]="label">{{label}}</span>
        <span class="performance-value">{{valueToDisplay | number}}</span>
        <span class="performance-uom">{{unitOfMeasure}}</span>
    }
</div>