<div class="dashboard">
  <div class="dashboard-title">
    Active Performance
  </div>
  <div class="dashboard-divider"></div>
  <div class="dashboard-subtitle">
    Current Railcar Count Metrics Based On Latest Railcar Messages
  </div>
  <div class="performance-cards">
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="location-marker" svgClass="location-marker-icon" viewBox="0 0 15 20"></svg-icon>
        Railcar Count
      </div>
      @if (performanceQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card" *ngIf="showCommodity">
      <div class="performance-title">
        <svg-icon name="checklist" svgClass="checklist-icon" viewBox="0 0 18 18"></svg-icon>
        Commodity Count
      </div>
      @if (performanceCommodityQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric [label]="result.data?.commodity1Type" [value]="result.data?.commodity1Cars"
            unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"
            [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity2Type" [value]="result.data?.commodity2Cars"
            unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"
            [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity3Type" [value]="result.data?.commodity3Cars"
            unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"
            [isError]="result.isError"></app-performance-metric>
          <app-performance-metric [label]="result.data?.commodity4Type" [value]="result.data?.commodity4Cars"
            unitOfMeasure="Railcars" [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="empty-box" svgClass="empty-box-icon" viewBox="0 0 19 15"></svg-icon>
        Empty Railcars
      </div>
      @if (performanceEmptyQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          }
        }
    </div>
    <div class="performance-card">
      <div class="performance-title">
        <svg-icon name="closed-box" svgClass="closed-box-icon" viewBox="0 0 22 20"></svg-icon>
        Loaded Railcars
      </div>
      @if (performanceLoadedQueryResult$ | async; as result) {
        @if (result.isError) {
          <div class="query-error-message">There was an error loading your data</div>
        }
        @else {
          <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
          <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
            [isLoading]="result.isLoading" [isError]="result.isError"></app-performance-metric>
        }
      }
    </div>
  </div>
</div>
<div class="content-width">
  <div class="content-width dashboard-cards">
    <div class="dashboard">
      <div class="dashboard-title d-flex justify-content-between">
        <span>Historical Performance</span>
        <svg-icon *ngIf="showHistoricalPerformanceToolTip" name="info-circle" svgClass="info-circle" title="Filters for In-Motion and Loaded/Empty do not apply to data in Historical Performance"></svg-icon>
      </div>
      <div class="dashboard-divider"></div>
      <div class="muted">
        Historical Railcar Count Metrics
      </div>
      <app-date-filter [startYear]="2021" [formGroup]="dashboardRailcarFormService.filterForm"
        yearsFormControlName="years" monthsFormControlName="months"></app-date-filter>
      <app-dashboard-railcar-graph></app-dashboard-railcar-graph>
      <app-dashboard-railcar-table></app-dashboard-railcar-table>
    </div>
  </div>
</div>