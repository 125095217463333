import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExpansionPanelComponent } from "@progress/kendo-angular-layout";
import { SelectionRange } from "@progress/kendo-angular-dateinputs";
import { Report } from '../../../../../../dashboard/models/report/report';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, filter, buffer, map, catchError, of } from "rxjs";
import { QueryObserverResult } from '@tanstack/query-core';
import { SubSink } from "subsink";
import { ReportDataService } from '../../../../../../dashboard/submodules/filters/services/report-data.service';
import { ForecastReportFormService } from '../../../../../services/forecast-report-form.service';
import { ForecastReportService } from '../../../../../services/forecast-report.service';
import { UserService } from '../../../../../../admin/services/user.service';
import { User } from '../../../../../../admin/models/user';
import { ForecastDataExportService } from '../../../../../services/forecast-data-export.service';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';

@Component({
  selector: 'app-forecast-demand-report-filters',
  templateUrl: './forecast-demand-report-filters.component.html',
  styleUrl: './forecast-demand-report-filters.component.scss'
})
export class ForecastDemandReportFiltersComponent implements OnInit {

  @Input() reportTitle: string = '';

  reportName: string = '';
  @ViewChild('filterExpansionPanel') filterExpansionPanel?: ExpansionPanelComponent;

  reportdata: any;
  originalReportdata: any;
  reportQueryResult$?: Observable<QueryObserverResult<Report[], Error>>;
  public queryResult$?: Observable<QueryObserverResult<string[], Error>>;
  isLoadingUserInformation$ = new BehaviorSubject<boolean>(false);
  carrierDatasource: any[] = [];
  currentEdittingRecord: any;
  user?: User;
  selectedReport: any = { id: 0, name: "Select Report" };
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
    private reportFormService: ForecastReportFormService,
    private forecastReportService: ForecastReportService,
    private exportService: ForecastDataExportService,
    private kpiDataService: KpiDataService,
    private userService: UserService,
    private reportDataService: ReportDataService) {
  }

  ngOnInit(): void {
    this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
    this.queryResult$ = this.forecastReportService.getAllCarriers().result$;
    this.sub.sink = this.queryResult$.subscribe((result) => {
      if (!result.isLoading) {
        result.data?.forEach((carrier: any) => {
          if (carrier.carrier) {
            this.carrierDatasource.push(carrier.carrier);
          }
        });
      }
    });
    this.hideAllReportEditMenu();
    this.isLoadingUserInformation$.next(true);
    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.isLoadingUserInformation$.next(false);
    });
  }

  hideAllReportEditMenu() {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      this.reportdata = result.data;
      this.options = [{ id: 0, name: "Select Report" }];
      for (let report of this.reportdata as any[]) {
        this.options.push({ id: report.id, name: report.name });
        report.show = false;
      }
      this.originalReportdata = cloneDeep(this.reportdata);
    });
  }

  exportReportData() {
    switch (this.reportTitle) {
      case 'Accuracy Fulfillment':
        this.exportFirstMileLastMileYearReport();
        break;
      case 'Switching Performance':
        this.exportSwitchingPerformanceYearReport();
        break;
      case 'Volume Demand':
        this.exportVolumeDemandYearReport();
        break;
    }
  }

  exportVolumeDemandYearReport() {
    this.sub.sink = this.kpiDataService.getVolumeDemandYearReport().subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Fulfillment', key: 'fulfillment' },
          { header: 'Max Fulfillment', key: 'maxFulfillment' },
          { header: 'Min Fulfillment', key: 'minFulfillment' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  exportSwitchingPerformanceYearReport() {
    this.sub.sink = this.kpiDataService.getSwitchingPerformanceYearReport().subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Performed as Planned', key: 'performedAsPlanned' },
          { header: 'Inaccurate', key: 'inaccurate' },
          { header: 'Missed', key: 'missed' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  exportFirstMileLastMileYearReport() {
    this.sub.sink = this.kpiDataService.GetFirstMileLastMileYearReport().subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Fulfillment', key: 'fulfillment' },
          { header: 'Max Fulfillment', key: 'maxFulfillment' },
          { header: 'Min Fulfillment', key: 'minFulfillment' },
          { header: 'Accuracy', key: 'accuracy' },
          { header: 'Max Accuracy', key: 'maxAccuracy' },
          { header: 'Min Accuracy', key: 'minAccuracy' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  get userEmail(): string {
    return !!this.user ? this.user.email : '';
  }

  saveNewReport() {
    var filterobject = JSON.stringify({ rang: { start: this.range.start, end: this.range.end }, carrier: this.SelectedCarrier });
    this.sub.sink = this.reportFormService.saveNewReport(filterobject, this.reportName, this.reportTitle, this.userEmail).pipe(
      map((result: any) => {
        return true;
      }),
      catchError((err: boolean) => of(false))
    ).subscribe((success: boolean) => {
      if (success) {
        this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
        this.hideAllReportEditMenu();
      }
      else {
      }
    });
  }

  public range: { start: Date | null, end: Date | null } = { start: null, end: null };

  isFiltered: boolean = false;
  openSaveReportDialog: boolean = false;
  SelectedCarrier: string = "Select";
  options: any[] = [{ id: 0, name: "Select Report" }];

  reportNameDisabled(itemArgs: { dataItem: any; index: number }) {
    return (itemArgs.dataItem.id == null || itemArgs.dataItem.id <= 0);
  }

  valueChange(event: any) {
    if (event != null && event.id != 0) {
      this.reportdata.forEach((item: any) => {
        if (item.id === event.id) {
          var x = JSON.parse(item.filters);
          this.SelectedCarrier = x.carrier;
          this.range.start = new Date(x.rang.start);
          this.range.end = new Date(x.rang.end);
        }
      });
    }
    else {
      this.SelectedCarrier = "Select";
      this.range.start = null;
      this.range.end = null;
    }
  }

  public closeReport(): void {
    this.openSaveReportDialog = false;
  }

  onSave() {
    this.openSaveReportDialog = false;
  }

  saveReports() {
    this.saveNewReport();
    this.opened = false;
    this.openSaveReportDialog = false;
    this.showFilter = false;
    this.SelectedCarrier = "Select";
    this.reportName = "";
    this.range.start = null;
    this.range.end = null
    this.isFiltered = false;
  }

  updateReports() {
    var editedItem: any;
    this.reportdata.forEach((item: any) => {
      if (item.editRecord === true) {
        editedItem = item;
      }
    });
    this.sub.sink = this.reportFormService.saveEditingReport(editedItem.filters, editedItem.name, this.reportTitle, editedItem.id, editedItem.createdBy).pipe(
      map((result: any) => {
        return true;
      }),
      catchError((err: boolean) => of(false))
    ).subscribe((success: boolean) => {
      if (success) {
        this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
        this.hideAllReportEditMenu();
      }
    });
    this.opened = false;
    this.openSaveReportDialog = false;
  }

  get showHideManageReportData() {
    return this.reportdata && this.reportdata.length > 0;
  }

  get filteredReportData() {
    return this.reportdata?.filter((item: any) => !item.deleteRecord);
  }

  editReportRecord(rowData: any) {
    this.reportdata.forEach((item: any) => {
      if (item.editRecord === true) {
        item.name = this.currentEdittingRecord.name;
      }
      item.editRecord = false;
    });
    this.currentEdittingRecord = JSON.parse(JSON.stringify(rowData));
    rowData.show = false;
    rowData.editRecord = true;
  }

  get enableManageReportSave(): boolean {
    return !this.reportdata?.some((item: any) => (item.editRecord === true || item.deleteRecord === true));
  }

  deleteReportRecord(rowData: any) {
    rowData.show = false;
    rowData.deleteRecord = true;
    this.confirmDeleteClick(rowData.id);
  }

  confirmDeleteClick(id: any) {
    this.sub.sink = this.reportFormService.deleteReport(id).subscribe(() => {
    });
  }

  public openReportSaveDialog(): void {
    this.openSaveReportDialog = true;
  }

  toggleReportListEditMenu(reportToOpen: any) {
    for (let report of this.reportdata as any[]) {
      if (report.id != reportToOpen.id) {
        report.show = false;
      }
    }
    reportToOpen.show = !reportToOpen.show;
  }

  carrierSelectionChange(event: any) {

  }

  get enableSaveReport(): boolean {
    return !this.isFiltered;
  }

  get enableSaveButton(): boolean {
    return !(this.carrierHavingValue() || this.dateRangeHavingValue());
  }

  carrierHavingValue(): boolean {
    return !(this.SelectedCarrier == null || this.SelectedCarrier == "Select Carrier");
  }

  dateRangeHavingValue(): boolean {
    return (this.range.start != null && this.range.end != null);
  }

  applyFilters() {
    this.isFiltered = true;
    this.toggleFilterVisibility();
  }

  clearFilters() {
    this.SelectedCarrier = "Select Carrier";
    this.range.start = null;
    this.range.end = null

    this.applyFilters();
    this.isFiltered = false;
  }

  public opened = false;
  public close(): void {
    this.reportdata = cloneDeep(this.originalReportdata);
    this.opened = false;
  }

  public open(): void {

    this.opened = true;
  }

  showFilter: boolean = false;
  toggleFilterVisibility() {
    this.showFilter = !this.showFilter;
    if (this.filterExpansionPanel?.expanded != this.showFilter) {
      this.filterExpansionPanel?.toggle();
    }
  }

}
