<div class="content-width action-row d-flex flex-row" #reportActions>
    <div class="heading py-2 ms-1">
        {{this.dashboardReportFormService.dashboardType}}
    </div>
    <div class="py-2 report-list-container d-flex justify-content-center"
        [formGroup]="this.dashboardReportFormService.form">
        @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
        <kendo-dropdownlist #reportDropDown [data]="result.data ?? []" [itemDisabled]="reportNameDisabled"
            [defaultItem]="placeholderReport" formControlName="selectedReport" fillMode="none" textField="name"
            valueField="id"></kendo-dropdownlist>
        }
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="manageReportsClick()">
            <svg-icon name="checklist-no-border" viewBox="0 0 18 10" svgClass="icon"></svg-icon>
            Manage Reports
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="saveReportClick()"
            [disabled]="!(dashboardReportFormService.isSaveEnabled$ | async) || !!dashboardReportFormService.pendingEditReport">
            <svg-icon name="save" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
            Save Report
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="filterButtonClick()">
            <svg-icon name="filter" viewBox="0 0 18 12" svgClass="icon"></svg-icon>
            Filter
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="exportDataClick()">
            <svg-icon name="export" viewBox="0 0 18 18" svgClass="icon" *ngIf="!(isExporting$ | async)"></svg-icon>
            <div class="spinner-border spinner-border-small" role="status" *ngIf="isExporting$ | async"></div>
            Export
        </button>
    </div>
</div>
<kendo-dialog *ngIf="manageReportWindowOpen$ | async" [minWidth]="250" [width]="550" [minHeight]="350" [height]="500"
    (close)="closeManageReportWindow()">
    @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    }
    <div [formGroup]="dashboardReportFormService.form" class="h-75">
        <div *ngIf="!dashboardReportFormService.pendingDeleteReport" class="dialog-padding h-75">
            <span class="modal-header">Manage Reports</span>
            <span class="muted">Edit existing reports.</span>
            <div class="divider"></div>
            <br />
            @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
            <kendo-grid [data]="result.data ?? []" [scrollable]="'none'">
                <kendo-grid-column title="Actions" [width]="85" [headerClass]="'grid-header'" [class]="'grid-cell'">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <button #editPopup class="ellipsis-button"
                            (click)="toggleReportListEditMenuClick(dataItem)">&nbsp;</button>
                        <kendo-popup [anchor]="editPopup" (anchorViewportLeave)="dataItem.show = false"
                            *ngIf="dataItem.show">
                            <button class="popup-button" (click)="editReportClick(dataItem)">
                                <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                </svg>
                                Edit
                            </button>
                            <button class="popup-button" (click)="deleteReportClick(dataItem)">
                                <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                </svg>
                                Delete
                            </button>
                            <button class="popup-button" (click)="loadReportAndExportData(dataItem)">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="#287DA1"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z" />
                                </svg>
                                Export
                            </button>
                        </kendo-popup>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name" title="Report Name" [headerClass]="'grid-header'"
                    [class]="'grid-cell'"></kendo-grid-column>
                <kendo-grid-messages noRecords="  "></kendo-grid-messages>
            </kendo-grid>
            }
            <br />
            <br />
            <div class="action-group">
                <button type="button" class="btn btn-action-secondary btn-above-overlay"
                    (click)="closeManageReportWindow()">Close</button>
            </div>
        </div>
        <div *ngIf="!!dashboardReportFormService.pendingDeleteReport"
            class="d-flex flex-column justify-content-center align-items-center h-100">
            <app-spinner-loader *ngIf="isDeleting$ | async" [showOverlay]="true"></app-spinner-loader>
            <img class="warning-icon mb-2" src="./../../../../../../assets/icons/warning.svg">
            <span class="warning-title mb-4">Delete Report</span>
            <span class="warning-text mb-4">Are you sure you want to permanently delete this report template?</span>
            <div class="d-flex justify-content-between w-75">
                <button class="btn btn-action-secondary" (click)="cancelDeleteClick()"
                    [disabled]="isDeleting$ | async">Cancel</button>
                <button class="btn btn-action-primary" (click)="confirmDeleteClick()"
                    [disabled]="isDeleting$ | async">Delete</button>
            </div>
        </div>
    </div>
</kendo-dialog>
<kendo-dialog *ngIf="saveReportWindowOpen$ | async" [minWidth]="250" [width]="550" [minHeight]="350"
    (close)="closeSaveReportWindow()">
    <app-spinner-loader *ngIf="isSaving$ | async" [showOverlay]="true"></app-spinner-loader>
    <div [formGroup]="dashboardReportFormService.form" class="dialog-padding">
        <span class="modal-header">Save Report</span>
        <span class="muted">Save the current report view, filters and settings.</span>
        <div class="divider"></div>
        <br />
        <kendo-formfield showHints="always" showErrors="always">
            <kendo-label text="Report Name">
                <kendo-textbox placeholder="Enter Name" formControlName="name"></kendo-textbox>
                <kendo-formerror
                    *ngIf="dashboardReportFormService.nameFormControl.touched && dashboardReportFormService.nameFormControl.hasError('required')">Name
                    is
                    required.</kendo-formerror>
            </kendo-label>
        </kendo-formfield>
        <br />
        <br />
        <div class="action-group mt-5">
            <button type="button" class="btn btn-action-primary" [disabled]="isSaving$ | async"
                (click)="saveClick()">Save</button>
            <button type="button" class="btn btn-action-secondary" [disabled]="isSaving$ | async"
                (click)="closeSaveReportWindow()">Cancel</button>
        </div>
    </div>
</kendo-dialog>
<ng-template #reportEditNotification>
    <app-report-edit-notification
        [reportNotificationRef]="dashboardReportFormService.editNotificationRef"></app-report-edit-notification>
</ng-template>
<app-dashboard-filters [showFilters$]="dashboardReportFormService.showFilters$"></app-dashboard-filters>
<div class="content-width">
    <router-outlet></router-outlet>
</div>
<kendo-dialog *ngIf="dashboardReportFormService.showExportDataLoading$ | async" [minWidth]="250" [width]="550"
    [minHeight]="250">
    <div class="d-flex align-items-center justify-content-center flex-column fs-5 h-100">
        <img class="warning-icon" src="./../../../../../../assets/icons/access-time.svg">
        <span class="warning-text my-4">Please wait while we load your data for export</span>
        <div class="spinner-border" role="status">
        </div>
    </div>
</kendo-dialog>