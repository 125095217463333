<div class="content-width">
    <app-schedule-report></app-schedule-report>
    <app-schedule-info (emitViewModeEvent)="onEmitEventViewMode($event)"></app-schedule-info>

    <div [ngSwitch]="viewMode">
        <div *ngSwitchCase="'tiles'">
            <app-schedule-tile></app-schedule-tile>
        </div>
        <div *ngSwitchCase="'table'">
            table work
        </div>
    </div>
</div>