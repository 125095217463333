<div>
    <!-- @if (queryResult$ | async; as result) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    <fusioncharts type="mscombi2d" [dataSource]="result.data ?? {}"></fusioncharts>
    } -->
    <div>
        <div class="legend">
            <span class="dot-planned"></span>
            <span>Performed as Planned</span>
            &nbsp;
            &nbsp;
            <span class="dot-inaccurate"></span>
            <span>Inaccurate</span>
            &nbsp;
            &nbsp;
            <span class="dot-missed"></span>
            <span>Missed</span>
        </div>
        <div class="d-flex justify-content-around">
            @if (dataSource2) {
            <fusioncharts type="doughnut2d" width="{{donutWidth}}" height="{{donutHeight}}" [dataSource]="dataSource2">
            </fusioncharts>
            }

            @if (dataSource3) {
            <fusioncharts type="doughnut2d" width="{{donutWidth}}" height="{{donutHeight}}" [dataSource]="dataSource3">
            </fusioncharts>
            }

            @if (dataSource4) {
            <fusioncharts type="doughnut2d" width="{{donutWidth}}" height="{{donutHeight}}" [dataSource]="dataSource4">
            </fusioncharts>
            }
        </div>
    </div>
</div>