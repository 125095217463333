'use strict';
const validators_1 = require("./validators");
function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}
function rgbaToHex(color) {
    const r = Math.floor(color.r * color.a);
    const g = Math.floor(color.g * color.a);
    const b = Math.floor(color.b * color.a);
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}
class Color {
    constructor(r, g, b, a = 1) {
        validators_1.validateAlphaValue(a);
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }
    toRGBString() {
        return `rgb(${Math.floor(this.r * this.a)},${Math.floor(this.g * this.a)},${Math.floor(this.b * this.a)})`;
    }
    toRGBAString() {
        return `rgba(${this.r},${this.g},${this.b},${this.a})`;
    }
    toHexString() {
        return rgbaToHex(this);
    }
}
module.exports = Color;
