import { Component, EventEmitter, Output } from '@angular/core';
import { layoutSideBySideIcon, SVGIcon, bordersAllIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-schedule-info',
  templateUrl: './schedule-info.component.html',
  styleUrl: './schedule-info.component.scss'
})
export class ScheduleInfoComponent {

  public sideIcon: SVGIcon = layoutSideBySideIcon;
  public bordersIcon: SVGIcon = bordersAllIcon;

  @Output() emitViewModeEvent = new EventEmitter<string>();

  locationInfo = [{ heading: 'Capacity', value: '100 railcars', icon: 'storage' },
  { heading: 'Forecasted', value: '178 cars/weeks', icon: 'line-chart' },
  { heading: 'Fulfilled', value: '98%/week', icon: 'box-fill' },
  { heading: 'Missed Orders', value: '15 orders/week', icon: 'box' },
  { heading: 'Average Cycle Time', value: '9 hours', icon: 'time-fill' }]

  onClickTilesView(viewMode: string) {
    this.emitViewModeEvent.emit(viewMode)

  }

  onClickTableView(viewMode: string) {
    this.emitViewModeEvent.emit(viewMode)
  }

}
