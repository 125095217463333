import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, startWith } from 'rxjs';
import { addDays } from 'date-fns';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { LocationTypes } from '../../../../constants/location-type';
import { GridColumn } from '../../../../../shared-rail-performance/models/grid-column';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../../layout/services/dashboard-report-form.service';
import { DashboardRailcarFormService } from '../../services/dashboard-railcar-form.service';

@Component({
  selector: 'app-dashboard-railcar-table',
  templateUrl: './dashboard-railcar-table.component.html',
  styleUrl: './dashboard-railcar-table.component.css',
})
export class DashboardRailcarTableComponent implements OnInit, OnDestroy {
  public addDays = addDays;

  public locationTypes = LocationTypes;

  private sub = new SubSink();

  constructor(public formService: DashboardRailcarFormService,
    private dashboardFormService: DashboardReportFormService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.formService.loadStateProvinceColumns();
    this.formService.queryTableData();
    
    this.sub.sink = combineLatest([
      this.formService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.formService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.formService.locationTypeControl.valueChanges.pipe(startWith(undefined)),
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.formService.pagingState$.pipe(startWith({}))]).subscribe(() => {
        this.formService.queryTableData();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get railcarVolumeHistoryByWeekLocationQueryResult$() {
    return this.formService.railcarVolumeHistoryByWeekLocationQueryResult$;
  }

  public pageChange(state: PageChangeEvent): void {
    this.formService.pagingState$.next(state);
  }

  locationTypeChange(event: any) {
    let locationTypeIndex = event[0];
    let locationType = LocationTypes[locationTypeIndex];

    this.formService.locationTypeControl.setValue(locationType);
    this.changeDetectorRef.detectChanges();
  }

  getColumnWidth(column: GridColumn) {
    return Math.max(70, (column.title?.length ?? 5) * 15);
  }
}
