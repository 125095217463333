import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, startWith } from 'rxjs';
import { SubSink } from 'subsink';
import { CycleMetrics } from '../../../../../constants/cycle-metric';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { DashboardCycleTimeFormService } from '../services/dashboard-cycle-time-form.service';
import { DashboardCycleFormService } from '../../services/dashboard-cycle-form.service';

@Component({
  selector: 'app-cycle-dashboard-tab',
  templateUrl: './cycle-dashboard-tab.component.html',
  styleUrl: './cycle-dashboard-tab.component.scss',
})
export class CycleDashboardTabComponent {
  public filterForm: FormGroup = this.cycleTimeFormService.filterForm;
  public cycleMetrics = CycleMetrics;

  private sub = new SubSink();

  constructor(public cycleTimeFormService: DashboardCycleTimeFormService,
              private dashboardFormService: DashboardReportFormService,
              private changeDetectorRef: ChangeDetectorRef,
              private dashboardCycleFormService: DashboardCycleFormService
  ) {
    
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.dashboardCycleFormService.exportData();
    });

    this.sub.sink = combineLatest([
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))
    ]).subscribe(() => {
      this.cycleTimeFormService.querySummaryData();
    });

    this.cycleTimeFormService.querySummaryData();
  }

  get cycleCountQueryResult$() {
    return this.cycleTimeFormService.cycleCountQueryResult$;
  } 

  get averageCycleTimeQueryResult$() {
    return this.cycleTimeFormService.averageCycleTimeQueryResult$;
  } 

  getChangePercentText(current: number, previous: number) {
    if (!previous || previous === 0) {
      return '';
    }

    let change = Math.round(((100 * (current - previous)) / previous));

    if (change > 0) { 
      return `<img src=\"/assets/icons_reports/dashboard_uptrend.svg\"> &nbsp; up ${Math.abs(change).toFixed(0)}% since last month`; 
    }
    else if (change == 0) { 
      return `no change since last month`; 
    }
    else  { 
      return `<img src=\"/assets/icons_reports/dashboard_downtrend.svg\"> &nbsp; down ${Math.abs(change).toFixed(0)}% since last month`;
    }
  }

  cycleMetricChanged(event: any) {
    let cycleMetricIndex = event[0];
    let cycleMetric = this.cycleMetrics[cycleMetricIndex];

    this.cycleTimeFormService.cycleMetricControl.setValue(cycleMetric);
  }
}
