import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LocationFilters } from '../models/filters/location-filters';
import { environment } from '../../../../environments/environment';
import { Country } from '../models/location/country';
import { StateProvince } from '../models/location/state-province';
import { Station } from '../models/location/station';
import { Region } from '../models/location/region';
import { SubSink } from 'subsink';
import { combineLatest, Observable } from 'rxjs';
import { DataQueryService } from './data-query/data-query.service';
import { DataQueryServiceConfiguration } from './data-query/data-query-service-configuration';
import { EnvironmentInformation } from '../models/environment-information';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentInformationDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([
      this.prefetchGet(EnvironmentInformationDataServiceConfiguration.EnvironmentInformation)
    ]);
  }

  getEnvironmentInformation() {
    return this.get<EnvironmentInformation>(EnvironmentInformationDataServiceConfiguration.EnvironmentInformation);
  }
}

abstract class EnvironmentInformationDataServiceConfiguration {
  static readonly EnvironmentInformation = new DataQueryServiceConfiguration<EnvironmentInformation>(
    'environment-information',
    environment.apiUrl + 'EnvironmentInformation',
    Infinity
  );
}
