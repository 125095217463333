import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { injectMutation } from '@ngneat/query';
import { map, Observable, tap } from 'rxjs';
import { DataQueryService } from '../../../../shared-rail-performance/services/data-query/data-query.service';
import { environment } from '../../../../../../environments/environment';
import { DataQueryServiceConfiguration } from '../../../../shared-rail-performance/services/data-query/data-query-service-configuration';
import { Report } from '../../../models/report/report';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService extends DataQueryService {
  constructor(private httpClient: HttpClient, notificationService: NotificationService) { 
    super(httpClient, notificationService);
  }

  getAllForType(type: string) {
    return this.get(ReportDataServiceConfiguration.ReportsAll, { type });
  }

  save(report: Report) {
    return this.httpClient.post<number>(`${environment.apiUrl}Report`, report).pipe(tap(() => {
      return this.refetchAnyQuery(ReportDataServiceConfiguration.ReportsAll.key);
    }));
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}Report/${id}`).pipe(tap(() => {
      return this.refetchAnyQuery(ReportDataServiceConfiguration.ReportsAll.key);
    }));
  }
}

class ReportDataServiceConfiguration {
  static readonly ReportsAll = new DataQueryServiceConfiguration<Report[]>(
      'report',
       environment.apiUrl + 'Report'
  );
}